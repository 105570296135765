// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- IO
import API from 'io/API';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import { getStoredSessionCredentials } from 'logic/userOperations';
import { getErrorMessageFromResponse } from 'logic/requestOperations';
import { isStringNullOrEmpty, isValidEmailAddress, isValidPhoneNumber } from 'logic/stringOperations';

// --- External components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

// --- Components
import { Transition } from 'App';
import PhoneNumberInput from 'visual/components/_/form/PhoneNumberInput';

// --- Style
const styles = theme => ({
	form: {},
});


const formRules = Object.freeze({
/*
	email: [
		value => !!value || { i18nKey: TranslationKey.validation_email_empty },
		value => isValidEmailAddress(value) || { i18nKey: TranslationKey.validation_email_invalid },
	],
*/
	fullname: [
		value => !!value || { i18nKey: TranslationKey.validation_fullname_empty },
	],
	// username: [
	// 	value => !!value || { i18nKey: TranslationKey.validation_username_empty },
	// ],
	phone: [
		value => (isStringNullOrEmpty(value) || isValidPhoneNumber(value)) || { i18nKey: TranslationKey.validation_phone_invalid },
	],
});

const defaultState = userData => ({
	email: userData.email,
	fullname: userData.voor_en_achternaam,
	username: userData.users,
	phone: userData.telefoon || '',
	errors: {
		email: [],
		fullname: [],
		// username: [],
		phone: [],
	},
	requestError: null,
	isFormDisabled: false,
});

class EditAccountModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...defaultState(props.userData) };

		this.onSubmit = this.onSubmit.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	async validateForm() {
		await this.setState({ isFormDisabled: true });

		let hasErrors = false;

		await this.setState((currentState) => {
			const errors = Object.keys(formRules).reduce((result, field) => {
				result[field] = formRules[field]
					.map(rule => rule(currentState[field]))
					.filter(ruleResult => ruleResult !== true);

				if (result[field].length > 0)
					hasErrors = true;

				return result;
			}, {});

			console.log('Form errors', errors);

			return {
				errors,
				isFormDisabled: false
			};
		});

		return hasErrors;
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		await this.setState(currentState => ({
			requestError: null,
//			email: currentState.email.toLowerCase(),
		}));

		const {
			props: {
				userData,
				onCompleted,
				onFailedToComplete,
			},
			state: {
//				email,
				fullname,
				username,
//				phone,
			}
		} = this;

		const hasErrors = await this.validateForm();

		// TODO Verify that the edited email isn't used by any other account (should be done on the back-end).

		// Invalid fields.
		if (hasErrors)
			return;

		const changes = {
//			email,
			users: username,
			voor_en_achternaam: fullname,
//			telefoon: phone,
		};

		if (userData.users !== username) {
			const checkIfUserExistsResponse = await API.checkIfUserExists(username);
			console.log('Check if user exists response', checkIfUserExistsResponse);

			if (checkIfUserExistsResponse.status === 200 && checkIfUserExistsResponse.data != null && checkIfUserExistsResponse.data.length > 0) {
				await this.setState({ requestError: { i18nKey: TranslationKey.error_update_profile_email_already_used } });
				return;
			}
		}

		const patchResponse = await API.patchUser(userData.id, changes);
		console.log('Patch user response', patchResponse);

		const patchErrorMessage = getErrorMessageFromResponse(patchResponse, 200);
		if (patchErrorMessage != null) {
			// Request failed, cannot fetch user data.
			await this.setState({ requestError: patchErrorMessage });
			return;
		}

		const json = getStoredSessionCredentials();

		if (json == null) {
			onFailedToComplete();
			return;
		}

		const getResponse = await API.getUserFromCredentials(username, json.password);
		console.log('Get user response after patch', getResponse);

		const getErrorMessage = getErrorMessageFromResponse(getResponse, 200);
		if (getErrorMessage != null) {
			// Failed to fetch user data.
			await this.setState({ requestError: getErrorMessage });
			return;
		}

		if (getResponse.data.length <= 0) {
			onFailedToComplete();
			return;
		}

		const rawUserData = getResponse.data[0];

		// url parameter not present in the get user response
		// (because getting a user from its id doesn't return a list of users).
		rawUserData.url = userData.url;

		onCompleted(rawUserData);

		this.closeModal();
	}

	closeModal() {
		const { onClose, userData } = this.props;

		this.setState({ ...defaultState(userData) });

		onClose();
	}

	render() {
		const {
			props: {
				t,
				isOpen,
				classes,
			},
			state: {
				errors,
				requestError,
				isFormDisabled,
			}
		} = this;

		/*
		<Grid item>
			<TextField
				required
				fullWidth
				size="small"
				autoComplete="off"
				variant="outlined"
				disabled={isFormDisabled}
				value={this.state.username}
				label={t(TranslationKey.username)}
				error={errors.username.length > 0}
				helperText={errors.username.length > 0 ? t(errors.username[0].i18nKey, errors.username[0].values) : null}
				onChange={({ target: { value } }) => this.setState((previousState) => {
					if (errors.username.length > 0)
						return { username: value, errors: { ...previousState.errors, username: [] } };
					return { username: value };
				})}
			/>
		</Grid>

		<TextField
			fullWidth
			size="small"
			type="phone"
			variant="outlined"
			autoComplete="off"
			disabled={isFormDisabled}
			value={this.state.phone}
			label={t(TranslationKey.phone)}
			error={errors.phone.length > 0}
			helperText={errors.phone.length > 0 ? t(errors.phone[0].i18nKey, errors.phone[0].values) : t(TranslationKey.help_text_enter_phone_number)}
			onChange={({ target: { value } }) => this.setState((previousState) => {
				if (errors.phone.length > 0)
					return { phone: value, errors: { ...previousState.errors, phone: [] } };
				return { phone: value };
			})}
		/>
		*/
		let phoneNumberInput = (
			<Grid item>
				<PhoneNumberInput
					country={'nl'}
					value={this.state.phone}
					label={t(TranslationKey.phone)}
					error={errors.phone.length > 0}
					id="edit-account-phone-number-input-field"
					helperText={errors.phone.length > 0 ? t(errors.phone[0].i18nKey, errors.phone[0].values) : t(TranslationKey.help_text_enter_phone_number)}
					onChange={value => this.setState((previousState) => {
						if (errors.phone.length > 0)
							return { phone: value, errors: { ...previousState.errors, phone: [] } };
						return { phone: value };
					})}
				/>
			</Grid>
		);
		return (
			<Dialog
				fullScreen
				open={isOpen}
				TransitionComponent={Transition}
				aria-labelledby="edit-account-modal-title"
				aria-describedby="edit-account-modal-content"
			>
				<div className="modal">
					<DialogTitle
						disableTypography
						className="modalBar"
						id="edit-account-modal-title"
					>
						<Button
							onClick={this.closeModal}
							aria-label="close button"
							disabled={isFormDisabled}
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
						<Typography
							variant="h6"
							className="modalTitle"
						>
							{t(TranslationKey.update_profile)}
						</Typography>
					</DialogTitle>
					<Container
						noValidate
						maxWidth="xs"
						disableGutters
						component="form"
						autoComplete="off"
						onSubmit={this.onSubmit}
						className="modalBody gridPadding"
					>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							direction="column"
							className="content"
							alignItems="stretch"
						>
{/*							<Grid item>
								<TextField
									disabled
									required
									fullWidth
									type="email"
									size="small"
									autoComplete="off"
									variant="outlined"
									disabled={isFormDisabled}
									value={this.state.email}
									label={t(TranslationKey.email)}
									error={errors.email.length > 0}
									helperText={errors.email.length > 0 ? t(errors.email[0].i18nKey, errors.email[0].values) : null}
									onChange={({ target: { value } }) => this.setState((previousState) => {
										if (errors.email.length > 0)
											return { email: value, username: value, errors: { ...previousState.errors, email: [] } };
										return { email: value, username: value };
									})}
								/>
							</Grid>
*/}
							<Grid item>
								<TextField
									required
									fullWidth
									size="small"
									autoComplete="off"
									variant="outlined"
									disabled={isFormDisabled}
									value={this.state.fullname}
									label={t(TranslationKey.fullname)}
									error={errors.fullname.length > 0}
									helperText={errors.fullname.length > 0 ? t(errors.fullname[0].i18nKey, errors.fullname[0].values) : null}
									onChange={({ target: { value } }) => this.setState((previousState) => {
										if (errors.fullname.length > 0)
											return { fullname: value, errors: { ...previousState.errors, fullname: [] } };
										return { fullname: value };
									})}
								/>
							</Grid>
							{/*phoneNumberInput*/}
							{requestError != null ? (
								<Grid item>
									<Typography variant="caption" color="error">
										{t(requestError.i18nKey, requestError.values)}
									</Typography>
								</Grid>
							) : null}
							<Grid item>
								<Button
									fullWidth
									size="large"
									type="submit"
									color="primary"
									disableElevation
									variant="contained"
								>
									{t(TranslationKey.update_profile)}
								</Button>
							</Grid>
						</Grid>
					</Container>
					<div className="footer">
						<Button
							className="button"
							onClick={this.closeModal}
						>
							{t(TranslationKey.cancel)}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
}

EditAccountModal.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	onCompleted: PropTypes.func.isRequired,
	onFailedToComplete: PropTypes.func.isRequired,
};

EditAccountModal.defaultProps = {
	isOpen: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(EditAccountModal);
