// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- Assets
import logo from 'assets/images/1000drills_logo_512x512.png';

// --- Style
import './SplashScreen.sass';

const useStyles = makeStyles(theme => ({
	background: {
		background: theme.darkMode
			? '#161616'
			: 'linear-gradient(to top, #dbdbd7, #eeeeea) no-repeat center center',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: 1,
		flexShrink: 0,
		zIndex: 999,
	},
}));

const SplashScreen = ({ readyToDisplayApp }) => {
	const classes = useStyles();

	return (
		<div
			id="splashScreen"
			className={readyToDisplayApp ? 'hide' : null }
		>
			<div className={classes.background}>
				<img
					src={logo}
					alt="1000 Drills"
					title="1000 Drills"
					id="splashScreenImage"
				/>
			</div>
		</div>
	);
};

SplashScreen.propTypes = {
	readyToDisplayApp: PropTypes.bool,
};

SplashScreen.defaultProps = {
	readyToDisplayApp: false,
};

export default SplashScreen;
