// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import Color from 'logic/enums/Color';
import { isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Icon from '@mdi/react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
	mdiSteering,
	mdiStrategy,
	mdiClockOutline,
	mdiShieldOutline,
	mdiAccountOutline,
	mdiNetworkStrength1,
} from '@mdi/js';

// --- Assets
import PlotIcon from 'assets/icons/plot.svg';


const MainTechniqueIcon = (props) => {
	const { mainTechnique, size } = props;

	switch (mainTechnique) {
	case 'aanval':
		return (
			<Icon path={mdiStrategy}
				className='mainTechniqueIcon'
				size={size}
			/>
		);

	case 'verdedigen':
	case 'verdediginstechnieken':
		return (
			<Icon path={mdiShieldOutline}
				className='mainTechniqueIcon'
				size={size}
			/>
		);

	case 'coördinatie':
		return (
			<Icon path={mdiSteering}
				className='mainTechniqueIcon'
				size={size}
			/>
		);

	default:
		return (
			<Icon path={mdiStrategy}
				className='mainTechniqueIcon'
				size={size}
				horizontal
				vertical
				rotate={90}
				// TODO Red should only be used in dire circumstances to describe something that went wrong, because it draws
				//  attention immediately, regular content should use the regular color scheme with passive colors if any at all.
				// color="red"
			/>
		);
	}
};

MainTechniqueIcon.propTypes = {
	mainTechnique: PropTypes.string,
	size: PropTypes.number,
};

MainTechniqueIcon.defaultProps = {
	mainTechnique: '',
	size: 1,
};

export default MainTechniqueIcon;
