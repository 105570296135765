import { isStringNullOrEmpty } from '../stringOperations';
import { alphabeticalSort } from '../sortOperations';


const getSortingValue = (item, parameter) => {
	if (item == null || isStringNullOrEmpty(item[parameter]))
		return null;

	return item[parameter];
};

const SupportedSorting = Object.freeze({
	sort_none: {
		id: 'sort_none',
		sort: null
	},

	sort_main_technique: {
		id: 'sort_main_technique',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'hoofdtechniek');
			const valueB = getSortingValue(b, 'hoofdtechniek');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return -alphabeticalSort(valueA, valueB);
		},
	},
	sort_title: {
		id: 'sort_titel',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'label');
			const valueB = getSortingValue(b, 'label');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return alphabeticalSort(valueA, valueB);
		},
	},

	sort_newest: {
		id: 'sort_newest',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'datum');
			const valueB = getSortingValue(b, 'datum');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return -alphabeticalSort(valueA, valueB);
		},
	},
});

export default SupportedSorting;
