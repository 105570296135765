// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';
import { isStringNullOrEmpty } from 'logic/stringOperations';
// Global
// import userObject from './userObject';

// HL 1 2024
import {Buffer} from 'buffer';


import { baseURL } from 'io/API';

const getUserFromCredentials = (username, password, fields = '*', contextConfiguration) => {

	const escapedUsername = encodeURI(username);
	const escapedPassword = encodeURI(password);
	console.log('getUserFromCredentials');
	let url;
	if (isStringNullOrEmpty(fields))
		url = `/users?users=${escapedUsername}`; // &password=${escapedPassword}`;
	else
		url = `/users?users=${escapedUsername}&fields=${fields}`; //&password=${escapedPassword}&fields=${fields}`;

		// Set users' credentials for API calls after a user signed in


	const token = `${username}:${password}`;
	const encodedToken = Buffer.from(token).toString('base64');
	let headers = { 'Authorization': 'Basic '+ encodedToken };
//	const baseURL = (process.env.SQLCONNSTR_DatabaseURL != null )? process.env.SQLCONNSTR_DatabaseURL : 'https://api-test.brown.dutchbackend.nl/1000drills';

	let config = {
		baseURL,
	//	options,
		headers
	};
	
	// let userFromCredentials = axios.get(
	// 	url,
	// 	{
	// 		...config,
	// 		...contextConfiguration,
	// 	}
	// ).catch(onRequestError);

	// userObject = getUserFromCredentials;
	// return userFromCredentials;

	return axios.get(
		url,
		{
			...config,
			...contextConfiguration,
		}
	).catch(onRequestError);
};

export default getUserFromCredentials;
