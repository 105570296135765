// --- Requests
import getLog from './requests/getLog';
import postLogEntry from './requests/postLogEntry';

// HL 1 2024
import {Buffer} from 'buffer';

export const baseURL = 'https://api-test.brown.dutchbackend.nl/1000drillsLOG';
//const baseURL = (process.env.SQLCONNSTR_DatabaseURL != null )? process.env.SQLCONNSTR_DatabaseURL : 'https://api.brown.dutchbackend.nl/1000drills';

//const baseURL = 'https://api.brown.dutchbackend.nl/1000drillsDEV';
// const vimeoBaseURL = 'https://api.vimeo.com/videos';
/*
const options = {
	auth: {
		username: 'eric@vanrijswick.com',
		password: '1234'
	  },
//	  'Content-Type': 'application/x-www-form-urlencoded'
};
*/


const token = 'Loguser:nAQL8b3ulOh85Tgrdh8dg';
const encodedToken = Buffer.from(token).toString('base64');
console.log(encodedToken);
const headers = { 'Authorization': 'Basic '+ encodedToken };

// Default common configuration
export let commonConfiguration = {
	baseURL,
//	options,
	headers
};

// Set users' credentials for API calls after a user signed in
export const setCommonLoggingConfiguration = () => {
	const json = getStoredSessionCredentials();
	console.log('setCommonConfiguration');
	if (json != null) {
		const token = `${json.username}:${json.password}`;
		const encodedToken = Buffer.from(token).toString('base64');
		headers = { 'Authorization': 'Basic '+ encodedToken };
		commonConfiguration.headers = headers;
	}
}

// logging API
export default {
	getLog,
	postLogEntry,


	// Change header after user signed in
//	setCommonConfiguration,
};
