import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

//const useStyles = makeStyles(theme => ({
	
const styles = theme => ({
	root: {
		display: 'inline',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		width: '100%',
		overflow: 'hidden',
		// padding: '10px',
		// margin: '7px',
		backgroundColor: '#e0e0e0', // theme.palette.background.paper,
		borderRadius: '5px',
	},
	imageList: {
		flexWrap: 'nowrap',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)',
	},
	title: {
		color: theme.palette.primary.dark,
	},
	titleBar: {
		background: 'rgba(255, 255, 255, 0.7)'
//      'linear-gradient(to bottom, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.3) 70%, rgba(255,255,255,0) 100%)',
	},
	titleBarSelected: {
		background: 'rgba(150, 150, 255, 0.7)'
//      'linear-gradient(to bottom, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.3) 70%, rgba(255,255,255,0) 100%)',
	},
	infoBackgroundTabBody: {
		padding: '10px 15px',
		backgroundColor: theme.palette.background.backgroundPaper,
		borderRadius: '0px 5px 5px 5px',
	},
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */


 class LearningCurveBlock extends React.Component {

	// --- Constructor
	constructor(props) {
		super(props);
		this.state ={ scrollOffset: '0px' };

	}

	componentDidMount() {
		this.setScrollPosition();
		this._ismounted = true;
	}
	
	setScrollPosition() {
		const {
			props: {
			itemData = [],
			current = null,
			},
		} = this;

		let el = document.getElementById("learning-curve-list");
		let listItem = document.getElementById("imageListItem-0");
		let curveBlock = document.getElementById("learning-curve-block");
		// If element is loaded and width is know, proceed
		if (listItem!= null) {
			let listItemWidth = listItem.offsetWidth;
			// get index of selected item
			let itemIndex = 0;
			let index = 0;
			itemData.forEach( (item) => {
				if (item != null) {
					if (item.id == current){
						itemIndex = index;
					}
					index++;
				}
			});

			if (curveBlock != null) {
				// Calculate middle of block and how far to scroll
				if (el != null) {
					let middleAnchor = (curveBlock.offsetWidth/2) - (listItemWidth/2)
					let scrollLeft = ((itemIndex * listItemWidth) - middleAnchor);
					el.scrollLeft = (scrollLeft >= 0) ? scrollLeft : 0;
				}
			}
		}		
	}

	render (){
		const {
			props: {
			itemData = [],
			origin,
			current = null,
			history,
			classes,
			title,
			},
		} = this;
//		const history = useHistory();
//		const classes = useStyles();

		// Idea, get ImageListItem width, multiply by selected item index and set horizontal scroll to it

		if (this._ismounted) {
			this.setScrollPosition();
		}



		return (
			<Container>
			<div className={classes.infoBackgroundTabTitle}>
				{title}
			</div>
			<div className={classes.infoBackgroundTabBody}>
				<div id="learning-curve-block" className={classes.root}>
					<ImageList id="learning-curve-list" className={classes.imageList} cols={2.5}>
						{itemData.map((item, index) => { /* Note that when not all drills are loaded
						e.g. only drills with videos, this item can be undefined, so filter */
						if (item != null) { 
							return (
							<ImageListItem id={`imageListItem-${index}`}				
								key={`${item.thumbnail}_${index}`} 
							>
									<img src={item.thumbnail} alt={item.label} />

									<Link to={`${origin}/${item.id}`} className={classes.imageList}>
									<div style={(current==item.id)?{
										position: 'absolute',
										top: '0',
										left: '0',
										width: '100%',
										height: '100%',
										border: "2px solid grey",	
									}:{
											position: 'absolute',
											top: '0',
											left: '0',
											width: '100%',
											height: '100%',
											backgroundColor: 'rgba(230, 230, 230, 0.6)',
										}}>
									</div>
									<div style={(current==item.id)?{
											position: 'absolute',
											top: '0',
											left: '0',
											width: '100%',
											height: '100%',
											display: 'block',
											margin: 'auto',
										
										}:{
											position: 'absolute',
											top: '0',
											left: '0',
											width: '100%',
											height: '100%',
											display: 'block',
											margin: 'auto',
				
										}}>

										<div  
											style={{
											display: "block",
											justifyContent: "center",
											alignItems: "center",
											height: "100%",
											textAlign: "center",
											margin: "auto",
										}}>
											<svg  style={{height: "100%", width: "100%"}}>	
												<g >
												<circle cx="40px" cy="40px" r="30px" stroke="#aaa" strokeWidth="8" ></circle>
												<text  x="40" y="52" textAnchor="middle" fontSize="36" fill="#fff">
													{
														/* get number between () using regex look ahead/begind to get rid of () */
														(item.label.match(/\((\d+)\)/g) != null) 
														? item.label.match(/\((\d+)\)/g)[0].match(/\d+/g) 
														: '?'
													}
												</text>
												</g>
											</svg>
										</div>


									</div>
								</Link>

							</ImageListItem>
							);
						}
						
						})}
					</ImageList>
				</div>
			</div> 
		</Container>
		);
	}
 }

LearningCurveBlock.propTypes = {

};

LearningCurveBlock.defaultProps = {
};

export default withStyles(styles)(LearningCurveBlock);
//export default withStyles(styles, { withTheme: true })(ClassComponent);