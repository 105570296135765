// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import Color from 'logic/enums/Color';
import { isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Icon from '@mdi/react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// --- Assets
import Level1 from 'assets/icons/level-1.svg';
import Level2 from 'assets/icons/level-2.svg';
import Level3 from 'assets/icons/level-3.svg';
import Level12 from 'assets/icons/level-12.svg';
import Level13 from 'assets/icons/level-13.svg';
import Level23 from 'assets/icons/level-23.svg';
import Level123 from 'assets/icons/level-123.svg';
import LevelEmpty from 'assets/icons/level-empty.svg';

const styles = theme => ({
	levelIconStyle: {
		fontSize: 26, 
		color: theme.palette.text.primary,
	},
});

const LevelIcon = (props) => {
	const { levelCode, classes } = props;
	// Use the unique value levelCode to determine which icon to use
	// level  code
	// 1     = 5
	// 2     = 9
	// 3     = 12
	// 1, 2  = 5+9 = 14, etc
	let icon = (
		<SvgIcon
			key="levelIcon"
			component={LevelEmpty}
//			className={styling.levelIconStyle}
		/>
	);

	switch(levelCode) {
		case 5:	// Levels 1
				icon = (
					<SvgIcon
						key="levelIcon"
						component={Level1}
//						className={levelIconStyle}
					/>
				);
				break;
		case 9:	// Levels 2
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level2}
					/>
				);
				break;
		case 12:// Levels 3
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level3}
					/>
				);
				break;
		case 14:// Levels 1, 2
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level12}
					/>
				);
				break;
		case 17:// Levels 1, 3
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level13}
					/>
				);
				break;
		case 21:// Levels 2, 3
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level23}
					/>
				);
				break;
		case 26:// Levels 1, 2, 3
				icon= (
					<SvgIcon
						key="levelIcon"
						component={Level123}
					/>
				);
				break;
		default:
				icon = (
					<SvgIcon
						key="levelIcon"
						component={LevelEmpty}
//						className={classes.levelIconStyle}
					/>
				);
				break;
	
	}
	return icon;
};

LevelIcon.propTypes = {
	levelCode: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
};

//export default withStyles(styles)(LevelIcon);
export default withStyles(styles)(LevelIcon);