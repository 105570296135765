import React, {useState} from 'react';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from "@material-ui/icons/Favorite";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CheckIcon from "@material-ui/icons/Check";



import Dialog from '@material-ui/core/Dialog';
import { 
	DialogTitle, DialogActions, DialogContent,
	Menu, MenuList, MenuItem, ListItemText, ListItemIcon 
 } from '@mui/material';

// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import FormControl from '@material-ui/core/FormControl';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';



// --- Style
import 'visual/components/modals/ApplyFiltersModal.sass';
// import { Transition } from 'App';

// const themes = theme => ({
// 	// paper: {
// 	// 	width: '100%',
// 	// },
// });

// // const iconButtonStyles = {
// //   color: "yellow",
// //   backgroundColor: "teal"
// // };



import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  BookmarkIconButton: {
    color: "#ccc",
    backgroundColor: "#555",
    "&:hover, &.Mui-focusVisible": { 
    	color: "yellow",
    	backgroundColor: "#555",
    },
  },
  ListItemText: {
  	paddingTop: '6px',
  }
}));



export default function Bookmarkbutton2( {drill, bookmarkDict} ) {
	const [showBookmarkdialog, setshowBookmarkdialog] = useState(false);
	const classes = useStyles();

	const handleClickOpen = () => {
		setshowBookmarkdialog(true);
	};
	const handleClose = () => {
		console.log('Bookmarkbutton2 - handleClose');
		setshowBookmarkdialog(false);
	};

	console.log('Bookmarkbutton2');
	// console.log('drill');
	// console.log(drill);
	console.log('drill.i18n_label');
	console.log(drill.i18n_label);
	console.log('bookmarkDict');
	console.log(bookmarkDict);


	// Find home folder
	// HL 6 2022: Todo: should be done in user object or bookmark object
	let homefolder;
	for (const [key, value] of Object.entries(bookmarkDict)) {
		if (key != 'allBookmarks') {	// Skip allBookmarks item
	    	if( bookmarkDict[key].type == 'home' ){
	    		homefolder = key;
    			break;
	    	}
	    }
	}



	return (
		<>
			<IconButton
				className={classes.BookmarkIconButton}
				onClick={() => {setshowBookmarkdialog(true)} }
				// color="yellow"
			>
				<FavoriteIcon sx={{  color: "green"  }}  />
			</IconButton>
			<BookmarkDialog 
				drill={drill}
				bookmarkDict={bookmarkDict}
				folder={homefolder}
				show={showBookmarkdialog} 
				onClose={handleClose}/>
		</>
	);
}



function BookmarkDialog({ drill, bookmarkDict, folder, show, onClose }) {
		console.log("BookmarkDialog");
		const classes = useStyles();
		if (! show) {
				return <></>;
		}

  	return(
		<Dialog
			BackdropProps={ {style: {opacity: '0.8'} }}
			// fullWidth
			maxWidth='md'
			open={show}
			onClick={() => {
				onClose()} 
			}
			// TransitionComponent={Transition}
			aria-labelledby="bookmark-dialog-modal-title"
			aria-describedby="bookmark-dialog-modal-content"
		>
			<DialogTitle
				className="modalBar"
				id="apply-filter-modal-title"
			>
				Add Bookmark
			</DialogTitle>
			<DialogContent>
				<div>{drill.i18n_label}</div>
				<hr/>
				<div>Map</div>

				<FolderChooser 
					bookmarkDict={bookmarkDict}
					folder={folder}
				/>

				<MenuList
					className="FolderSelector"
					id="folder-selector"
				>
			        <MenuItem
			        	onClick={(e) => {
			        		e.stopPropagation();
									// FolderChooser() 
								}} 
			        >
						<ListItemIcon>
							<FolderOpenIcon fontSize='small'/>
						</ListItemIcon>
						<ListItemText
							className={classes.ListItemText}
						>
							My very first Folder
						</ListItemText>
						<ListItemIcon>
							<CheckIcon />
						</ListItemIcon>
			        </MenuItem>


				</MenuList>
				<hr/>
				<div>Drills</div>

			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose()} 
					}
				>
					{'cancelText'}
				</Button>
				<Button
					disableElevation
					variant="contained"
					color="primary"
					onClick={() => {
						onClose();
						// onCompleted();
					}}
				>
					{'confirmText'}
				</Button>
			</DialogActions>
		</Dialog>

	)
}


function FolderChooser({bookmarkDict, folder}) {
	console.log('FolderChooser');
	console.log(bookmarkDict);
	console.log(folder);

	for( const [key, value] of Object.entries(bookmarkDict) ){
		console.log('key');
		console.log(key);
		if (key == folder) {	
			console.log('bookmarkDict[key]');
			console.log(bookmarkDict[key]);
  		break;
	  }
	}

	console.log('FolderChooser 2');


	return(
		<MenuList
			className="FolderSelector"
			id="folder-selector"
		>
	        <MenuItem
	        	onClick={(e) => {
	        		e.stopPropagation();
							// FolderChooser({user, folder})}
						}} 
	        >
				<ListItemIcon>
					<FolderOpenIcon fontSize='small'/>
				</ListItemIcon>
				<ListItemText>
					My second Folder
				</ListItemText>
				<ListItemIcon>
					<CheckIcon />
				</ListItemIcon>
	        </MenuItem>

		</MenuList>
	)

}