// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';

// --- External components
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { ColorLensOutlined } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
// --- Components
import { Transition } from 'App';
import BookmarkDrillCard from 'visual/components/cards/BookmarkDrillCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import DrillInspector from 'visual/components/modals/DrillInspector';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';
import BookmarkFolderButton from 'visual/components/icons/BookmarkFolderButton';

// --- Style
const styles = theme => ({
	paper: {
		margin: '0px',
		width: '100%',
	},
	scrollPaper: {
		width: '100%',
		height: '100%',
	}
});


class Bookmarks extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// bookmarkFilter(house, fullBookmarkDictionary) {
	// 	//fullBookmarkDictionary[0].allBookmarks
	// 	return (bookmarkDictionary[house.id] != null);
	// }

	// TODO: Create houselist per folder
	// createBookmarkedDrillLists(houseList, fullBookmarkDict) {
	// 	//let bookmarkedHouseList = houseList.filter(house => fullBookmarkDict[0].bookmarks[house.id] != null);
	// 	// todo eric
	// }

	deleteFolder(folderId) {
		console.log('this.props: ');
		console.log(this.props);
		const {
			props: {
				deleteBookmarkFolder,
			}
		} = this;

		console.log('bookmarks: deleteFolder folderId: ', folderId);
		deleteBookmarkFolder(folderId);
	}

	render() {
		const {
			props: {
				t,
				i18n,
				classes,
				history,
				houseList,
				contactData,
				adCategories,
				// toggleBookmark,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				addUserBookmarkFolder,
				getDrillDetails,
				bookmarkDictionary,
				fullBookmarkDict,
				authenticationStatus,
				supportedTechnics,
				supportedSubTechnics,
				route,
				learningCurves,
				deleteBookmarkFolder,
				// supportedHouseStatus,
				// supportedPropertyTypes,
				match: { params: { id } },
				...other
			},
		} = this;

		let notSignedIn = null;
		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			notSignedIn = (
				<Grid
					item
					xs={12}
					sm="auto"
					style={{ margin: 'auto' }}
				>
					<Alert severity="warning">
						<Trans i18nKey={TranslationKey.page_favorites_caveat_not_signed_in} />
					</Alert>
				</Grid>
			);
		}

		// const isFilteredList = houseListFiltersEnabled && houseListFilterCount > 0;
		//bookmarkFilter
		console.log('bookmarks: allBookmarks: ');
		console.log(fullBookmarkDict.allBookmarks);

		console.log(houseList);

		const bookmarkedHouseList = houseList.filter(house => fullBookmarkDict.allBookmarks[house.id] != null);
		// TODO: Create houselist per folder
		// const bookmarkedDrillLists = this.createBookmarkedDrillLists(houseList, fullBookmarkDict);
//		const bookmarkedHouseList = houseList.filter(house => bookmarkDictionary[house.id] != null);
		console.log('bookmarkedHouseList: ');
		console.log(bookmarkedHouseList);
		console.log('fullBookmarkDict: ');
		console.log(fullBookmarkDict);

		let subListLength = 0;
		let bookmarkList = [];

		// Loop over folders
		for (const [folderKey, folderValue] of Object.entries(fullBookmarkDict)) {
			if (folderKey !== 'allBookmarks') {
				bookmarkList.push({item: fullBookmarkDict[folderKey].label, id: fullBookmarkDict[folderKey].id, folderType: fullBookmarkDict[folderKey].type, type: 'text'})
				console.log(fullBookmarkDict[folderKey].label);
				console.log('bookmarks: ');
				// TODO: Note the root bookmarks are objects, while sub bookmarks are array, so fix!!!!
				console.log(fullBookmarkDict[folderKey].bookmarks);
				// Loop over bookmarks
				for (const index in fullBookmarkDict[folderKey].bookmarks) {
					bookmarkList.push({item: fullBookmarkDict[folderKey].bookmarks[index], type: 'card'});
				}
			}
		}
		console.log('bookmarkList: ');
		console.log(bookmarkList);

		let list = bookmarkList.map((item, index) => {
			if (bookmarkList[index].type == 'card') {
				return (
					<Grid key={`bookmark-card-${index}`} item xs={12} sm={8} md={4} style={{padding: '8px 0px'}}>
						<BookmarkDrillCard
							t={t}
							i18n={i18n}
							drill={houseList.find(house => house.id === bookmarkList[index].item)}
							addBookmarkToFolder={addBookmarkToFolder}
							removeBookmarkFromFolder={removeBookmarkFromFolder}
							supportedTechnics={supportedTechnics}
							supportedSubTechnics={supportedSubTechnics}
							to={`/bookmarks/${bookmarkList[index].item}`}
							bookmarked={true}
							bookmarkDict={fullBookmarkDict} // Root folder
							showEditBookmarkMenu={true}
							small={true}
							addUserBookmarkFolder={addUserBookmarkFolder}
						/>
					</Grid>
				);
			}
			else {
				return (
					<Grid key={`bookmark-folder-title-${index}`} item xs={12} style={{paddingTop:'15px'}}>
						<Grid container columns={{ xs: 4, sm: 8, md: 12 }} alignItems='flex-start'>
							<Grid item xs={1} style={{paddingTop: '5px', paddingRight: '10px'}}>
								<FolderOpenIcon />
							</Grid>
							<Grid item xs={10} sm={10}>
								<Typography variant="h5" component="h2">
									{bookmarkList[index].item}
								</Typography>	
							</Grid>
							<Grid item xs={1}>
								{bookmarkList[index].folderType == 'home' 
									? '' 
									: <BookmarkFolderButton 
										key={`folder-button-${index}`}
										folder={bookmarkList[index]}
										t={t}
										deleteFolder={(id) => this.deleteFolder(id)}
										renameFolder={() => console.log('renameFolder')}
										{...other}
									/>}
							</Grid>

						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
					</Grid>
				);
			}
		});

	
		const bookmarksList = (
			<>
				{list}
			</>		
		);
			
		return (
			<Container
				disableGutters
				className="page"
			>
					<DrillInspector
						t={t}
						id={id}
						i18n={i18n}
						houseList={houseList}
						contactData={contactData}
						adCategories={adCategories}
						// toggleBookmark={toggleBookmark}
						addBookmarkToFolder={addBookmarkToFolder}
						removeBookmarkFromFolder={removeBookmarkFromFolder}
						onClose={() => history.push('/bookmarks') }
						supportedSubTechnics={supportedSubTechnics}
						supportedTechnics={supportedTechnics}  // HL 4 2022  
						bookmarked={fullBookmarkDict.allBookmarks[id] != null}
					//	bookmarked={fullBookmarkDict.allBookmarks[housesToDisplay[index].id] != null}
						learningCurves={learningCurves}
						history={history}
						bookmarkDict={fullBookmarkDict}
						addUserBookmarkFolder={addUserBookmarkFolder}
					/>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}>
							{route.icon}								
							<Typography
									variant="h5"
									component="h1"
									style={{ textTransform: 'capitalize', paddingLeft: '10px' }}
								>
									{t(TranslationKey.page_favorites_title)}
							</Typography>
						</div>  
					</Grid>
					{bookmarksList}
					<Grid
						item
						xs={12}
					>
						<EndOfListCard
							isFilteredList={false}
							itemsCount={Object.keys(fullBookmarkDict.allBookmarks).length}
							translationKey={TranslationKey.bookmarks_end_of_list}
							translationKeyWhenEmpty={TranslationKey.bookmarks_end_of_list_empty}
							// translationKeyWhenFiltered={TranslationKey.bookmarks_end_of_list_filtered}
							displayAsAlert={fullBookmarkDict.allBookmarks.length <= 0 ? 'info' : null}
							// displayAsAlert={bookmarkedHouseList.length <= 0 && !isFilteredList ? 'info' : null}
						/>
					</Grid>
					{notSignedIn}
				</Grid>
			</Container>
		);
	}
}

Bookmarks.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	userData: PropTypes.object,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	// toggleBookmark: PropTypes.func,
	addBookmarkToFolder: PropTypes.func,
	removeBookmarkFromFolder: PropTypes.func,
	deleteBookmarkFolder: PropTypes.func,
	supportedSubTechnics: PropTypes.object.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	// houseListFilterCount: PropTypes.number,
	bookmarkDictionary: PropTypes.object,
	classes: PropTypes.object.isRequired,
	fullBookmarkDict: PropTypes.object.isRequired,
	// clearHouseListFilters: PropTypes.func,
	// toggleHouseListFilters: PropTypes.func,
	// houseListFiltersEnabled: PropTypes.bool,
	// openFilterModal: PropTypes.func,
	getDrillDetails: PropTypes.func.isRequired,
	// supportedHouseStatus: PropTypes.object.isRequired,
	// supportedPropertyTypes: PropTypes.object.isRequired,
	houseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	authenticationStatus: PropTypes.oneOf(Object.values(AuthenticationStatus)).isRequired,
};

Bookmarks.defaultProps = {
	// houseListFiltersEnabled: false,
	// toggleBookmark: () => console.warn('toggleBookmark method not provided.'),
	addBookmarkToFolder: () => console.warn('addBookmarkToFolder method not provided.'),
	removeBookmarkFromFolder: () => console.warn('removeBookmarkFromFolder method not provided.'),
	deleteBookmarkFolder: () => console.warn('deleteBookmarkFolder method not provided.'),
	// clearHouseListFilters: () => console.warn('clearHouseListFilters method not provided.'),
	// toggleHouseListFilters: () => console.warn('toggleHouseListFilters method not provided.'),
	// openFilterModal: () => console.warn('openFilterModal method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Bookmarks);
