// --- Requests
import get from './requests/get';
// import getHouse from './requests/getHouse';
import getDrill from './requests/getDrill';
// import getHouses from './requests/getHouses';
import getDrills from './requests/getDrills';
import getAdData from './requests/getAdData';
import getContactData from './requests/getContactData';
import getAdCategories from './requests/getAdCategories';
import checkIfUserExists from './requests/checkIfUserExists';
import getSupportedCities from './requests/getSupportedCities';
import getSupportedLevels from './requests/getSupportedLevels';
import getSupportedBookmarkFolderTypes from './requests/getSupportedBookmarkFolderTypes';
import getBookmarkFolders from './requests/getBookmarkFolders';
import getSupportedThemes from './requests/getSupportedThemes';
import getBooleanSelectors from './requests/getBooleanSelectors';
import getFilterPreference from './requests/getFilterPreference';
import getInformationTexts from './requests/getInformationTexts';
import getSupportedTechnics from './requests/getSupportedTechnics';
import getSupportedLanguages from './requests/getSupportedLanguages';
import getUserFromCredentials from './requests/getUserFromCredentials';
import getSupportedSubTechnics from './requests/getSupportedSubTechnics';
import getFilterPreferenceByUserID from './requests/getFilterPreferenceByUserID';
import getVolleypediaArticles from './requests/getVolleypediaArticles';
import getVideoDetails from './requests/getVideoDetails';
import getBookmarkHomeDetails from './requests/getBookmarkHomeDetails';
import getBookmarkSubHomeDetails from './requests/getBookmarkHomeSubDetails';
import getBookmarkFolderTypes from './requests/getBookmarkFolderTypes';
import getLearningCurves from './requests/getLearningCurves';


import patchFolderBookmarks from './requests/patchFolderBookmarks';

import patchUser from './requests/patchUser';
import patchFilterPreference from './requests/patchFilterPreference';
import patchUserPassword from './requests/patchUserPassword';

import postUser from './requests/postUser';
import postFilterPreference from './requests/postFilterPreference';

import deleteUser from './requests/deleteUser';
import deleteFolder from './requests/deleteFolder';

import postBookmarkFolder from './requests/postBookmarkFolder';

import { getStoredSessionCredentials } from '../../logic/userOperations';
import { mdiConsoleLine } from '@mdi/js';

// HL 1 2024
import {Buffer} from 'buffer';

// export const baseURL = (process.env.REACT_APP_SQLCONNSTR_DatabaseURL != null )? process.env.REACT_APP_SQLCONNSTR_DatabaseURL : 'https://api-test.brown.dutchbackend.nl/1000drills';
export const baseURL = (process.env.REACT_APP_API_URL != null )? process.env.REACT_APP_API_URL : 'https://api-test.brown.dutchbackend.nl/1000drillsDEV';
console.log('process.env-------------', process.env);
console.log('process.env-------------', process.env.REACT_APP_API_URL);
// REACT_APP_API_URL


//const baseURL = (process.env.SQLCONNSTR_DatabaseURL != null )? process.env.SQLCONNSTR_DatabaseURL : 'https://api.brown.dutchbackend.nl/1000drills';

//const baseURL = 'https://api.brown.dutchbackend.nl/1000drillsDEV';
// const vimeoBaseURL = 'https://api.vimeo.com/videos';
/*
const options = {
	auth: {
		username: 'eric@vanrijswick.com',
		password: '1234'
	  },
//	  'Content-Type': 'application/x-www-form-urlencoded'
};
*/

const json = getStoredSessionCredentials();

// If user is not signed in, use default API user credentials to get texts, etc
let headers;
if (json == null) {
	// Not signed in, use defaults with limited access
	const token = 'anonymous:mG8UXU9OousY4q9T';
	const encodedToken = Buffer.from(token).toString('base64');
	headers = { 'Authorization': 'Basic '+ encodedToken };
}
else {
	// User has signed in, so use actual credentials for API calls
	const token = `${json.username}:${json.password}`;
	const encodedToken = Buffer.from(token).toString('base64');
	headers = { 'Authorization': 'Basic '+ encodedToken };
}

// Default common configuration
export let commonConfiguration = {
	baseURL,
//	options,
	headers
};

// Set users' credentials for API calls after a user signed in
export const setCommonConfiguration = () => {
	const json = getStoredSessionCredentials();
	if (json != null) {
		const token = `${json.username}:${json.password}`;
		const encodedToken = Buffer.from(token).toString('base64');
		headers = { 'Authorization': 'Basic '+ encodedToken };
		commonConfiguration.headers = headers;
	}
}

// API
export default {
	get,
	// getHouse,
	// getHouses,
	getDrill,
	getDrills,
	getAdData,
	getContactData,
	getAdCategories,
	checkIfUserExists,
	getSupportedLevels,
	getSupportedBookmarkFolderTypes,
	getBookmarkFolders,
	getSupportedCities,
	getSupportedThemes,
	getInformationTexts,
	getBooleanSelectors,
	getFilterPreference,
	getSupportedTechnics,
	getSupportedLanguages,
	getUserFromCredentials,
	getSupportedSubTechnics,
	getFilterPreferenceByUserID,
	getVolleypediaArticles,
	getBookmarkHomeDetails,
	getBookmarkSubHomeDetails,
	getLearningCurves,
	getBookmarkFolderTypes,
	patchUser,
	patchFilterPreference,
	patchUserPassword,
	patchFolderBookmarks,
	postUser,
	postFilterPreference,
	postBookmarkFolder,

	deleteUser,
	deleteFolder,

	// Vimeo
	getVideoDetails,

	// Change header after user signed in
	setCommonConfiguration,
};
