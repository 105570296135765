// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import { isStringNullOrEmpty } from 'logic/stringOperations';
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import MuiIcon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// --- Components


// --- Assets
import {
	mdiClockOutline,
	mdiAccountOutline,
	mdiNetworkStrength1
} from '@mdi/js';

// --- Style

const styles = theme => ({
	headerIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	bookmarkButton: {
		padding: 6,
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: 6,
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	MenuItemTitle: {
    	fontSize: '1rem',
	},
});

class BookmarkButton extends React.Component {
	constructor(props) {
		super(props);	  
		this.state = {
			anchorEl: null,
			openCreateModal: false,
			folderName: null,
		};
	}

	// Show menu when bookmark icon is clicked
	handleBookmarkClick = (event) => {
		console.log('handleBookmarkClick');
		this.setState( {anchorEl: event.currentTarget} );
	}

	handleBookmarkNewFolder = () => {
		console.log('bookmark New clicked');
		this.setState( {openCreateModal: true});
	}

	// Add bookmark to folder
	handleBookmarkAdd = (folderID, drillID) => {
		console.log(`handleBookmarkAdd: Add ${drillID} to ${folderID}`);
		this.props.addBookmarkToFolder(folderID, drillID);

		this.handleBookmarkClose();
	}

	handleBookmarkClose = () => {
		console.log('handleBookmarkClose');
		this.setState( {anchorEl: null} );
	}

	handleBookmarkRemoval = (folderID, drillID) => {
		this.props.removeBookmarkFromFolder(folderID, drillID);
		console.log(`handleBookmarkRemoval: Remove ${drillID} from ${folderID}`);
		this.handleBookmarkClose();
	}

	handleSaveNewFolder = (e) => {
		console.log('handleSaveNewFolder: ', this.state.folderName);
		// Add folder to database
		this.props.addUserBookmarkFolder(this.state.folderName);

		this.setState( {openCreateModal: false, folderName: null} );
	}

	render() {
		const {
			props: {
				t,
				drill,
				classes,
				bookmarked,
				showEditBookmarkMenu = false,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				bookmarkDict,
				addUserBookmarkFolder,
			},
			state: {
				openCreateModal,
			}
		} = this;

		const open = Boolean(this.state.anchorEl);

		let bookmarkMenu;
		let menuItems = [];
		let icon = (<MuiIcon>favorite</MuiIcon>);
		// Only when we need to show copy or remove options (typically on bookmark overview page)
		if (showEditBookmarkMenu) {
			// Change Icon to menu icon
			icon = (<MuiIcon>menu</MuiIcon>);

			// Show menu with copy and removing of bookmarks
			// Show remove bookmark options
			menuItems.push( (
				<Box key={'bookmark_remove_from_folder'}
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>
					{t(TranslationKey.bookmark_remove_from_folder)}
				</Box>
			));

			// Show remove bookmark options
			var self = this;
			// Find every bookmark folder containing this drill
			// Loop over folders
			for (const [key, value] of Object.entries(bookmarkDict)) {
				if (key != 'allBookmarks') {

					// loop over drills
					for(var jj in bookmarkDict[key].bookmarks) {
						if(bookmarkDict[key].bookmarks[jj] == drill.id) {
							// Found, so create unique key
							let uniqueKey = `${bookmarkDict[key].id}-${drill.id}`;
							menuItems.push( (<MenuItem key={uniqueKey}
								onClick={() => self.handleBookmarkRemoval(bookmarkDict[key].id, drill.id)}>
									{bookmarkDict[key].label}
								</MenuItem>
								)
							);
						}
					}
				}
			}	
			menuItems.push((<Divider key={'divider-1'}/>));
			// Show copy bookmark options
			menuItems.push(
				(<Box key='bookmark_copy_to_folder'
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>

					{t(TranslationKey.bookmark_copy_to_folder)}

				</Box>
				)
			);
			// Add bookmark
			var self = this;
			for (const [key, value] of Object.entries(bookmarkDict)) {
				// Skip allBookmarks item
				if (key != 'allBookmarks') {
					let uniqueKey = `${drill.id}-${key}`;
					// Disable menu item when it is already part of the folder
					const itemDisabled = (bookmarkDict[key].bookmarks[drill.id] == null) ? false : true;
					menuItems.push( (<MenuItem key={uniqueKey} disabled={itemDisabled}
						onClick={() => self.handleBookmarkAdd(key, drill.id)}
						divider={ (Object.keys(bookmarkDict)[0] == key) ? true : false } 
						>
							{bookmarkDict[key].label}
						</MenuItem>
						)	
					);
				}
			}
		}
		else{
			// Show default add bookmark to folder menu
			menuItems.push(	(
				<Box key={'bookmark_add_to_folder'}
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>

					{t(TranslationKey.bookmark_add_to_folder)}

				</Box>
			) );
			var self = this;
			// For every folder create an add to menu; Disable folders that already contain a bookmark for this drill
			for (const [key, value] of Object.entries(bookmarkDict)) {
				if (key != 'allBookmarks') {
					let uniqueKey = `${drill.id}-${key}`;
					const itemDisabled = (bookmarkDict[key].bookmarks[drill.id] == null) ? false : true;
					menuItems.push( (<MenuItem key={uniqueKey} 
						onClick={() => self.handleBookmarkAdd(key, drill.id)}
						disabled={itemDisabled}
//						divider={ (Object.keys(bookmarkDict)[0] == key) ? true : false } 
						>
							{bookmarkDict[key].label}
						</MenuItem>
						)	
					);
				}
			}


		}
			// Temporarily disable the create new functionality
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			// menuItems.push((<Divider key='divider'/>));
			menuItems.push((<Divider key='divider'/>));
			menuItems.push( (<MenuItem key={'create_new'}
				onClick={() => this.handleBookmarkNewFolder()}>
					{t(TranslationKey.bookmark_menu_create_folder_field)}
				</MenuItem>
				)
			);

			bookmarkMenu = (
				<Menu key={drill.id}
					id="bookmark-menu"
					anchorEl={this.state.anchorEl}
					open={open}
					onClose={this.handleBookmarkClose}
					MenuListProps={{
						'aria-labelledby': 'bookmark-button',
					}}
				>
					{menuItems}
				</Menu>
			)
		

		return (
			<>
			<Dialog
//				BackdropProps={ {style: {opacity: '0.8'} }}
//				fullWidth
//				maxWidth='xl'
//				classes={{scrollPaper: classes.scrollPaper, paper: classes.paper }}
				open={openCreateModal}
				aria-labelledby="alert-dialog-create-folder-title"
				aria-describedby="alert-dialog-create-folder-description"
			>
				<DialogTitle>
					{t(TranslationKey.modal_bookmark_create_folder_title)}
				</DialogTitle>
				<DialogContent>
				<DialogContentText>
					{t(TranslationKey.modal_bookmark_create_folder_text)}
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="foldername"
					label={t(TranslationKey.modal_bookmark_create_folder_input_field)}
					type="text"
					fullWidth
					variant="standard"
					onChange={(v) => {this.setState({folderName: v.target.value})}}
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={() => this.setState({openCreateModal: false})}>{t(TranslationKey.cancel)}</Button>
				<Button onClick={this.handleSaveNewFolder}>{t(TranslationKey.save)}</Button>
				</DialogActions>
			</Dialog>
				{bookmarked ? (
					<>
					<IconButton
						color="primary"
						aria-label="bookmark"
						className={classes.bookmarkButtonActive}
//						onClick={() => toggleBookmark(drill.id)}
						onClick={this.handleBookmarkClick}
						>
							{icon}

					</IconButton>
					</>
				
				) : ( <>
					<IconButton
						aria-label="bookmark"
						className={classes.bookmarkButton}
						
						onClick={this.handleBookmarkClick}
//							onClick={() => toggleBookmark(drill.id)}
					>
						{icon}
					</IconButton> 
					  </>
				)}
					{bookmarkMenu}
			</>
		);
	}
}

BookmarkButton.propTypes = {
	t: PropTypes.func.isRequired,
	drill: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	addBookmarkToFolder: PropTypes.func.isRequired,
	showEditBookmarkMenu: PropTypes.bool,
	removeBookmarkFromFolder: PropTypes.func.isRequired,
	addUserBookmarkFolder: PropTypes.func.isRequired,
	bookmarkDict: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookmarkButton);
