// --- Framework
import React from 'react';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';

// --- Components
import SignIn from 'visual/components/routes/SignIn';
import Drills from 'visual/components/routes/Drills';
import Register from 'visual/components/routes/Register';
import Bookmarks from 'visual/components/routes/Bookmarks';
import Volleypedia from 'visual/components/routes/Volleypedia';
import Leerlijnen from 'visual/components/routes/Leerlijnen';
import Homepage from 'visual/components/routes/Homepage';
import WikiIcon from 'assets/icons/v-logo2.svg';

export const authenticatedRoutes = [
	{
		key: TranslationKey.navbar_preferences,
		href: '/home',
		path: '/home',
		exact: false,
		icon: <Icon style={{fontSize: '3rem'}}  className="material-icons-outlined">home</Icon>,
		Component: Homepage,
		order: 0,
	},
	{
		key: TranslationKey.navbar_explorer,
		href: '/drills',
		path: '/drills/:id?',
		exact: true,
		icon: <Icon style={{fontSize: '3rem'}} className="material-icons-outlined">sports_volleyball</Icon>,
		Component: Drills,
		order: 1,
	},
	{
		key: TranslationKey.navbar_learningcurves,
		href: '/leerlijnen',
		path: '/leerlijnen/:leerlijnid?/:drillid?',
		exact: true,
		icon: <Icon style={{fontSize: '3rem'}} className="material-icons-outlined">clear_all</Icon>,
		Component: Leerlijnen,
		order: 2,
	},
	{
		key: TranslationKey.navbar_favorites,
		href: '/bookmarks',
		path: '/bookmarks/:id?',
		exact: true,
		icon: <Icon style={{fontSize: '3rem'}}  className="material-icons-outlined">grade</Icon>,
		Component: Bookmarks,
		order: 3,
	},
	{
		key: TranslationKey.navbar_information,
		href: '/volleypedia',
		path: '/volleypedia/:id?',
		exact: false,
		icon: <SvgIcon key="wiki-icon"
				component={WikiIcon}
				style={{ fontSize: 48, opacity: 0.8 }}
				viewBox='0 0 128 128'
			/>,
		Component: Volleypedia,
		order: 4,
	},
/*	{
		key: TranslationKey.navbar_preferences,
		href: '/preferences',
		path: '/preferences',
		exact: false,
		icon: <Icon style={{fontSize: '3rem'}}  className="material-icons-outlined">person</Icon>,
		Component: Preferences,
		order: 4,
	},
	*/
];

export const visitorRoutes = [
	{
		key: TranslationKey.sign_in,
		href: '/sign-in',
		path: '/sign-in',
		exact: true,
		Component: SignIn,
	},
	{
		key: TranslationKey.register,
		href: '/register',
		path: '/register',
		exact: true,
		Component: Register,
	},
];
