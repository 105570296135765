// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// --- API
// import getVideoDetails from 'io/API/requests/getVideoDetails'
import API from 'io/API';

// --- Logic
import Color from 'logic/enums/Color';
import ContactIcon from 'logic/enums/ContactIcon';
import TranslationKey from 'logic/enums/TranslationKey';
import { getAdvertisementIcon } from 'logic/enums/AdIcons';
// import HouseStatusColor from 'logic/enums/HouseStatusColor';
import { getIdFromApiUrl, isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ImageGallery from 'react-image-gallery';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DetailChip from 'visual/components/_/chips/DetailChip';
import { Transition } from 'App';

// --- Components
import ImageView from 'visual/components/_/media/ImageView';
import ItemStatus from 'visual/components/_/item/ItemStatus';
import ItemHeadLine from 'visual/components/_/item/ItemHeadLine';
import RequestInformationModal from 'visual/components/modals/RequestInformationModal';
import ItemHeader from 'visual/components/_/item/ItemHeader';
import MainTechniqueIcon from 'visual/components/icons/MainTechniqueIcon';
import LearningCurveBlock from 'visual/components/_/list/LearningCurveBlock';
import Drills from 'visual/components/routes/Drills';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';
import DrillCard from 'visual/components/cards/DrillCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import DrillInspector from 'visual/components/modals/DrillInspector';
// --- Assets
import PlotIcon from 'assets/icons/plot.svg';
import BedroomIcon from 'assets/icons/bedroom.svg';
import SurfaceIcon from 'assets/icons/surface.svg';
import LocationIcon from 'assets/icons/location.svg';

// --- Style
import './LeerlijnDrillsStyle.sass';

// --- Icons
import { Icon as MdiIcon } from '@mdi/react';
import {
	mdiStrategy, mdiClockOutline, mdiShieldOutline, mdiAccountOutline, mdiSteering, mdiNetworkStrength1
} from '@mdi/js';


const styles = theme => ({
	bookmarkButton: {
		padding: '6px',
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: '6px',
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	locationIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	priceIcon: {
		padding: 0,
		margin: 0,
		fontSize: 30,
		color: theme.palette.text.primary,
	},
	infoBackgroundTabTitle: {
		padding: '5px 15px',
		backgroundColor: theme.palette.background.backgroundPaper,
		borderRadius: '5px 5px 0px 0px',
		display: 'inline-block',
	},
	infoBackgroundTabBody: {
		padding: '10px 15px',
		backgroundColor: theme.palette.background.backgroundPaper,
		borderRadius: '0px 5px 5px 5px',
	},
});

const fallbackImage = '/images/image-placeholder.png';


class LeerlijnDrills extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

		};

		this.onClose = this.onClose.bind(this);
		this.renderNoData = this.renderNoData.bind(this);
	}


	async componentDidMount() {
		const {
			props: {
				id,
				houseList,
			},
		} = this;

	}

	onClose() {
		const { onClose } = this.props;

		this.setState({
			imageViewIndex: 0,
			showImageView: false,
		}, onClose);
	}

	getItemID (item) {
		let splitUrl = item.url.split('/');
		const itemID = splitUrl[splitUrl.length - 1];
	
		return itemID;
	}

	renderNoData(translationKey) {
		const { t, onClose, leerlijnid } = this.props;

		return (
			<Dialog
			fullScreen
			open={leerlijnid != null}
			TransitionComponent={Transition}
			aria-labelledby="alert-dialog-slide-title-leerlijnen"
			aria-describedby="alert-dialog-slide-description"
		>
	

			<div className="modal houseInspector">
				<DialogTitle disableTypography className="modalBar">
					{onClose ? (
						<Button
							onClick={onClose}
							aria-label="close"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
					) : null}
					<Box flexGrow={1} component="span"/>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>

				<div className="modalBody">
					<div style={{ padding: '64px 32px', textAlign: 'center' }}>
						{t(translationKey)}
					</div>
				</div>
				</DialogContent>
			</div>
			</Dialog>
		);
	}

	render() {
		const {
			props: {
				t,
				i18n,
				filters,
				classes,
				history,
				houseList,
				contactData,
				drillDetails,
				adCategories,
				clearFilters,
				toggleFilters,
				filtersEnabled,
				// toggleBookmark,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				addUserBookmarkFolder,
				openFilterModal,
				supportedTechnics,
				filteredHouseList,
				bookmarkDictionary,
				supportedSubTechnics,
				isLoadingFilteredList,
				fullBookmarkDict,
				onClose,
				learningCurves,
				leerlijnid,
			},
			state: {
				showImageView,
				imageViewIndex,
				informationRequest,
			}
		} = this;
		console.log('learningcurvees');
		console.log(learningCurves);
		console.log('leerlijnid: ', leerlijnid);
		let drillList = [];

		// find drills from learning curves
		for (const [key, value] of Object.entries(learningCurves)) {
			if (value.id == leerlijnid) {
				drillList = value.drills;
				console.log('found');
				break;
			}
		}
		// Sort by title
		drillList.sort((a, b) => {
			if (a.label == null ) { a.label = ""}
			if (b.label == null ) { b.label = ""}
			return a.label.toLowerCase().localeCompare(b.label.toLowerCase(), 
														i18n.language != null?i18n.language:'en', 
														{numeric: true});
		});	

		let drillListDetails = [];
		let self = this;
		// Now find corresponding drill details
		drillList.forEach((drillA, drillAIndex) => {
			let drillAID = self.getItemID(drillA);
			// Search and store drill details
			houseList.forEach((drillB, drillBIndex) => {
				if (drillAID == drillB.id) {
					drillListDetails.push(drillB);
				}
			});
		});
		
		if (houseList == null)
			return this.renderNoData(TranslationKey.loading);

		let list;
		let endOfList = null;
		let startOfList = null;
		let housesToDisplay = drillListDetails;
		// find drills to display
		// First loop over learning curve's drills

		// Then find corresponding drill's data
		console.log('inner height');
		console.log(window.innerHeight);
		console.log(housesToDisplay.length);
		list = (
			<VirtualizedList
				preloadingOffset={2}
				itemCount={housesToDisplay.length}
				// TODO: hack was: {window.innerHeight - 72 - 56 - 48 - 8} Find a way to get correct window height
				windowHeight={8000 - 72 - 56 - 48 - 8}
				computeSizes={(rowWidth) => {
					const maxItemWidth = 416;

					// maxItemWidth is multiplied by 1.1 (+10%) to prefer switching
					// item size when the available space gets 10% larger than the minimum.
					const howManyItemsCanFit = Math.max(Math.ceil(rowWidth / (maxItemWidth * 1.1)), 1);

					// Determines by how much the item(s) should be down-scaled to fit one more
					// and fill up as much horizontal space as possible. If the row width is
					// smaller than two items but still larger than one then a horizontal gap
					// can appear around the item, which is a waste of usable space.
					//
					// Note that this only applies when the row width is larger than the max item width,
					// otherwise there can only be one item per row that takes up all the width anyway,
					// and it shouldn't be applied when the ratio turns out to be larger than 1,
					// otherwise it would mean scaling each item up beyond their maximum width,
					// which would create a gap between each item horizontally and vertically.
					let freeSpaceRatio = (rowWidth / maxItemWidth) / howManyItemsCanFit;

					// Prevents each item to be considered to take up more space than they actually can,
					// otherwise a large gab can open up between items and re-introduce a waste of space.
					if (freeSpaceRatio > 1)
						freeSpaceRatio = 1;

					const itemWidth = rowWidth < maxItemWidth ? rowWidth : maxItemWidth * freeSpaceRatio;

					// (picture with 16:9 ratio) + (footer with street name and info) + (top and bottom padding).
					const rowHeight = (itemWidth * 0.5625) + 86 + 12;

					return {
						itemWidth, // 416
						rowHeight, // 317 + 16
					};
				}}
				renderItem={(key, index, style) => (
					<div
						key={key}
						style={{
							padding: '6px',
							...style,
						}}
					>
						<DrillCard
							t={t}
							i18n={i18n}
							drill={housesToDisplay[index]}
							// toggleBookmark={toggleBookmark}
							addBookmarkToFolder={addBookmarkToFolder}
							removeBookmarkFromFolder={removeBookmarkFromFolder}
							supportedTechnics={supportedTechnics}   // HL 4 2022  
							supportedSubTechnics={supportedSubTechnics}
							to={`/leerlijnen/${leerlijnid}/${housesToDisplay[index].id}`}
							bookmarked={fullBookmarkDict.allBookmarks[housesToDisplay[index].id] != null}
							bookmarkDict={fullBookmarkDict} // Root folder
							addUserBookmarkFolder={addUserBookmarkFolder}
							/>
					</div>
				)}
			/>
		);

			endOfList = (
				<Grid
					item
					xs={12}
				>
					<EndOfListCard
						itemsCount={housesToDisplay.length}
						isFilteredList={false}
						// Eric: No idea why, but drills_end_of_list results in a strange 
						// value here, not in drills, but only here. drills_end_of_list_filtered
						// gives the correct result. For now this workaround is acceptable
						translationKey={TranslationKey.drills_end_of_list_filtered}
						translationKeyWhenEmpty={TranslationKey.drills_end_of_list_empty}
						translationKeyWhenFiltered={TranslationKey.drills_end_of_list_filtered}
						displayAsAlert={housesToDisplay.length <= 0 ? 'warning' : null}
					/>
				</Grid>
			);
			startOfList = (
				<Grid
					item
					xs={12}
				>
					<EndOfListCard
						itemsCount={housesToDisplay.length}
						isFilteredList={false}
						translationKey={TranslationKey.drills_start_of_list}
						translationKeyWhenEmpty={TranslationKey.drills_start_of_list_empty}
						translationKeyWhenFiltered={TranslationKey.drills_start_of_list_filtered}
						displayAsAlert={housesToDisplay.length <= 0 ? 'warning' : null}
					/>
				</Grid>
			);			
		console.log('drills_end_of_list: ');
		console.log(TranslationKey.drills_end_of_list);
		console.log('itemsCount={housesToDisplay.length}');
		console.log(housesToDisplay.length);

		return (
			<Dialog
			fullScreen
			open={leerlijnid != null}
			TransitionComponent={Transition}
			aria-labelledby="alert-dialog-slide-title-leerlijnen"
			aria-describedby="alert-dialog-slide-description"
		>
	

				<div className="modal houseInspector">

					<DialogTitle disableTypography className="modalBar">
						{onClose ? (
							<Button
								onClick={onClose}
								aria-label="close"
								startIcon={<Icon>chevron_left</Icon>}
							>
								{t(TranslationKey.back)}
							</Button>
						) : null}
						<Box flexGrow={1} component="span"/>
					</DialogTitle>
					<DialogContent>
					<Container
						disableGutters
						className="modalBody"
					>
						{startOfList}
						{list}
					</Container>
					<div className="footer">
						<Button
							onClick={onClose}
							className="button"
						>
							{t(TranslationKey.back)}
						</Button>
					</div>
					</DialogContent>
				</div>
			</Dialog>
		);
	}
}

LeerlijnDrills.propTypes = {
	id: PropTypes.string,
	houseList: PropTypes.array,
	t: PropTypes.func.isRequired,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	// toggleBookmark: PropTypes.func.isRequired,
	addBookmarkToFolder: PropTypes.func,
	removeBookmarkFromFolder: PropTypes.func,
};


export default compose(
	withStyles(styles),
)(LeerlijnDrills);
