// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';



// HL 4 2022: seems no longer in use



const checkIfUserExists = (username, contextConfiguration) => {
	const escapedUsername = encodeURI(username);
	console.log('checkIfUserExists', escapedUsername);

	const config = {
		...commonConfiguration,
		...contextConfiguration,
	};

	// config.headers = {
	// 	'Cache-Control': 'no-cache',
	// };

	return axios.get(
		`/users?users=${escapedUsername}&password=x`,
		config
	).catch(onRequestError);
};

export default checkIfUserExists;
