// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

// --- IO
import API from 'io/API';

// --- Logic
import ContactIcon from 'logic/enums/ContactIcon';
import TranslationKey from 'logic/enums/TranslationKey';
// import { isStringNullOrEmpty, getIdFromApiUrl, convertToHtml, parseBrackets } from 'logic/stringOperations';
import { isStringNullOrEmpty, getIdFromApiUrl, convertToHtml, parseMd, parseNecessaryMd } from 'logic/stringOperations';
import LoadingStatus from 'logic/enums/LoadingStatus';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';

// --- External components
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core/ListItemButton';
// import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

// --- Components
import ShowMore from 'visual/components/_/text/ShowMore';
import { Transition } from 'App';
import Picture from 'visual/components/_/media/Picture';

// --- Assets
//import WikiIcon from 'assets/icons/wikipedia-logo.svg';
import WikiIcon from 'assets/icons/v-logo.svg';

// --- Styles
import { DialogContent } from '@material-ui/core';
import ItemHeadLine from '../_/item/ItemHeadLine';


// Technieken uitbreiding 1 2024
import NavBar from 'visual/components/_/navigation/NavBar';
import { visitorRoutes, authenticatedRoutes } from 'visual/components/routes';
import {Redirect} from 'react-router-dom';

import {useState} from 'react';
import {useEffect} from 'react';



// --- Style
const styles = theme => ({
	openFilterModalButton: {
		borderRadius: 16,
	},
	volleypediaModalHeader: {
		background: theme.palette.background.default,
	},
	volleypediaModalContent: {
		background: theme.palette.background.paper,
	},
	volleypediaBox: {
		columns: '500px 2',
		marginBottom: '60px',
	},
	menuListItem: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	menuList: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	menuBox: {
		paddingTop: '10px',
	},
	menuButton: {
		background: theme.palette.background.paper,
		color: theme.palette.text.primary,
		margin: '10px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.background.backgroundPaper,
		},
	},
	typography: {
		button: {
			textTransform: "none",
		},

	},
	articleContent: {
		marginRight: '60px',
		'& a': {
		fontWeight: '700',
		color: theme.palette.text.primary,
		}
	},
});



class Volleypedia extends React.Component {
	constructor(props) {
		super(props);


		this.state = {
			volleypediaArticles: {},
			appLoadingStatus: LoadingStatus.None,
			searchText: '',
			showVolleypediaMenu: true,
		};
//		this.onClose = this.onClose.bind(this);

		this.fetchVolleypediaArticles = this.fetchVolleypediaArticles.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}
/*
	onClose() {
		const { onClose } = this.props;

		this.setState({
			imageViewIndex: 0,
			showImageView: false,
		}, onClose);
	}
*/
	async fetchVolleypediaArticles() {
		const {
			props: {
				i18n,
				checkErrors,
			}
		} = this;

		if (!this._ismounted)
			return;

		const getVolleypediaArticlesResponse = await API.getVolleypediaArticles();
		if (checkErrors(getVolleypediaArticlesResponse)) {
			return;
		}

		if (!this._ismounted)
			return;

		var myHomeId = null;
		// console.log('process.env.REACT_APP_VOLLEYPEDIA_HOME',process.env.REACT_APP_VOLLEYPEDIA_HOME );
		const volleypediaArticles = getVolleypediaArticlesResponse.data.reduce((result, data) => {
			const id = getIdFromApiUrl(data.url);
			data.id = id;


// Technieken uitbreiding 1 2024  //zoek id voor redirect naar homepage  
			var VolleypediaHomeRegex = new RegExp( process.env.REACT_APP_VOLLEYPEDIA_HOME, 'gi' ); 
			if( !myHomeId  &&  data.label.match( VolleypediaHomeRegex ) ){	
				console.log('Home Found',data.label );
				myHomeId = data.id;
				this.setState({homeId: Object.freeze(myHomeId) });
			}

			if( isStringNullOrEmpty(i18n.language) ){ 
				data.i18n_label = data.label + " -- NLF --";
				data.i18n_description = data.beschrijving + " -- NLF --";
			}  // 
			else if( i18n.language == 'nl' ){
				data.i18n_label = data.label;
				data.i18n_description = data.beschrijving;
			}
			else{
				data.i18n_label = data[i18n.language];
				data.i18n_description = data['beschrijving_' + i18n.language];
			}

			data.i18n_description =  data.i18n_description 
			// + '<br/><br/><Link to="volleypedia/I20210525222427712743">some other volleypedia articel</Link>';


			// result[id] = data;
			result[id] = data;

			return result;
		}, []);


		await this.setState({ volleypediaArticles: Object.freeze(volleypediaArticles) });

		// Create sorted article array
		const articleArray = Object.entries(volleypediaArticles);

		articleArray.sort((a, b) => {
			if (a[1].i18n_label == null ) { a[1].i18n_label = ""}
			if (b[1].i18n_label == null ) { b[1].i18n_label = ""}
			//note that the sorted array contains an array per element [1] is id; [1] is the object
			return a[1].i18n_label.toLowerCase().localeCompare(b[1].i18n_label.toLowerCase(),
														i18n.language != null?i18n.language:'en', 
														{numeric: true});
			/*
			if ( a[1].label.toLowerCase() < b[1].label.toLowerCase() ){
				return -1;
			  }
			  if ( a[1].label.toLowerCase() > b[1].label.toLowerCase() ){
				return 1;
			  }

			  return 0;
			  */
		});	

		await this.setState({ sortedVolleypediaArticles: Object.freeze(articleArray) });
	}

	// --- Framework methods
	async componentDidMount() {
		this._ismounted = true;
		console.log('componentDidMount');
		// Brings back the scrolling to the top of the page when the route changes.
		// this.stopListeningToHistoryChanges = this.props.history.listen(this.onLocationChanged);

		const { appLoadingStatus } = this.state;

		if (appLoadingStatus !== LoadingStatus.None)
			return;

		await this.setState({ appLoadingStatus: LoadingStatus.Started });

		if (!this._ismounted)
			return;

		// Requests that must resolve before displaying the app.
		await Promise.all([
			this.fetchVolleypediaArticles(),
		]);

		if (!this._ismounted)
			return;

		// Requests that must resolve before displaying the app,
		// but after the initialization requests have resolved.

		if (!this._ismounted)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated) {
			await this.setState({ appLoadingStatus: LoadingStatus.Completed, splashScreenAnimationEnded: true });

			// Requests that can resolve after displaying the app.
			// this.fetchVolleypediaArticles();
			return;
		}

		this.setState({ appLoadingStatus: LoadingStatus.Completed });
	}
	
	onSearchChange (event) {
		const {
			state: {
			searchText,
			},
		} = this;
		this.setState({searchText: event.target.value});

    	this.setState({showVolleypediaMenu: true});
		
// Technieken uitbreiding 1 2024
// scroll to top na search input

		let VPModalContent = document.getElementById('volleypediaModalContent')
			if( VPModalContent ){
			VPModalContent.scrollTo({
	            top: 0,
	            behavior: "smooth",
	        });
		}

	}

	render() {
		const {
			props: {
				t,
				i18n,
				history,
//				onClose,
				contactData,
				classes,
				informationTexts,
				route,
				learningCurves,
				houseList,		// drills !!
				match: { params: { id } },
			},
			state: {
				appLoadingStatus,
				searchText,
				sortedVolleypediaArticles,
				volleypediaArticles,
				homeId,
			},
		} = this;



console.log('learningCurves', learningCurves);
console.log('houseList', houseList);

		const menuItems = [];
		if (appLoadingStatus !== LoadingStatus.Completed) {
			// splashScreen = <SplashScreen key="splash-screen" readyToDisplayApp={appLoadingStatus === LoadingStatus.Completed} />;
		}

		let header = [];
		let article = null;
		let content = [];
		let searchResults = [];
		if (appLoadingStatus === LoadingStatus.Completed) {
			
			// Create menu items
			if (volleypediaArticles != null) {
				if (searchText != '') { 
					let text = searchText.toLowerCase();
					 
					searchResults = sortedVolleypediaArticles.filter( (article) => {
						// if searchText is found, return true
						
						let i18n_label_field = ''
						let i18n_description_field = ''
						if( i18n.language == 'nl'){
							i18n_label_field = 'label';
							i18n_description_field = 'beschrijving';
						}
						else{
							i18n_label_field = i18n.language;
							i18n_description_field = 'beschrijving_' + i18n.language;
						}

						if (article[1][i18n_description_field] != null) {
							if (article[1][i18n_description_field].toLowerCase().includes(text)) {
								console.log('found');
								return true;
							}
						}
						if (article[1][i18n_label_field] != null) {
							if (article[1][i18n_label_field].toLowerCase().includes(text)) {
								return true;
							}	
						}
						return false;
					});


					// searchResults;
				}
				else{              // HL 4 2022  without searchterm, accept all articles  
					searchResults = sortedVolleypediaArticles;  
				}
				for (let index in searchResults) {
					let entry = searchResults[index][1];
//				Object.values(this.state.volleypediaArticles).forEach((entry) => {
					let i18n_label = ''
					if( i18n.language == 'nl'){
						i18n_label = entry.label;
					}
					else{
						i18n_label = entry[i18n.language];
					}
					if( i18n_label == '' ){
						i18n_label = '-';
					}
// console.log("VOLLEYPEDIA");
// console.log(entry);

					const item = (			// <div style={{display: isShown ? 'block' : 'none'}}>
						<Button
							key={`vp_article_button_${entry.id}`}
							className= { classes.menuButton }
							variant="contained"
							style={ { justifyContent: 'flex-start', textAlign: 'left', textTransform: 'none' } }
							endIcon={<Icon>chevron_right</Icon>}
							onClick={() => {history.push(`/volleypedia/${entry.id}`);
    										this.setState({showVolleypediaMenu: false});
							 		}}
						>
							<span style={{textTransform: 'none'}}>{i18n_label}</span>
						</Button>
					);
					// <span style={{textTransform: 'none'}}>{entry.label}</span>

					menuItems.push(item);
//				});
				}
			}

// let VPModalContent = document.getElementById('volleypediaMenuBox')
// 	if( VPModalContent ){
// 	VPModalContent.scrollTo({
//         top: 0,
//         behavior: "smooth",
//     });
// }


			let menuBox = (''); /////////////   TODO  HL 4 2022  translate  
			// let menuBox = ('niets gevonden'); /////////////   TODO  HL 4 2022  translate  

// Technieken uitbreiding 1 2024

			// if (menuItems.length !== 0) {
			if (menuItems.length !== 0 &&  searchText.length > 0 ) {
				// Create menu
				menuBox = (
					<Box key='box-menubox' id='volleypediaMenuBox' className={classes.menuBox}
						style={ {display: this.state.showVolleypediaMenu ? 'block' : 'none'} }

					>

							{/*<div>
								{this.state.showVolleypediaMenu ? 'showVolleypediaMenu VISIBLE' : 'showVolleypediaMenu HIDDEN' }
							</div>*/}

						<ButtonGroup
							orientation="vertical"
							aria-label="vertical contained button group"
						>
							{menuItems}
						</ButtonGroup>
					</Box>
				);
			} else
				console.log('No menu items');

			let volleypediaText = null;
			if (informationTexts != null) {
				if (informationTexts.volleypedia != null) {
					header = (
						<Grid item xs={12}>
							<div style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							}}>
								{route.icon}								
								<Typography
										variant="h5"
										component="h1"
										style={{ paddingLeft: '10px' }}
									>
											{t(TranslationKey.volleypedia)}
								</Typography>
							</div> 
						</Grid>
					);
				}
				volleypediaText = (
					<Grid item key="grid_item_vp_text">
						<Typography
							variant="body2"
							component="span"
						>
							<Trans>
								{informationTexts.volleypedia[i18n.language]}
							</Trans>
						</Typography>
					</Grid>
				);
			}

			// Are we on the volleypedia home page or an article page
			const home = [header, volleypediaText, menuBox];
			const searchBox = (
				<Grid item xs={12} key='search-box'>
				<TextField 
					style={{width: '100%', margin: '20px 0px'}}
					size="small"
					id="outlined-search" 
					label={t(TranslationKey.drills_filters_search_label)}
					value={searchText}
					type="search" 
					variant="outlined"
					onChange={this.onSearchChange}
					onClick = {this.onSearchChange}
					InputProps={{
						endAdornment: (
						<InputAdornment position="start">
							<IconButton>
							<SearchIcon />
							</IconButton>
						</InputAdornment>
						)}}
				/>
				</Grid>
			);
			content = [volleypediaText, searchBox, menuBox];



			console.log('+++++++++++=volleypediaArticles', volleypediaArticles);
			console.log('+++++++++++=learningCurves', learningCurves);


// Technieken uitbreiding 1 2024  //redirect naar homepage  
			if( id == null ){			
				return <Redirect to={'volleypedia/' + homeId} />
			}
			else if( id != null ){
				let descriptionComponent = null;


				// let i18n_label = '';
				// if( i18n.language == 'nl'){
				// 	i18n_label = this.state.volleypediaArticles[id].label;
				// }
				// else{
				// 	i18n_label = this.state.volleypediaArticles[id][i18n.language];
				// }


				// let i18n_descr = '';
				// if( i18n.language == 'nl'){
				// 	i18n_descr = this.state.volleypediaArticles[id].beschrijving;
				// }
				// else{
				// 	i18n_descr = this.state.volleypediaArticles[id]['beschrijving_'+i18n.language];
				// }
				// if( i18n_descr == '' ){
				// 	i18n_descr = '';
				// }

				if ( !isStringNullOrEmpty( this.state.volleypediaArticles[id].i18n_description ) ){

					var current = this.state.volleypediaArticles[id];

console.log('+++++++++++=current', current);
					if( current.vp ){
						console.log(`current.vp`,current.vp);
						current.vp.forEach((element, index) => {			// links naar andere VP artikelen
							// console.log(`element`,index, element);		// [vp_1] -> [complex 1](/volleypedia/I20210525222418974926)
							const humanIndex = index + 1;
							const myurl = element.url.match(/\/[^\/]*\/i\d{20}/gmi);
							const articleId = element.url.match(/i\d{20}/gmi);
							var label = element.label;
							if( i18n.language !== "" && i18n.language !== 'nl' ){
								label = volleypediaArticles[articleId].i18n_label
							}
							const searchfor = `\\[VP_${humanIndex}\\]`;
							const replacewith = `[${label}](${myurl})`;
							const regex = new RegExp(searchfor, 'gi');
							current.i18n_description = current.i18n_description.replace(regex, replacewith);
						});
					}
					if( current.leerlijn ){
						current.leerlijn.forEach((element, index) => {		// links naar leerlijnen
							// console.log(`element`,index, element);		// [leerlijn_1] -> [label](/leerlijn/I20210525222418974926)
							const humanIndex = index + 1;
							const myurl = element.url.match(/\/[^\/]*\/i\d{20}/gmi);
							const articleId = element.url.match(/i\d{20}/gmi);
							var label = element.label;
							if( i18n.language !== "" && i18n.language !== 'nl' ){
								label = learningCurves[element.label].i18n_label		// HL 2 2024, hmmm, label is als key van de hash gebruikt
							}
							const searchfor = `\\[leerlijn_${humanIndex}\\]`;
							const replacewith = `[${label}](${myurl})`;
							const regex = new RegExp(searchfor, 'gi');
							current.i18n_description = current.i18n_description.replace(regex, replacewith);
						});
					}
					if( current.drill ){
						current.drill.forEach((element, index) => {			// links naar drills
							// console.log(`element`,index, element);		// [drill_1] -> [label](/drill/I20210525222418974926)
							const humanIndex = index + 1;
							const myurl = element.url.match(/\/[^\/]*\/i\d{20}/gmi);
							const articleId = element.url.match(/i\d{20}/gmi);
							var label = element.label;
							if( i18n.language !== "" && i18n.language !== 'nl' ){
								var result = houseList.filter(obj => {		//  houselist = drills
									return obj.id == articleId
								})
								if( result[0] ){
									label = result[0].i18n_label
								}
							}
							const searchfor = `\\[drill_${humanIndex}\\]`;
							const replacewith = `[${label}](${myurl})`;
							const regex = new RegExp(searchfor, 'gi');
							current.i18n_description = current.i18n_description.replace(regex, replacewith);
						});
					}
					if( current.image ){			
						var images = new Array();;
						if( Array.isArray(current.image) ){	// API returns array when multiple values are present
							images = current.image
						}
						else{								// API returns single value when singelton
							images[0] = current.image;		// Convert to array
						}
						images.forEach((element, index) => {				// images
							console.log(`element`,index, element);			// [image_1] -> ![filename.png](https://.../filename.png)
							const humanIndex = index + 1;
							const myurl = element;
							var label = element.match(/[^\/]*$/)[0];
							console.log('Image myurl', myurl);
							console.log('Image label', label);

							const searchfor = `\\[image_${humanIndex}\\]`;
							const replacewith = `![${label}](${myurl})`;
							const regex = new RegExp(searchfor, 'gi');
							current.i18n_description = current.i18n_description.replace(regex, replacewith);
						});
					}
					if( current.vid ){
						var vids = new Array();;
						if( Array.isArray(current.vid) ){	// API returns array when multiple values are present
							vids = current.vid
						}
						else{								// API returns single value when singelton
							vids[0] = current.vid;			// Convert to array
						}
						vids.forEach((element, index) => {					// vid for video's
							console.log(`element`,index, element);			// [VID_1] -> [vid_123456789] (vimeo id)
							const humanIndex = index + 1;
							const video_id = element;
							console.log('video_id', video_id);
							const searchfor = `\\[vid_${humanIndex}\\]`;
							const replacewith = `[vid_${video_id}]`;
							const regex = new RegExp(searchfor, 'gi');
							current.i18n_description = current.i18n_description.replace(regex, replacewith);
						});
					}
// console.log('+++++++++++ square brackets are prepared now');

					// descriptionComponent = parseBrackets(this.state.volleypediaArticles[id].i18n_description);
					
					if( current.opmaak[0] && current.opmaak[0].label == 'ja' ){
						descriptionComponent = parseMd(current.i18n_description);
					}
					else{
						descriptionComponent = parseNecessaryMd(current.i18n_description);
					}
					// descriptionComponent = parseBrackets(current.i18n_description);

					// if (!isStringNullOrEmpty(this.state.volleypediaArticles[id].beschrijving)) {
					// 	descriptionComponent = parseBrackets(this.state.volleypediaArticles[id].beschrijving);
					// if (!isStringNullOrEmpty(i18n_descr)) {
					// 	descriptionComponent = parseBrackets(i18n_descr);

					// descriptionComponent = (
					// 	<Typography 
					// 		variant="body1" 
					// 			color="textSecondary" 
					// 			style={{ fontStyle: 'italic' }}
					// 			dangerouslySetInnerHTML={{ __html: parseBrackets(this.state.volleypediaArticles[id].i18n_description) }}
					// 	>
					// 	</Typography>
					// );


				} 
				else {
					descriptionComponent = (
						<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
							{convertToHtml(t(TranslationKey.description_caveat_empty))}
						</Typography>
					);
				}
//				descriptionComponent.push(<Link to="/drills">Drills</Link>);
				let imageComponent = null;
				if (!isStringNullOrEmpty(this.state.volleypediaArticles[id].foto_s)) {
					imageComponent = (<>
						<Box sx={{padding: "20px 0px 10px 0px"}}>
							<Divider />
							<Box sx={{textAlign: "center"}}>
								<Chip label={(t(TranslationKey.volleypedia_media_images))} />
							</Box>
							<Divider />
						</Box>
						<img style={{maxWidth: "100%", maxHeight: "300px"}}
							src={encodeURI(this.state.volleypediaArticles[id].foto_s)}
							alt={this.state.volleypediaArticles[id].label}
						/>
					</>);
				}
				let videoComponent = null;
				if (!isStringNullOrEmpty(this.state.volleypediaArticles[id].video_id)) {
					videoComponent = (<iframe
						src={encodeURI(`https://player.vimeo.com/video/${this.state.volleypediaArticles[id].video_id}?badge=0&amp;autopause=0&amp;player_id=0`)}
						style={{
							height: '400px',
							width: '100%',
							padding: "20px 0px 10px 0px",
						}}
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="170721_BHA_89B"
					/>);
				}


// Technieken uitbreiding 1 2024

				let navigation = null;
				navigation = <NavBar routes={authenticatedRoutes}/>;
				// var article_TEST = (<Grid container>
				// 				<Grid item xs={12}>
				// 					<h3>
				// 						{this.state.volleypediaArticles[id].i18n_label}
				// 					</h3>
				// 				</Grid>
				// 				<Grid item xs={12}>
				// 					<Box className={classes.articleContent}>
				// 						{descriptionComponent}
				// 					</Box>
				// 				</Grid>
				// 				<Grid item xs={12} md={12}>
				// 					{imageComponent}
				// 				</Grid>
				// 				<Grid item xs={12}>
				// 					{videoComponent}
				// 				</Grid>
				// 			</Grid>

				// 	);
				article = (
					<Dialog
					fullScreen
					open={id != null}
					TransitionComponent={Transition}
					aria-labelledby="alert-dialog-slide-title-volleypedia"
					aria-describedby="alert-dialog-slide-description"
					className="modal"
					>
						<DialogTitle
							disableTypography
							className="modalBar"
							id="apply-filter-modal-title"
						>

							<Grid container justifyContent='space-between'>
{/*								<Grid item xs={3}>
									<Button
										onClick={() => { history.push('/volleypedia'); } }
										aria-label="close"
										startIcon={<Icon>close</Icon>}
									>
										{t(TranslationKey.modal_window_close)}
									</Button>
								</Grid>*/}
								<Grid item xs={12} sm={4} style={{paddingTop:'14px'}}>
									{header}
								</Grid>
								<Grid item xs={8} >
									{searchBox}
								</Grid>
								<Grid item xs={12} >
									<Divider orientation="horizontal"  />
								</Grid>	
							</Grid>	

						</DialogTitle>
						<DialogContent className={classes.volleypediaModalContent} id='volleypediaModalContent'>

							{/*<Grid container>*/}
							<div className={classes.volleypediaBox} >
								<Grid item xs={12} md={12}>
									{menuBox}  {/* search results */}
								</Grid>
								<Grid item xs={12}>
									<h3>
										{this.state.volleypediaArticles[id].i18n_label}
									</h3>
								</Grid>
								<Grid item xs={12}>
									<Box className={classes.articleContent}>
										{descriptionComponent}
									</Box>
								</Grid>
								<Grid item xs={12} md={12}>
									{imageComponent}
								</Grid>
								<Grid item xs={12}>
									{videoComponent}
								</Grid>
								{/*<Grid item xs={12}>
									<Link to='/volleypedia/I20210525222427712743'>some other vlp volleypedia articel</Link>
								</Grid>*/}
							</div>
							{/*</Grid>*/}
						</DialogContent>
					<div className="footer">
						{navigation}
					</div>
				</Dialog>
				);
			}
		}
// {this.state.volleypediaArticles[id].label}

		return (
			<Container
				disableGutters
				className="page"
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}>
							{route.icon}								
							<Typography
									variant="h5"
									component="h1"
									style={{ paddingLeft: '10px' }}
								>
										{t(TranslationKey.volleypedia)}
							</Typography>
						</div> 
					</Grid>
					<Grid item>
						{content}
					</Grid>
				</Grid>

				<Dialog
					// fullScreen
					open={id != null}
					TransitionComponent={Transition}
					aria-labelledby="alert-dialog-slide-title-volleypedia"
					aria-describedby="alert-dialog-slide-description"
					className="modal"
				>
					<Grid
						container
						spacing={3}
						direction="column"
					>

						{article}

						<Grid item />
					</Grid>
					<div className="footer">
						<Button
							onClick={() => { history.push('/volleypedia'); } }
							className="button"
						>
							{t(TranslationKey.back)}
						</Button>
					</div>

				</Dialog>
			</Container>
		);
	}
}

Volleypedia.propTypes = {
	contactData: PropTypes.object,
	informationTexts: PropTypes.object,
	match: PropTypes.object, 
	classes: PropTypes.object.isRequired,
	i18n: PropTypes.object,
	t: PropTypes.func,
	history: PropTypes.object.isRequired,
//	onClose: PropTypes.func.isRequired,
	route: PropTypes.object.isRequired,

	// history: PropTypes.shape({
	// 	push: PropTypes.func.isRequired,
	// 	listen: PropTypes.func.isRequired,
	// }),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Volleypedia);
