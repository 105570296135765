// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	chip: {
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	}
});

class DropDownGroupOnlySelector extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			t,
			i18n,
			items,
			classes,
			onItemRemoved,
			itemLabelText,
			itemFieldText,
			itemsEmptyText,
			onItemSelected,
			supportedGroups,
			renderGroupIcon,
		} = this.props;

		let selectableItems = [];
		Object.keys(supportedGroups)
			.filter(groupId => !items.some(item => item.itemId == null && item.groupId === groupId))
			.forEach((groupId) => {
				const group = supportedGroups[groupId];

				let groupIcon = null;
				if (renderGroupIcon != null) {
					groupIcon = (
						<span style={{ marginRight: 8 }}>
							{renderGroupIcon(group)}
						</span>
					);
				}

				selectableItems.push(
					<MenuItem
						key={groupId}
						value={{ groupId }}					>
						{groupIcon}
						{group[i18n.language] || group.nl}
					</MenuItem>
				);
			});

		return (
			<Grid item style={{ marginBottom: '16px' }}>
				<Typography
					id="items-label"
					variant="subtitle1"
					style={{ paddingBottom: 8, fontWeight: 600 }}
				>
					{itemLabelText}
				</Typography>

				<FormControl
					fullWidth
					size="small"
					variant="outlined"
				>
					<Select
						value={''}
						displayEmpty
						id="select-filter-items"
						style={{ color: '#808080' }}
						onChange={({ target: { value: selectedItem } }) => onItemSelected(selectedItem)}
					>
						<MenuItem
							value=""
							disabled
						>

							{items[0] != null 
								? (supportedGroups[items[0].groupId])[i18n.language] 
								: itemFieldText
							}
						</MenuItem>
						{selectableItems}
					</Select>
				</FormControl>
			</Grid>
		);
	}
}

DropDownGroupOnlySelector.propTypes = {
	t: PropTypes.func.isRequired,
	renderGroupIcon: PropTypes.func,
	i18n: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	onItemRemoved: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	itemLabelText: PropTypes.string.isRequired,
	itemFieldText: PropTypes.string.isRequired,
	itemsEmptyText: PropTypes.string.isRequired,
	supportedGroups: PropTypes.object.isRequired,
};

DropDownGroupOnlySelector.defaultProps = {
	renderGroupIcon: null,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(DropDownGroupOnlySelector);
