// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
// --- Components
import { Transition } from 'App';
import DropDownSelector from 'visual/components/_/form/DropDownSelector';
import MainTechniqueIcon from 'visual/components/icons/MainTechniqueIcon';
import DropDownTreeGroupSelector from 'visual/components/_/form/DropDownTreeGroupSelector';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Drawer from "@material-ui/core/Drawer";

// --- Style

const styles = theme => ({
	chip: {
		
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	},
	paper: {
		margin: '5px 0px 0px 0px',
		width: '100%',
		maxHeight: 'calc(100% - 45px)',
		backgroundColor: '#000000',
	},
	scrollPaper: {
		width: '100%',
		height: '100%',
		alignItems: 'flex-start',
	},

});


export const countActiveFilters = (activeFilters) => {

	 
	 
	if (activeFilters == null || activeFilters === {})
		return 0;

	let count = 0;

	if (activeFilters.niveau != null)
		count++;

	if (activeFilters.hoofdtechniek != null || activeFilters.subtechniek != null)
		count++;

	return count;
};

const defaultState = Object.freeze({
	hasChanged: false,
	levels: [],
	technics: [],
	searchText: '',
});

class ApplyFiltersDrawer extends React.Component {
	constructor(props) {
		super(props);

		const {
			alwaysUpdateFilterPreference,
		} = props;

		this.state = {
			open: true,
			activeFilterCount: -1,
			updateFilterPreference: alwaysUpdateFilterPreference,
			sidebarOpen: false,
			...defaultState,
		};

		this.onClose = this.onClose.bind(this);
		this.onClear = this.onClear.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onRestore = this.onRestore.bind(this);
		this.onLevelRemoved = this.onLevelRemoved.bind(this);
		this.onLevelSelected = this.onLevelSelected.bind(this);
		this.onTechnicRemoved = this.onTechnicRemoved.bind(this);
		this.onTechnicSelected = this.onTechnicSelected.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);

	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop/state values.
	static getDerivedStateFromProps(nextProps, previousState) {
		const {
			isOpen,
			activeFilters,
			supportedSubTechnics,
		} = nextProps;

		const {
			open: wasOpen,
			levels,
			technics,
		} = previousState;

		// The goal here is to reset the state if the appliedFilters prop is set to null
		// from higher up in the hierarchy. `this.onClear` method is a punctual call where
		// the state can be edited as usual, but if the filters are edited from say App.jsx
		// (which is the actual bearer of appliedFilters props, it's one of App.jsx' state)
		// then this component has to figure out from its props that something changed
		// so that it updates its state accordingly.

		const nextState = { };

		const nextActiveFilterCount = countActiveFilters(activeFilters);

		nextState.activeFilterCount = nextActiveFilterCount;


		return nextState;
	}

	onLevelToggled = (event) => {
		setChecked(event.target.checked);
	};

	// --- Event methods
	onLevelSelected(selectedLevel) {
		this.setState(({ levels }) => {
			levels.push(selectedLevel);
			return { hasChanged: true, levels };
		});
	}

	onLevelRemoved(index) {
		this.setState(({ levels }) => {
			levels.splice(index, 1);
			return { hasChanged: true, levels };
		});
	}

	onTechnicSelected(selectedTechnic) {
		const {
			props: {
				asSidebar
			}
		} = this;
		 
		 
		this.setState(({ technics }) => {
			// When itemId is null, a technic has been selected (not a sub technic), in that
			// case all the sub technics of that group should be removed from the selection.
			if (selectedTechnic.itemId == null)
				technics = technics.filter(({ groupId }) => selectedTechnic.groupId !== groupId);

			technics.push(selectedTechnic);

			return { hasChanged: true, technics };
			}, () => { if (asSidebar) {
			this.onSubmit();
		}});
	}

	onTechnicRemoved(index) {
		const {
			props: {
				asSidebar,
			}
		} = this;
		 
		this.setState(({ technics }) => {
			technics.splice(index, 1);
			return { hasChanged: true, technics };
		}, () => { if (asSidebar) {
			this.onSubmit();
		}});
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		const {
			props: {
				onApply,
			},
			state: {
				levels,
				technics,
				updateFilterPreference,
			}
		} = this;

		const filters = {
			levels: null,
			technics: null,
		};

		if (levels.length > 0)
			filters.niveau = [...levels];

		if (technics.length > 0) {
			const hoofdtechniek = technics.filter(({ itemId }) => itemId == null).map(({ groupId }) => groupId);

			if (hoofdtechniek.length > 0)
				filters.hoofdtechniek = hoofdtechniek;

			const subtechniek = technics.filter(({ itemId }) => itemId != null).map(({ itemId }) => itemId);

			if (subtechniek.length > 0)
				filters.subtechniek = subtechniek;
		}

		await onApply(filters, updateFilterPreference);

		this.onClose();
	}

	onRestore() {
		const {
			props: {
				supportedLevels,
				filterPreference,
				supportedTechnics,
				alwaysUpdateFilterPreference,
			},
			state: {
				levels,
				technics,
			},
		} = this;

		const nextFilterCount = countActiveFilters(filterPreference);

		const nextState = {
			activeFilterCount: nextFilterCount,
			hasChanged: !alwaysUpdateFilterPreference,
			updateFilterPreference: alwaysUpdateFilterPreference,
		};

		if (nextFilterCount <= 0 || filterPreference.hoofdtechniek == null) {
			technics.length = 0;
			nextState.technics = technics;
		} else
			nextState.technics = Object.keys(supportedTechnics).filter(id => filterPreference.hoofdtechniek.indexOf(id) >= 0);

		if (nextFilterCount <= 0 || filterPreference.levels == null) {
			levels.length = 0;
			nextState.levels = levels;
		} else
			nextState.levels = Object.keys(supportedLevels).filter(id => filterPreference.niveau.indexOf(id) >= 0);

		this.setState(nextState);
	}

	onClear() {
		const {
			onApply,
			applyOnClear,
			alwaysUpdateFilterPreference,
		} = this.props;

		this.setState(({ levels, technics }) => {
			// Replacing the arrays to new ones doesn't trigger the related react
			// elements to re-render, emptying the existing array instances does.
			levels.length = 0;
			technics.length = 0;

			return {
				...defaultState,
				levels,
				technics,
				activeFilterCount: 0,
				hasChanged: !applyOnClear,
				updateFilterPreference: alwaysUpdateFilterPreference,
			};
		});

		if (!applyOnClear)
			return;

		onApply(null, false);

//		this.onClose();
	}

	onSearchChange (event) {
		const {
			props: {
				onSearchChange,
			},
			state: {
			searchText,
			},
		} = this;

		 
		onSearchChange(event.target.value);
	}

	onClose() {
		const {
			props: {
				onClose,
				alwaysUpdateFilterPreference,
			},
		} = this;

		this.setState({
			hasChanged: false,
			updateFilterPreference: alwaysUpdateFilterPreference,
		});

		onClose();
	}

	render() {
		const {
			props: {
				t,
				i18n,
				isOpen,
				sidebarOpen,
				classes,
				isAuthenticated,
				supportedLevels,
				filterPreference,
				supportedTechnics,
				supportedSubTechnics,
				alwaysUpdateFilterPreference,
				searchText,
				width,
				asSidebar,
				onApply,
				...other
			},
			state: {
				levels,
				technics,
				hasChanged,
				activeFilterCount,
				updateFilterPreference,
			}
		} = this;

		let updateFilterPreferenceCheckbox = null;
/*		Disable standard filter functionality
		if (isAuthenticated && !alwaysUpdateFilterPreference) {
			updateFilterPreferenceCheckbox = (
				<Grid item>
					<FormControlLabel
						label={t(TranslationKey.modal_filters_update_preference_checkbox)}
						control={
							<Checkbox
								color="primary"
								name="update-filter-preference"
								checked={updateFilterPreference}
								onChange={({ target: { checked } }) => this.setState({ updateFilterPreference: checked })}
							/>
						}
					/>
				</Grid>
			);
		}
*/
		// Level checkboxes
		let levelFilterCheckboxes = [];
		 
		for (const [key, supportedLevel] of Object.entries(supportedLevels)) {			 
			 
			let checked = false;
			for (let index in levels) {
				 
				 
				if (levels[index] == supportedLevel.id) {
					checked = true;
					 
					break;
				}
			}
			levelFilterCheckboxes.push( (
				<Grid item key={`cb_grid_item_${supportedLevel.id}`}>
					<FormControlLabel
						key={`form_cont_label_cb_${supportedLevel.id}`}
						label={supportedLevel[i18n.language]}
						control={
							<Checkbox
								key={`levelcb_${supportedLevel.id}`}
								color="primary"
								name="update-filter-preference"
								checked={checked}
								onChange={({ target: { checked } }) => { this.setState(({ levels }) => {
									if (checked) {
										levels.push(supportedLevel.id);
									}
									else {
										const index = levels.indexOf(supportedLevel.id);
										if (index > -1) {
											levels.splice(index, 1);
										}
									}
									return { hasChanged: true, levels };
								}, () => { /* Update immediately when results are visible */ 
											if (asSidebar) {
												this.onSubmit();
											}
										} ) }}
							/>
						}
					/>
				</Grid>
			)
			);
		}
		let levelCheckboxes = (
			<Grid item xs={12} style={{ marginBottom: '16px' }}>
			<Typography
				id="items-label"
				variant="subtitle1"
				style={{ paddingBottom: 8, fontWeight: 600 }}
			>
				{t(TranslationKey.modal_filters_select_levels_label)}
			</Typography>
				{levelFilterCheckboxes}
			</Grid>
		)
		 
		 

		return (
				<Box maxWidth={width} sx={{overflow: 'hidden', paddingBottom: '20px'}}>
						<Grid container justifyContent='space-between' >
		
							<Grid item xs={12}>
								<Typography
									variant="h6"
								>
									{t(TranslationKey.modal_filters_title)}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{paddingBottom: '10px'}}>
								<Divider orientation="horizontal"  />
								</Grid>


							<Grid item>
								<Button
									size="small"
									variant="outlined"
									onClick={this.onClear}
									className={classes.clearButton}
									aria-label="clear filters button"
									disabled={! ((this.state.hasChanged) || (activeFilterCount > 0)) }
								>
									{t(TranslationKey.modal_filters_clear_button)}
								</Button>
							</Grid>
						</Grid>

					<Container
						noValidate
						maxWidth="xs"
						sx={{overflow: 'hidden'}}
						component="form"
						onSubmit={this.onSubmit}
						id="apply-filter-modal-content"
					>

						<Grid
							container
							spacing={2}
							direction="column"
							className="content"
							alignItems="stretch"
							justifyContent="center"
						>

							<Grid item xs={12}>
								<TextField 
									style={{width: '100%', margin: '20px 0px'}}
									size="small"
									id="outlined-search" 
									label={t(TranslationKey.drills_filters_search_label)}
									value={searchText}
									type="search" 
									variant="outlined"
									onChange={this.onSearchChange}
									InputProps={{
										endAdornment: (
										<InputAdornment position="start">
											<IconButton>
											<SearchIcon />
											</IconButton>
										</InputAdornment>
										)}}
								/>
							</Grid>
							
							{levelCheckboxes}

							<Grid item xs={12}>
								<DropDownTreeGroupSelector
									items={technics}
									{...other}
									supportedGroups={supportedTechnics}
									supportedItems={supportedSubTechnics}
									onItemRemoved={this.onTechnicRemoved}
									onItemSelected={this.onTechnicSelected}
									getGroupItemIds={technic => technic.subTechnicIds}
									itemLabelText={t(TranslationKey.modal_filters_select_technics_label)}
									itemFieldText={t(TranslationKey.modal_filters_select_technics_field)}
									itemsEmptyText={t(TranslationKey.modal_filters_select_technics_empty)}

									// NOTE Uncomment the following line to display its icon next to each technic in the dropdown.
									// renderGroupIcon={technic => <MainTechniqueIcon mainTechnique={technic.label}/>}
								/>
							</Grid>
							{/*updateFilterPreferenceCheckbox //Disable standard filter functionality */}
							{/* <Grid item>
								<Button
									fullWidth
									size="large"
									type="submit"
									color="primary"
									disableElevation
									variant="contained"
									disabled={false}
								>
									{!updateFilterPreference ? t(TranslationKey.modal_filters_apply_button) : t(TranslationKey.modal_filters_apply_and_save_button)}
								</Button>
							</Grid>
							*/}
							{/* Disable standard filter functionality
								isAuthenticated && (
									<Grid item>
										<Button
											fullWidth
											size="small"
											variant="outlined"
											onClick={this.onRestore}
											disabled={filterPreference == null || (alwaysUpdateFilterPreference && !hasChanged)}
											aria-label="restore filter preference button"
										>
											{t(TranslationKey.modal_filters_restore_preference_button)}
										</Button>
									</Grid>
								)*/
							}
						</Grid>
					</Container>
				{/*}	<div className="footer">
						<Button
							onClick={this.onClose}
							className="button"
						>
							{t(TranslationKey.cancel)}
						</Button>
					</div>
						*/}
						</Box>
		);
	}
}

ApplyFiltersDrawer.propTypes = {
	isOpen: PropTypes.bool,
	t: PropTypes.func.isRequired,
	applyOnClear: PropTypes.bool,
	activeFilters: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onApply: PropTypes.func.isRequired,
	filterPreference: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	alwaysUpdateFilterPreference: PropTypes.bool,
	supportedLevels: PropTypes.object.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	supportedSubTechnics: PropTypes.object.isRequired,
	onSearchChange: PropTypes.func.isRequired,
	asSidebar: PropTypes.bool,
};

ApplyFiltersDrawer.defaultProps = {
	isOpen: false,
	applyOnClear: true,
	activeFilters: null,
	filterPreference: null,
	alwaysUpdateFilterPreference: false,
	asSidebar: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(ApplyFiltersDrawer);
