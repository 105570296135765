// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const patchFolderBookmarks = (folderID, changes, contextConfiguration) => axios.patch(
	`/bookmark_folders/${folderID}`,
	changes,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default patchFolderBookmarks;
