// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider'
// --- Components
import { Transition } from 'App';
import MainTechniqueIcon from 'visual/components/icons/MainTechniqueIcon';
import DropDownGroupOnlySelector from 'visual/components/_/form/DropDownGroupOnlySelector';
import DropDownItemSelector from 'visual/components/_/form/DropDownItemSelector';

// --- Style
//import './FilterBox.sass';

const styles = theme => ({
	chip: {
		
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	}
});


export const countActiveFilters = (activeFilters) => {
	if (activeFilters == null || activeFilters === {})
		return 0;

	let count = 0;

	if (activeFilters.niveau != null)
		count++;

	if (activeFilters.hoofdtechniek != null || activeFilters.subtechniek != null)
		count++;

	return count;
};

const defaultState = Object.freeze({
	hasChanged: false,
	levels: [],
	technics: [],
	subTechnics: [],

	// To show in box when subtechnic is selected as the active filter should
	// not contain the main technic when a subtechnic is selected
	selectedMainTechnics: [], 
});

class FilterBox extends React.Component {
	constructor(props) {
		super(props);

		const {
			alwaysUpdateFilterPreference,
		} = props;

		this.state = {
			open: false,
			activeFilterCount: -1,
			updateFilterPreference: alwaysUpdateFilterPreference,
			test: [],
			...defaultState,
		};

		this.onClose = this.onClose.bind(this);
		this.onClear = this.onClear.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onRestore = this.onRestore.bind(this);
		this.onLevelRemoved = this.onLevelRemoved.bind(this);
		this.onLevelSelected = this.onLevelSelected.bind(this);
		this.onTechnicRemoved = this.onTechnicRemoved.bind(this);
		this.onTechnicSelected = this.onTechnicSelected.bind(this);
		this.onSubTechnicSelected = this.onSubTechnicSelected.bind(this);
	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop/state values.
	static getDerivedStateFromProps(nextProps, previousState) {
		 
		const {
			activeFilters,
			supportedSubTechnics
		} = nextProps;

		const {
			open: wasOpen,
			levels,
			technics,
			subTechnics,
			selectedMainTechnics,
		} = previousState;

		// The goal here is to reset the state if the appliedFilters prop is set to null
		// from higher up in the hierarchy. `this.onClear` method is a punctual call where
		// the state can be edited as usual, but if the filters are edited from say App.jsx
		// (which is the actual bearer of appliedFilters props, it's one of App.jsx' state)
		// then this component has to figure out from its props that something changed
		// so that it updates its state accordingly.



		const nextState = {};

		const nextActiveFilterCount = countActiveFilters(activeFilters);

		nextState.activeFilterCount = nextActiveFilterCount;

		if (nextActiveFilterCount === 0 || activeFilters.niveau == null) {
			levels.length = 0;
			nextState.levels = levels;
		} else if (activeFilters.niveau !== levels)
			nextState.levels = [...activeFilters.niveau];

		if (nextActiveFilterCount === 0 || (activeFilters.hoofdtechniek == null && activeFilters.subtechniek == null)) {
			technics.length = 0;
			subTechnics.length = 0;
			selectedMainTechnics.length = 0;
			nextState.technics = technics;
			nextState.subTechnics = subTechnics;
			nextState.selectedMainTechnics = selectedMainTechnics;
		} else {
			const selectedTechnics = technics.filter(({ itemId }) => itemId == null).map(({ groupId }) => groupId);
			const selectedSubTechnics = technics.filter(({ itemId }) => itemId != null).map(({ itemId }) => itemId);

			//  

			if (activeFilters.hoofdtechniek !== selectedTechnics || activeFilters.subtechniek !== selectedSubTechnics) {
				let nextTechnics = [];

				if (activeFilters.hoofdtechniek != null) {
					nextTechnics = activeFilters.hoofdtechniek.map(technicId => ({ groupId: technicId }));
				}
				else {
					// Derive mainTechnic from subTechnic if not null
					if ( (activeFilters.subtechniek != null) ){
						// Get the main technic
						nextState.selectedMainTechnics = [supportedSubTechnics[activeFilters.subtechniek[0]].technicId];
					}
				}

				if (activeFilters.subtechniek != null) {
					nextTechnics = [...nextTechnics, ...activeFilters.subtechniek.map(subTechnicId => ({
						groupId: supportedSubTechnics[subTechnicId].technicId,
						itemId: subTechnicId
					}))];
				}

				nextState.technics = nextTechnics;
			}
		}

		return nextState;
	}

	onLevelToggled = (event) => {
		setChecked(event.target.checked);
	};

	// --- Event methods
	onLevelSelected(selectedLevel) {
		const {
			state: {
				levels,
			}
		} = this;

		// Delete all previously selected levels
		if (levels.length > 0) {
			levels.splice(0,levels.length);
		}

		levels.push(selectedLevel);	
		this.onSubmit();

		 
		 	
	}

	onLevelRemoved(index) {
		this.setState(({ levels }) => {
			levels.splice(index, 1);
			return { hasChanged: true, levels };
		});
	}

	async onTechnicSelected(selectedTechnic) {
		const {
			state: {
				technics,
				subTechnics,
			}
		} = this;

		// A new main technic has been selected, so remove selected subtechnics
		if (subTechnics.length > 0) {
			subTechnics.splice(0,subTechnics.length);
		}

		 
		 
		// State will be updated after onSubmit through getDerivedStateFromProps

		// Delete all previously selected technics and add selected item
		if (technics.length > 0) {
			technics.splice(0,technics.length);
		}
		this.setState((selectedMainTechnics) => {
				selectedMainTechnics = [selectedTechnic];
			return {selectedMainTechnics}
		});
		technics.push(selectedTechnic);	
		this.onSubmit();

		 
		 

	}

	onTechnicRemoved(index) {
		 
		this.setState(({ technics }) => {
			technics.splice(index, 1);
			return { hasChanged: true, technics };
		});
	}

	async onSubTechnicSelected(selectedSubTechnic) {
		 
		// New subtechnic is selected. Remove any main technics from filter
		// as it is part of the active filter. The main technic should however
		// be shown in the dropdown box, so find a solution for that

		const {
			state: {
				technics,
				subTechnics,
			}
		} = this;

		// Delete all previously selected technics and add selected item
		if (technics.length > 0) {
			technics.splice(0,technics.length);
		}
		// Remove previously selected subtechnics
		if (subTechnics.length > 0) {
			subTechnics.splice(0,subTechnics.length);
		}

		subTechnics.push(selectedSubTechnic);
		 
		this.onSubmit();
	}


	async onSubmit(event) {
		 		
		if (event != null)
			event.preventDefault();

		const {
			props: {
				onApply,
			},
			state: {
				levels,
				technics,
				subTechnics,
				updateFilterPreference,
			}
		} = this;

		const filters = {
			levels: null,
			technics: null,
		};

		if (levels.length > 0)
			filters.niveau = [...levels];

		 
		if (technics.length > 0) {
			 
			const hoofdtechniek = technics.filter(({ itemId }) => itemId == null).map(({ groupId }) => groupId);
			if (hoofdtechniek.length > 0)
				filters.hoofdtechniek = hoofdtechniek;

		}else { }


		if (subTechnics.length > 0) {
			const subtechniek = technics.filter(({ itemId }) => itemId != null).map(({ itemId }) => itemId);
			filters.subtechniek = subTechnics;
			 
		}
		else { }

		await onApply(filters, updateFilterPreference);

		this.onClose();
	}

	onRestore() {
		const {
			props: {
				supportedLevels,
				filterPreference,
				supportedTechnics,
				alwaysUpdateFilterPreference,
			},
			state: {
				levels,
				technics,
			},
		} = this;

		const nextFilterCount = countActiveFilters(filterPreference);

		const nextState = {
			activeFilterCount: nextFilterCount,
			hasChanged: !alwaysUpdateFilterPreference,
			updateFilterPreference: alwaysUpdateFilterPreference,
		};

		if (nextFilterCount <= 0 || filterPreference.hoofdtechniek == null) {
			technics.length = 0;
			nextState.technics = technics;
		} else
			nextState.technics = Object.keys(supportedTechnics).filter(id => filterPreference.hoofdtechniek.indexOf(id) >= 0);

		if (nextFilterCount <= 0 || filterPreference.levels == null) {
			levels.length = 0;
			nextState.levels = levels;
		} else
			nextState.levels = Object.keys(supportedLevels).filter(id => filterPreference.niveau.indexOf(id) >= 0);
		 
		this.setState(nextState);
	}

	onClear() {
		const {
			onApply,
			applyOnClear,
			alwaysUpdateFilterPreference,
		} = this.props;
		 
		this.setState(({ levels, technics, subtechnics, selectedMainTechnics }) => {
			// Replacing the arrays to new ones doesn't trigger the related react
			// elements to re-render, emptying the existing array instances does.
			levels.length = 0;
			technics.length = 0;
			subtechnics.length = 0;
			selectedMainTechnics.length = 0;
			return {
				...defaultState,
				levels,
				technics,
				activeFilterCount: 0,
				hasChanged: !applyOnClear,
				updateFilterPreference: alwaysUpdateFilterPreference,
			};
		});

		if (!applyOnClear)
			return;

		onApply(null, false);

//		this.onClose();
	}

	onClose() {
		const {
			props: {
				onClose,
				alwaysUpdateFilterPreference,
			},
		} = this;

		this.setState({
			hasChanged: false,
			updateFilterPreference: alwaysUpdateFilterPreference,
		});

		onClose();
	}

	render() {
		const {
			props: {
				t,
				i18n,
				classes,
				supportedLevels,
				filterPreference,
				supportedTechnics,
				supportedSubTechnics,
				alwaysUpdateFilterPreference,
				activeFilters,
			},
			state: {
				levels,
				technics,
				selectedMainTechnics,
				hasChanged,
				activeFilterCount,
				updateFilterPreference,
			}
		} = this;

		let updateFilterPreferenceCheckbox = null;
/*		Disable standard filter functionality
		if (isAuthenticated && !alwaysUpdateFilterPreference) {
			updateFilterPreferenceCheckbox = (
				<Grid item>
					<FormControlLabel
						label={t(TranslationKey.modal_filters_update_preference_checkbox)}
						control={
							<Checkbox
								color="primary"
								name="update-filter-preference"
								checked={updateFilterPreference}
								onChange={({ target: { checked } }) => this.setState({ updateFilterPreference: checked })}
							/>
						}
					/>
				</Grid>
			);
		}
*/
		// Level checkboxes
		let levelFilterCheckboxes = [];
		 
		for (const [key, supportedLevel] of Object.entries(supportedLevels)) {		 
			 
			let checked = false;
			for (let index in levels) {
				 
				 
				if (levels[index] == supportedLevel.id) {
					checked = true;
					 
					break;
				}
			}
			levelFilterCheckboxes.push( (
				<Grid item key={`cb_grid_item_${supportedLevel.id}`}>
					<FormControlLabel
						key={`form_cont_label_cb_${supportedLevel.id}`}
						label={supportedLevel[i18n.language]}
						control={
							<Checkbox
								key={`levelcb_${supportedLevel.id}`}
								color="primary"
								name="update-filter-preference"
								checked={checked}
								onChange={({ target: { checked } }) => { this.setState(({ levels }) => {
									if (checked) {
										levels.push(supportedLevel.id);
									}
									else {
										const index = levels.indexOf(supportedLevel.id);
										if (index > -1) {
											levels.splice(index, 1);
										}
									}
									return { hasChanged: true, levels };
								})}}
							/>
						}
					/>
				</Grid>
			)
			);
		}
		let selectedSubTechnics = [];
//		let supportedItems = supportedSubTechnics.filter(		
//								subTechnic => subTechnic.technicId == activeFilters.hoofdtechniek[0]
//							);
		let subTechnicSelector = [];
		// Eric TODO: for now assume one hoofdtechniek [0], let's make sure there can be only one
		if (activeFilters != null) {
			// If only subtechnic is selected, the active filter does not set 'hoofdtechniek'
			// So get the hoofdtechniek here and show the selected filter
			let mainTechnic = activeFilters.hoofdtechniek == null ? null : activeFilters.hoofdtechniek[0];
			if ( (activeFilters.subtechniek != null) && (activeFilters.hoofdtechniek == null) ){
				// Get the main technic
				mainTechnic = supportedSubTechnics[activeFilters.subtechniek[0]].technicId;
				selectedSubTechnics.push(activeFilters.subtechniek[0]);
				 
			}
			if (selectedMainTechnics.length > 0) {
				// Get all subtechnics belonging to technic
//				let supportedItems = {};
				let supportedItemsArray = [];
				for (const [key, value] of Object.entries(supportedSubTechnics)) {
					if (value.technicId == mainTechnic) {
//						supportedItems[key]= value;
						supportedItemsArray.push(value);
					}
				}

				// Sort items by name
				// 1. First create sorted array


				console.log('Filterbox - i18n.language' + i18n.language + '-----');

				let sortedSupportedItems = supportedItemsArray.sort((a, b) => {
					//note that the sorted array contains an array per element [1] is id; [1] is the object
					// if (a[i18n.language] == null ) { a[i18n.language] = ""}
					// if (b[i18n.language] == null ) { b[i18n.language] = ""}
					// return my_a.toLowerCase().localeCompare(my_b.toLowerCase(),
					// 											i18n.language != null?i18n.language:'en', 
					// 											{numeric: true});
					return a[i18n.language].toLowerCase().localeCompare(b[i18n.language].toLowerCase(),
																i18n.language != null?i18n.language:'en', 
																{numeric: true});
				});
				// 2. Copy sorted array to dict
				let subTechnicItems = {}
				for (let index in supportedItemsArray) {
					subTechnicItems[supportedItemsArray[index].id] = supportedItemsArray[index];
				}

				subTechnicSelector.push((
				<DropDownItemSelector
					key={`subTechnicSelector`}
					items={selectedSubTechnics}
					supportedItems={subTechnicItems}
					classes={classes}
					onItemUpdated={this.onSubtechnicUpdated}
					itemLabelText={t(TranslationKey.drills_filters_select_subtechnic_label)}
					itemFieldText={t(TranslationKey.drills_filters_select_subtechnic_field)}
					itemsEmptyText={t(TranslationKey.drills_filters_select_subtechnic_empty)}
					onItemSelected={this.onSubTechnicSelected}
				/>
				))
			}

		}

		let levelCheckboxes = (
			<Grid item>
			<Typography
				id="items-label"
				variant="subtitle1"
				style={{ paddingBottom: 8, fontWeight: 600 }}
			>
				{t(TranslationKey.modal_filters_select_levels_label)}
			</Typography>
				{levelFilterCheckboxes}
			</Grid>
		)

		 
		
		// Create array to sort
		let supportedTechnicsArray = [];
		for (const [key, value] of Object.entries(supportedTechnics)) {
			supportedTechnicsArray.push(value);	
		}
		
		let sortedSupportedTechnicsArray = supportedTechnicsArray.sort((a, b) => {
			//note that the sorted array contains an array per element [1] is id; [1] is the object
			return a[i18n.language].toLowerCase().localeCompare(b[i18n.language].toLowerCase(),
														i18n.language != null?i18n.language:'en', 
														{numeric: true});
		});
		// 2. Copy sorted array to dict
		let technicItems = {}
		for (let index in sortedSupportedTechnicsArray) {
			technicItems[sortedSupportedTechnicsArray[index].id] = sortedSupportedTechnicsArray[index];
		}
 
		return (
			<Grid
				container
				spacing={2}
				direction="row"
				className="content"
				alignItems="stretch"
			>
				<Grid item xs={12} sm={6} md={4} >
				<DropDownItemSelector
						items={levels}
						classes={classes}
						supportedItems={supportedLevels}
						onItemRemoved={this.onLevelRemoved}
						onItemSelected={this.onLevelSelected}
						itemLabelText={t(TranslationKey.drills_filters_select_level_label)}
						itemFieldText={t(TranslationKey.drills_filters_select_level_field)}
						itemsEmptyText={t(TranslationKey.drills_filters_select_level_empty)}
					/>
				</Grid>
				<Grid item xs={6} sm={6} md={4}>
					<DropDownGroupOnlySelector
						items={technics}
						classes={classes}
						supportedGroups={technicItems}
						onItemRemoved={this.onTechnicRemoved}
						onItemSelected={this.onTechnicSelected}
						itemLabelText={t(TranslationKey.drills_filters_select_technic_label)}
						itemFieldText={t(TranslationKey.drills_filters_select_technic_field)}
						itemsEmptyText={t(TranslationKey.drills_filters_select_technic_empty)}
					/>
				</Grid>
				<Grid item xs={6} style={{flexBasis: 'auto'}}>
					{subTechnicSelector}
				</Grid>
			
			</Grid>
		);
	}
}

FilterBox.propTypes = {
	t: PropTypes.func.isRequired,
	applyOnClear: PropTypes.bool,
	activeFilters: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onApply: PropTypes.func.isRequired,
	filterPreference: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	alwaysUpdateFilterPreference: PropTypes.bool,
	supportedLevels: PropTypes.object.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	supportedSubTechnics: PropTypes.object.isRequired,
};

FilterBox.defaultProps = {
	applyOnClear: true,
	activeFilters: null,
	filterPreference: null,
	alwaysUpdateFilterPreference: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(FilterBox);
