// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import { isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

// --- Components
import { Transition } from 'App';

const styles = theme => ({
	chip: {
		textTransform: 'capitalize',
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	}
});


class RequestInformationModal extends React.Component {
	constructor(props) {
		super(props);

		this.onClose = this.onClose.bind(this);
		this.generateEmailUrl = this.generateEmailUrl.bind(this);

		this.state = {
			subject: '',
			body: '',
			isOpen: false,
		};
	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop/state values.
	static getDerivedStateFromProps(nextProps, previousState) {
		const {
			t,
			i18n,
			adCategory,
			houseAddress,
		} = nextProps;

		const {
			isOpen,
		} = previousState;

		const shouldBeOpen = adCategory != null;

		if (shouldBeOpen === isOpen)
			return null;

		const nextState = { isOpen: shouldBeOpen };

		if (shouldBeOpen === false)
			return nextState;

		nextState.subject = t(adCategory[`subject_${i18n.language}`], { houseAddress });
		nextState.body = t(adCategory[`body_${i18n.language}`], { houseAddress });

		return nextState;
	}

	// --- Working methods
	generateEmailUrl() {
		const {
			props: {
				t,
				adCategory,
			},
			state: {
				body,
				subject,
				isOpen,
			}
		} = this;

		if (!isOpen || adCategory == null)
			return '';

		const composition = ['mailto:', adCategory.email];

		if (!isStringNullOrEmpty(subject)) {
			composition.push('?subject=');
			composition.push(encodeURIComponent(subject));
		}

		if (!isStringNullOrEmpty(body)) {
			// If no subject parameter was added, the body will be
			// the only parameter and should thus start with a `?` character.
			composition.push(!isStringNullOrEmpty(subject) ? '&body=' : '?body=');
			composition.push(encodeURIComponent(body));
		}

		// Aggregates all the different strings composing the mail-to url into one.
		return ''.concat(...composition);
	}

	// --- Event methods
	onClose() {
		const {
			props: {
				onClose,
			},
		} = this;

		this.setState({
			body: '',
			subject: '',
		});

		onClose();
	}

	render() {
		const {
			props: {
				t,
				i18n,
				classes,
				adCategory,
			},
			state: {
				body,
				isOpen,
				subject,
			}
		} = this;

		let adImages = null;
		if (adCategory != null && adCategory.ad_images != null && adCategory.ad_images.length > 0) {
			adImages = (
				<Grid
					item
					container
					spacing={1}
					direction="row"
					justifyContent="center"
				>
					{adCategory.ad_images.map((image, i) => (
						<Grid
							key={`${adCategory.id}-partner-${i}`}
							item
						>
							<img src={image} style={{ width: 64, height: 64 }}/>
						</Grid>
					))}
				</Grid>
			);
		}

		return (
			<Dialog
				fullScreen
				open={isOpen}
				TransitionComponent={Transition}
				aria-labelledby="request-information-modal-title"
				aria-describedby="request-information-modal-content"
			>
				<div className="modal">
					<DialogTitle
						disableTypography
						className="modalBar"
						id="request-information-modal-title"
					>
						<IconButton
							size="small"
							onClick={this.onClose}
							aria-label="close button"
						>
							<Icon>chevron_left</Icon>
						</IconButton>
						<Typography
							variant="h6"
							className="modalTitle"
						>
							{adCategory != null ? adCategory[i18n.language] : ''}
						</Typography>
					</DialogTitle>
					<Container
						noValidate
						maxWidth="md"
						disableGutters
						component="form"
						onSubmit={this.onSubmit}
						id="apply-filter-modal-content"
						className="modalBody gridPadding"
					>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							direction="column"
							className="content"
							alignItems="stretch"
						>
							{adImages}
							<Grid item>
								<TextField
									fullWidth
									multiline
									value={subject}
									variant="outlined"
									id="information-request-subject"
									label={t(TranslationKey.modal_request_information_subject_field_label)}
									onChange={event => this.setState({ subject: event.target.value })}
								/>
							</Grid>
							<Grid item>
								<TextField
									fullWidth
									multiline
									value={body}
									variant="outlined"
									id="information-request-body"
									label={t(TranslationKey.modal_request_information_body_field_label)}
									onChange={event => this.setState({ body: event.target.value })}
								/>
							</Grid>
							<Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									className="button"
									onClick={this.onClose}
									style={{ marginRight: 8 }}
								>
									{t(TranslationKey.cancel)}
								</Button>
								<Button
									size="large"
									target="_blank"
									color="primary"
									disableElevation
									variant="contained"
									onClick={this.onClose}
									rel="noopener noreferrer"
									href={this.generateEmailUrl()}
								>
									{t(TranslationKey.modal_request_information_submit_button)}
								</Button>
							</Grid>
						</Grid>
					</Container>
				</div>
			</Dialog>
		);
	}
}

RequestInformationModal.propTypes = {
	t: PropTypes.func.isRequired,
	adCategory: PropTypes.object,
	houseAddress: PropTypes.string,
	i18n: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

RequestInformationModal.defaultProps = {
	adCategory: null,
	houseAddress: '-',
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(RequestInformationModal);
