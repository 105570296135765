// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';

// TODO in API, only return folders for user
const getBookmarkFolders = contextConfiguration => axios.get(
	'/bookmark_folders?fields=*',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getBookmarkFolders;
