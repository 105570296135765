// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import { isStringNullOrEmpty } from 'logic/stringOperations';
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Icon from '@mdi/react';
import MuiIcon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';

// --- Components
import Picture from 'visual/components/_/media/Picture';
import ItemHeader from 'visual/components/_/item/ItemHeader';
import DetailChip from 'visual/components/_/chips/DetailChip';
import MediaContainer from 'visual/components/_/media/MediaContainer';
import MainTechniqueIcon from 'visual/components/icons/MainTechniqueIcon';
import BookmarkButton from 'visual/components/icons/BookmarkButton';
import LevelIcon from 'visual/components/icons/LevelIcons';

// import BookmarkMenu from 'visual/components/Menus/BookmarkMenu'

// --- Assets
import {
	mdiClockOutline,
	mdiAccountOutline,
	mdiNetworkStrength1
} from '@mdi/js';

// --- Style
import './DrillCardStyle.sass';
import Drills from '../routes/Drills';
import { AndroidRounded, CallReceived } from '@material-ui/icons';

const styles = theme => ({
	headerIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	bookmarkButton: {
		padding: 6,
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: 6,
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	MenuItemTitle: {
    	fontSize: '1rem',
	},
	mediaBoxSmall: {
		width: '50%',
	},
	mediaBox: {
		width: '100%',
	},
	flexBox: {
		display: 'flex',
		flexDirection: 'row-reverse',
		flexShrink: '0',
	},
	detailsClass: {
		display: 'flex',
		flexDirection: 'row',
		gap: '10px',
		padding: '4px',
		width: '100%',
		flexGrow: '1',
		margin: '2px',
		justifyContent: 'flex-start',
	}
});

class BookmarkDrillCard extends React.Component {
	constructor(props) {
		super(props);	  
		this.state = {anchorEl: null};
	}

	// Show menu when bookmark icon is clicked
	handleBookmarkClick = (event) => {
		console.log('handleBookmarkClick');
		this.setState( {anchorEl: event.currentTarget} );
	}

	handleBookmarkNew = () => {
		console.log('bookmark New clicked');
	}

	// Add bookmark to folder
	handleBookmarkAdd = (folderID, drillID) => {
		console.log(`handleBookmarkAdd: Add ${drillID} to ${folderID}`);
		this.props.addBookmarkToFolder(folderID, drillID);

		this.handleBookmarkClose();
	}

	handleBookmarkClose = () => {
		console.log('handleBookmarkClose');
		this.setState( {anchorEl: null} );
	}

	handleBookmarkRemoval = (folderID, drillID) => {
		this.props.removeBookmarkFromFolder(folderID, drillID);
		console.log(`handleBookmarkRemoval: Remove ${drillID} from ${folderID}`);
		this.handleBookmarkClose();
	}

	render() {
		const {
			props: {
				t,
				to,
				i18n,
				drill,
				classes,
				bookmarked,
				small = false,
				showEditBookmarkMenu = false,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				supportedTechnics,
				bookmarkDict,
				addUserBookmarkFolder,
			},
		} = this;
		const open = Boolean(this.state.anchorEl);

		if (drill == null || drill === {})
			return null;

		// let title = '-';
		// if (!isStringNullOrEmpty(drill.label))
		// 	title = drill.label.charAt(0).toUpperCase() + drill.label.slice(1);


		let title = '';
		if( i18n.language == 'nl'){
			title = drill.label.charAt(0).toUpperCase() + drill.label.slice(1);
		}
		else{
			title = drill[i18n.language].charAt(0).toUpperCase() + drill[i18n.language].slice(1);
		}
		if( title == '' ){
			title = '-';
		}



		let mainTechnique = '-';
		if (!isStringNullOrEmpty(drill.mainTechniqueId) && supportedTechnics[drill.mainTechniqueId] != null)
			mainTechnique = supportedTechnics[drill.mainTechniqueId][i18n.language] || supportedTechnics[drill.mainTechniqueId].nl;

		const categoryIcon = <MainTechniqueIcon mainTechnique={drill.mainTechnique} />;

		let detailsClass = "details";
		if (small) { detailsClass = classes.detailsClass};
		
		const detailChips = (
			<div className={detailsClass}>
				<DetailChip
					icons={[<Icon key={`${drill.id}-detail-icon-0`} path={mdiAccountOutline} size={1} />]}
					labels={[drill.minPlayers]}
				/>
				<DetailChip
					// fullWidth={true}
					icons={[<LevelIcon levelCode={drill.levelCode} classes={classes.theme} />]}
					labels={[''/*drill.aantal_slaapkamers || '-'*/]}
				/>
			</div>
		);

		let bookmarkMenu;
		let menuItems = [];
		let icon = (<MuiIcon>grade</MuiIcon>);
		// Only when we need to show copy or remove options (typically on bookmark overview page)
		if (showEditBookmarkMenu) {
			// Change Icon to menu icon
			icon = (<MuiIcon>menu</MuiIcon>);

			// Show menu with copy and removing of bookmarks
			// Show remove bookmark options
			menuItems.push( (
				<Box key={'bookmark_remove_from_folder'}
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>
					{t(TranslationKey.bookmark_remove_from_folder)}
				</Box>
			));

			// Show remove bookmark options
			var self = this;
			// Find every bookmark folder containing this drill
			// Loop over folders
			for (const [key, value] of Object.entries(bookmarkDict)) {
				if (key != 'allBookmarks') {

					// loop over drills
					for(var jj in bookmarkDict[key].bookmarks) {
						if(bookmarkDict[key].bookmarks[jj] == drill.id) {
							// Found, so create unique key
							let uniqueKey = `${bookmarkDict[key].id}-${drill.id}`;
							menuItems.push( (<MenuItem key={uniqueKey}
								onClick={() => self.handleBookmarkRemoval(bookmarkDict[key].id, drill.id)}>
									{bookmarkDict[key].label}
								</MenuItem>
								)
							);
						}
					}
				}
			}	
			menuItems.push((<Divider key={'divider-1'}/>));
			// Show copy bookmark options
			menuItems.push(
				(<Box key='bookmark_copy_to_folder'
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>

					{t(TranslationKey.bookmark_copy_to_folder)}

				</Box>
				)
			);
			// Add bookmark
			var self = this;
			for (const [key, value] of Object.entries(bookmarkDict)) {
				// Skip allBookmarks item
				if (key != 'allBookmarks') {
					let uniqueKey = `${drill.id}-${key}`;

					// Disable menu item when it is already part of the folder
					const itemDisabled = (bookmarkDict[key].bookmarks[drill.id] == null) ? false : true;
					menuItems.push( (<MenuItem key={uniqueKey} disabled={itemDisabled}
						onClick={() => self.handleBookmarkAdd(key, drill.id)}
						divider={ (Object.keys(bookmarkDict)[0] == key) ? true : false } 
						>
							{bookmarkDict[key].label}
						</MenuItem>
						)	
					);
				}
			}

			menuItems.push( (<MenuItem key={'create_new'}
				onClick={() => this.handleBookmarkNew()}>
					Nieuw...
				</MenuItem>
				)
			);

			bookmarkMenu = (
				<Menu key={drill.id}
				id="bookmark-menu"
				anchorEl={this.state.anchorEl}
				open={open}
				onClose={this.handleBookmarkClose}
				MenuListProps={{
				'aria-labelledby': 'bookmark-button',
				}}
			>

					{menuItems}
				</Menu>
			)
		}
		else{
			// Show default add bookmark to folder menu
			menuItems.push(	(
				<Box key={'bookmark_add_to_folder'}
				sx={{
					bgcolor: 'background.paper',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '6px',
					paddingBottom: '6px',
					fontSize: '1rem',
					fontWeight: '600',
				}}>

					{t(TranslationKey.bookmark_add_to_folder)}

				</Box>
			) );
			var self = this;
			for (const [key, value] of Object.entries(bookmarkDict)) {
				if (key != 'allBookmarks') {
					let uniqueKey = `${drill.id}-${key}`;
					menuItems.push( (<MenuItem key={uniqueKey}
						onClick={() => self.handleBookmarkAdd(key, drill.id)}
//						divider={ (Object.keys(bookmarkDict)[0] == key) ? true : false } 
						>
							{bookmarkDict[key].label}
						</MenuItem>
						)	
					);
				}
			}

			menuItems.push((<Divider key='divider'/>));
			menuItems.push( (<MenuItem key={'create_new'}
				onClick={() => this.handleBookmarkNew()}>
					Nieuw...
				</MenuItem>
				)
			);
			bookmarkMenu = (
				<Menu key={drill.id}
				id="bookmark-menu"
				anchorEl={this.state.anchorEl}
				open={open}
				onClose={this.handleBookmarkClose}
				MenuListProps={{
				'aria-labelledby': 'bookmark-button',
				}}
			>

					{menuItems}
				</Menu>
			)
		}

		let mediaBoxStyle = {};
		let titleStyle = {};

//		if (small) 
		let linkContainer = 'linkInvisible';
		if (small) { 
			linkContainer = `linkInvisible ${classes.flexBox}`; 
			mediaBoxStyle = {
				minWidth: '150px',
				maxWidth: '150px',
//				border: '3px solid red',
				alignSelf: 'center',
//				paddingRight: '5px',
				overflow: 'hidden',
			};
			titleStyle = {
				width: '100%',
				overflow: 'hidden',
				padding: '4px',
				flexWrap: 'wrap',
				flexShrink: '2',
				marginRight: '30px',
//				border: '3px solid green',
			};
		}

		return (
			<Container>
			<Card style={{position:'relative'}}>
				<Link to={to} className={linkContainer}>

					<Box sx={titleStyle}>
						<ItemHeader
							icon={categoryIcon}
							leftTitle={title}
							leftSubtitle={mainTechnique}
							// rightTitle={price}
						/>
					{detailChips}
					</Box>
				</Link>
				<BookmarkButton
					t={t}
					drill={drill} 
//					classes={classes} 
					bookmarked={bookmarked} 
					showEditBookmarkMenu={showEditBookmarkMenu} 
					bookmarkDict={bookmarkDict}
					addBookmarkToFolder={addBookmarkToFolder}
					removeBookmarkFromFolder={removeBookmarkFromFolder}
					addUserBookmarkFolder={addUserBookmarkFolder}
				/>



			</Card>
			</Container>
		);
	}
}

BookmarkDrillCard.propTypes = {
	t: PropTypes.func.isRequired,
	to: PropTypes.string.isRequired,
	i18n: PropTypes.object.isRequired,
	drill: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	small: PropTypes.bool,
	addBookmarkToFolder: PropTypes.func.isRequired,
	showEditBookmarkMenu: PropTypes.bool,
	removeBookmarkFromFolder: PropTypes.func.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	bookmarkDict: PropTypes.object.isRequired,
	addUserBookmarkFolder: PropTypes.func.isRequired,
};

export default withStyles(styles)(BookmarkDrillCard);
