// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- IO
import API from 'io/API';

// --- Logic
import { diffDays, LLinxxDate_to_Date } from 'logic/dateOperations';
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// --- Style
const styles = theme => ({});
  
const formRules = Object.freeze({
	username: [
		value => !!value || { i18nKey: TranslationKey.validation_username_empty },
		// Check for white spaces
		// HL 4 2022: OLD: value => value.indexOf(' ')== -1 || { i18nKey: TranslationKey.validation_username_contains_spaces },
		// value => isValidEmailAddress(value) || { i18nKey: TranslationKey.validation_email_invalid },
	],
	password: [
		value => !!value || { i18nKey: TranslationKey.validation_password_empty },
		// HL 4 2022: OLD: value => value.indexOf(' ') == -1 || { i18nKey: TranslationKey.validation_password_contains_spaces },
	],
});

const defaultState = Object.freeze({
	username: '',
	password: '',
	errors: {
		username: [],
		password: [],
	},
	requestError: null,
	isFormDisabled: false,
});

class SignIn extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...defaultState };

		this.onSubmit = this.onSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.switchToRegister = this.switchToRegister.bind(this);
	}

	async validateForm() {
		let hasErrors = false;

		await this.setState((currentState) => {
			const errors = Object.keys(formRules).reduce((result, field) => {
				result[field] = formRules[field]
					.map(rule => rule(currentState[field]))
					.filter(ruleResult => ruleResult !== true);

				if (result[field].length > 0)
					hasErrors = true;

				return result;
			}, {});

			console.log('Form errors', errors);

			return { errors };
		});

		return hasErrors;
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		await this.setState(currentState => ({
			requestError: null,
			isFormDisabled: true,
			username: currentState.username.trim().toLowerCase(), // HL 4 2022: trim spaces from username and password 
			password: currentState.password.trim(),  
		}));

		const {
			props: {
				onSignedIn,
			},
			state: {
				username,
				password,
			}
		} = this;
		console.log(`username: ${username}`);
		console.log(`password: ${password}`);
		const hasErrors = await this.validateForm();
		console.log(`hasErrors: ${hasErrors}`);
		// Invalid fields.
		if (hasErrors) {
			await this.setState({ isFormDisabled: false });
			return;
		}
		console.log(`-${username}-${password}-`);

		const response = await API.getUserFromCredentials(username, password);
		console.log('Sign-in response', response);

//		if (response.status == 200) {
		if (response.status != 200 || response.data == null || response.data.length <= 0) {
			// Wrong credentials.
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_sign_in_bad_credentials }
			});
			return;
		}

/*		}
		else {
			// Wrong credentials.
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_sign_in_bad_credentials }
			});
			return;

		}
*/

		const credentials = { username, password };
		const rawUserData = response.data[0];

		console.log('rawUserData.enddate', rawUserData.enddate);


		const proper_enddate = LLinxxDate_to_Date(rawUserData.enddate);
		console.log('rawUserData.enddate', proper_enddate);

		const today = new Date();
    	today.setHours(0, 0, 0, 0);

		const daysUntilExpired = diffDays ( today, proper_enddate)
		console.log('daysUntilExpired:', daysUntilExpired);

		// let JSenddate = new Date();

		if( daysUntilExpired < -14 ) {
			// user account expired
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_user_account_expired }
			});
			return;
		}

		await this.setState({
			isFormDisabled: true,
			requestError: { i18nKey: TranslationKey.info_login_success }
		});


		onSignedIn(credentials, rawUserData);

		this.setState({ isFormDisabled: false });
	}

	switchToRegister() {
		const { history } = this.props;

		history.push('/register');
	}

	render() {
		const {
			props: {
				t,
				classes,
			},
			state: {
				errors,
				requestError,
				isFormDisabled,
			}
		} = this;

		return (
			<Container
				disableGutters
				className="page"
			>
				<Typography
					variant="h5"
					component="h2"
					className="pageTitle"
					style={{ textAlign: 'center', marginBottom: '16px' }}
				>
					{t(TranslationKey.sign_in)}
				</Typography>
				<Container
					noValidate
					maxWidth="xs"
					disableGutters
					component="form"
					onSubmit={this.onSubmit}
					className="pageContainer"
				>
					<Grid
						container
						spacing={2}
						direction="column"
						alignItems="stretch"
						className="content"
						justifyContent="center"
					>
						<Grid item>
							<TextField
								fullWidth
								variant="outlined"
								disabled={isFormDisabled}
								value={this.state.username}
								label={t(TranslationKey.email)}
								error={errors.username.length > 0}
								helperText={errors.username.length > 0 ? t(errors.username[0].i18nKey, errors.username[0].values) : null}
								onChange={({ target: { value } }) => this.setState((previousState) => {
									if (errors.username.length > 0)
										return { username: value, errors: { ...previousState.errors, username: [] } };
									return { username: value };
								})}
							/>
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								type="password"
								variant="outlined"
								id="current-password"
								disabled={isFormDisabled}
								value={this.state.password}
								autoComplete="current-password"
								error={errors.password.length > 0}
								label={t(TranslationKey.password)}
								helperText={errors.password.length > 0 ? t(errors.password[0].i18nKey, errors.password[0].values) : null}
								onChange={({ target: { value } }) => this.setState((previousState) => {
									if (errors.password.length > 0)
										return { password: value, errors: { ...previousState.errors, password: [] } };
									return { password: value };
								})}
							/>
						</Grid>
						{requestError != null ? (
							<Grid item>
								<Typography variant="caption" color="error">
									{t(requestError.i18nKey, requestError.values)}
								</Typography>
							</Grid>
						) : null}
						<Grid item>
							<Button
								fullWidth
								size="large"
								type="submit"
								color="primary"
								disableElevation
								variant="contained"
								disabled={isFormDisabled}
							>
								{t(TranslationKey.sign_in)}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Container>
		);
	}
}

SignIn.propTypes = {
	t: PropTypes.func,
	history: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	onSignedIn: PropTypes.func.isRequired,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(SignIn);
