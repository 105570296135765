import ThemeKey from './ThemeKey';


const TranslationKey = Object.freeze({
	// --- Generic
	no: 'no',
	yes: 'yes',
	and: 'and',
	back: 'back',
	cancel: 'cancel',
	confirm: 'confirm',
	username: 'username',
	fullname: 'fullname',
	password: 'password',
	contact: 'contact',
	volleypedia: 'volleypedia',
	privacy_policy: 'privacy_policy',
	phone: 'phone',
	email: 'email',
	register: 'register',
	sign_in: 'sign_in',
	sign_out: 'sign_out',
	minimum: 'minimum',
	maximum: 'maximum',
	between: 'between',
	are_you_sure: 'are_you_sure',
	show_more: 'show_more',
	show_less: 'show_less',
	loading: 'loading',
	unknown: 'unknown',
	save: 'save',

	// --- Component specific
	navbar_explorer: 'navbar_explorer',
	navbar_favorites: 'navbar_favorites',
	navbar_learningcurve: 'navbar_learningcurve',
	navbar_learningcurves: 'navbar_learningcurves',
	navbar_learningcurv: 'navbar_learningcurv',
	navbar_preferences: 'navbar_preferences',
	navbar_information: 'navbar_information',
	page_homepage_title: 'page_homepage_title',
	page_explorer_title: 'page_explorer_title',
	page_favorites_title: 'page_favorites_title',
	page_favorites_caveat_not_signed_in: 'page_favorites_caveat_not_signed_in',
	page_preferences_title: 'page_preferences_title',
	page_preferences_caveat_not_signed_in: 'page_preferences_caveat_not_signed_in',
	page_information_title: 'page_information_title',
	page_learningcurve_title: 'page_learningcurve_title',
	end_of_list_default: 'end_of_list_default',
	end_of_list_filtered: 'end_of_list_filtered',
	end_of_list_empty: 'end_of_list_empty',
	drills_end_of_list: 'drills_end_of_list',
	drills_end_of_list_filtered: 'drills_end_of_list_filtered',
	drills_end_of_list_empty: 'drills_end_of_list_empty',	
	// drills_start_of_list: '{{itemsCount}} Oefeningen gevonden',
	// drills_start_of_list_filtered: '{{itemsCount}} Oefeningen gevonden',
	// drills_start_of_list_empty: 'drills_end_of_list_empty',
	drills_start_of_list: 'drills_start_of_list',
	drills_start_of_list_filtered: 'drills_start_of_list_filtered',
	drills_start_of_list_empty: 'drills_start_of_list_empty',
	bookmarks_end_of_list: 'bookmarks_end_of_list',
	bookmarks_end_of_list_filtered: 'bookmarks_end_of_list_filtered',
	bookmarks_end_of_list_empty: 'bookmarks_end_of_list_empty',
	description_caveat_empty: 'description_caveat_empty',
	no_picture_available: 'no_picture_available',
	drill_not_found: 'drill_not_found',
	drill_plot_area: 'drill_plot_area',
	drill_surface_area: 'drill_surface_area',
	drill_bedrooms: 'drill_bedrooms',
	drill_floors: 'drill_floors',
	drill_publication_date: 'drill_publication_date',
	drill_type_of_property: 'drill_type_of_property',
	advertising_header: 'advertising_header',
	button_sort_list: 'button_sort_list',
	button_sort_list_active: 'button_sort_list_active',
	sort_none: 'sort_none',
	sort_newest: 'sort_newest',
	sort_price_high_low: 'sort_price_high_low',
	sort_price_low_high: 'sort_price_low_high',
	sort_main_technique: 'sort_main_technique',
	sort_titel: 'sort_titel',
	button_filter_list: 'button_filter_list',
	button_filter_list_active: 'button_filter_list_active',
	modal_filters_title: 'modal_filters_title',
	modal_filters_select_levels_label: 'modal_filters_select_levels_label',
	modal_filters_select_levels_field: 'modal_filters_select_levels_field',
	modal_filters_select_levels_empty: 'modal_filters_select_levels_empty',
	modal_filters_select_technics_label: 'modal_filters_select_technics_label',
	modal_filters_select_technics_field: 'modal_filters_select_technics_field',
	modal_filters_select_technics_empty: 'modal_filters_select_technics_empty',
	modal_filters_select_all: 'modal_filters_select_all',
	modal_filters_update_preference_checkbox: 'modal_filters_update_preference_checkbox',
	modal_filters_restore_preference_button: 'modal_filters_restore_preference_button',
	modal_filters_clear_button: 'modal_filters_clear_button',
	modal_filters_apply_button: 'modal_filters_apply_button',
	modal_filters_apply_and_save_button: 'modal_filters_apply_and_save_button',
	modal_request_information_submit_button: 'modal_request_information_submit_button',
	modal_request_information_subject_field_label: 'modal_request_information_subject_field_label',
	modal_request_information_body_field_label: 'modal_request_information_body_field_label',
	modal_confirm_sign_out: 'modal_confirm_sign_out',
	modal_window_close: 'modal_window_close',
	modal_bookmark_create_folder_input_field: 'modal_bookmark_create_folder_input_field',
	modal_bookmark_create_folder_text: 'modal_bookmark_create_folder_text',
	modal_bookmark_create_folder_title: 'modal_bookmark_create_folder_title',
	bookmark_menu_create_folder_field: 'bookmark_menu_create_folder_field',
	menu_item_any: 'menu_item_any',
	connected_as_username: 'connected_as_username',
	select_language: 'select_language',
	select_theme: 'select_theme',
	select_email_notifications: 'select_email_notifications',
	select_push_notifications: 'select_push_notifications',
	edit_filter_preference_button: 'edit_filter_preference_button',
	[ThemeKey.Light]: ThemeKey.Light, // theme_light
	[ThemeKey.Dark]: ThemeKey.Dark, // theme_dark
	update_profile: 'update_profile',
	change_password: 'change_password',
	current_password: 'current_password',
	new_password: 'new_password',
	delete_account: 'delete_account',
	help_text_enter_phone_number: 'help_text_enter_phone_number',
	warning_irreversible_action: 'warning_irreversible_action',
	warning_delete_account: 'warning_delete_account',
	validation_email_empty: 'validation_email_empty',
	validation_email_invalid: 'validation_email_invalid',
	validation_password_empty: 'validation_password_empty',
	validation_fullname_empty: 'validation_fullname_empty',
	validation_username_empty: 'validation_username_empty',
	validation_phone_invalid: 'validation_phone_invalid',
	validation_string_too_short: 'validation_string_too_short',
	// HL 4 2022: OLD: validation_username_contains_spaces: 'validation_username_contains_spaces',
	// HL 4 2022: OLD: validation_password_contains_spaces: 'validation_password_contains_spaces',
	error_wrong_password: 'error_wrong_password',
	error_sign_in_bad_credentials: 'error_sign_in_bad_credentials',
	error_user_account_expired: 'error_user_account_expired',
	info_login_success: 'info_login_success',
	error_register_unknown_reason: 'error_register_unknown_reason',
	error_register_user_already_exists: 'error_register_user_already_exists',
	error_update_profile_email_already_used: 'error_update_profile_email_already_used',
	error_missing_data_in_response: 'error_missing_data_in_response',
	error_request_failed_with_status_code: 'error_request_failed_with_status_code', // values: "status"
	error_request_failed_with_error_in_response: 'error_request_failed_with_error_in_response', // values: "error"
	title_error_server_not_reachable: 'title_error_server_not_reachable',
	error_server_not_reachable: 'error_server_not_reachable',
	development_help_text_enter_password: 'development_help_text_enter_password',
	home_user_set_preferences: 'home_user_set_preferences',
	// bookmarks
	bookmark_add_to_folder: 'bookmark_add_to_folder',
	bookmark_remove_from_folder: 'bookmark_remove_from_folder',
	bookmark_copy_to_folder: 'bookmark_copy_to_folder',
	bookmark_folder_delete: 'bookmark_folder_delete',
	bookmark_folder_rename: 'bookmark_folder_rename',
	bookmark_folder_delete_confirmation_title: 'bookmark_folder_delete_confirmation_title',
	bookmark_folder_delete_confirmation_text: 'bookmark_folder_delete_confirmation_text',

	// New filters
	drills_filters_select_technic_label: 'drills_filters_select_technic_label',
	drills_filters_select_technic_field: 'drills_filters_select_technic_field',
	drills_filters_select_technic_empty: 'drills_filters_select_technic_empty',
	drills_filters_select_subtechnic_label: 'drills_filters_select_subtechnic_label',
	drills_filters_select_subtechnic_field: 'drills_filters_select_subtechnic_field',
	drills_filters_select_subtechnic_empty: 'drills_filters_select_subtechnic_empty',	
	drills_filters_select_level_label: 'drills_filters_select_level_label',
	drills_filters_select_level_field: 'drills_filters_select_level_field',
	drills_filters_select_level_empty: 'drills_filters_select_level_empty',	
	drills_filters_search_label: 'drills_filters_search_label',
	drills_filters_search_status_label: 'drills_filters_search_status_label',

	volleypedia_media_images: 'volleypedia_media_images',

	// --- Proper nouns (people, place, brand, etc.)
	whatsapp: 'whatsapp',
	facebook: 'facebook',
});

export default TranslationKey;
