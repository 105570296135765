// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const postBookmarkFolder = (userID, bookmarkFolder, contextConfiguration) => axios.post(
	'/bookmark_folders',
	{
		owner: userID,
		...bookmarkFolder
	},
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default postBookmarkFolder;
