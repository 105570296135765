// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import { NavLink } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// --- Style
const useStyles = makeStyles(theme => ({
	container: {
		background: theme.darkMode ? '#161616' : '#ffffff',
		position: 'fixed',
		bottom: 0,
		right: 0,
		left: 0,
		height: 56 + 16,
		// height: 56 + 16,
		// paddingBottom: 16,
		filter: 'drop-shadow(0 0 10px #00000040)',
	},
	item: {
		display: 'flex',
		height: 'inherit',
		alignItems: 'center',
		justifyContent: 'center',
		textTransform: 'capitalize',
		paddingBottom: '8px',
	},
	// gradient: {
	// 	display: 'none',
	// 	position: 'fixed',
	// 	left: 0,
	// 	right: 0,
	// 	bottom: 56,
	// 	height: 16,
	// 	background: 'linear-gradient(to top, #00000090, #00000000 100%) no-repeat center center', // 14
	// },
}));


const NavBar = ({
	routes,
	...props
}) => {
	const [value, setValue] = React.useState(0);

	const { t } = useTranslation();

	const classes = useStyles(props);

	return (
		<>
			<BottomNavigation
//				showLabels
				value={value}
				className={classes.container}
				onChange={(event, newValue) => setValue(newValue)}
			>
				{/* <div className={classes.gradient}/> */}
				{routes.sort((a, b) => a.order - b.order).map(route => (
					<BottomNavigationAction
						key={route.key}
						to={route.href}
						icon={route.icon}
						value={route.path}
						component={NavLink}
						className={classes.item}
						activeClassName="Mui-selected"
//						label={t(TranslationKey[route.key])}
					/>
				))}
			</BottomNavigation>
		</>
	);
};

NavBar.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object),
};

export default NavBar;
