/* eslint-disable no-restricted-syntax */

export function setIsSuperset(set, subSet) {
	let result = true;
	for (const element of subSet) {
		if (!set.has(element)) {
			result = false;
			break;
		}
	}

	return result;
}

export function setIncludesAny(set, subSet) {
	let result = false;
	for (const element of subSet) {
		if (set.has(element)) {
			result = true;
			break;
		}
	}

	return result;
}

export function setUnion(setA, setB) {
	const result = new Set(setA);

	for (const element of setB)
		result.add(element);

	return result;
}

export function setIntersection(setA, setB) {
	const result = new Set();

	for (const element of setB) {
		if (setA.has(element))
			result.add(element);
	}

	return result;
}

export function setSymmetricDifference(setA, setB) {
	const result = new Set(setA);

	for (const element of setB) {
		if (result.has(element))
			result.delete(element);
		else
			result.add(element);
	}

	return result;
}

export function setDifference(setA, setB) {
	const result = new Set(setA);

	for (const element of setB)
		result.delete(element);

	return result;
}
