// --- External tools
import axios from 'axios';

// --- Logic
// import { vimeoConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';

const baseURL = 'https://api.vimeo.com/videos';

export const vimeoConfiguration = Object.freeze({
	baseURL,
});


const token = '2f250f21397157e5685e82499ecc27a3';

const config = {
	headers: {
		Authorization: 'Bearer 2f250f21397157e5685e82499ecc27a3',
		Accept: 'application/vnd.vimeo.user+json;version=3.0,application/vnd.vimeo.video+json;version=3.4',
		'User-Agent': '1000drills/0.0.1',
		'Content-Type': 'application/offset+octet-stream',
	}
};

const getVideoDetails = (id, contextConfiguration) => {
	axios.get(
		`/${id}`,
		{
			...vimeoConfiguration,
			...contextConfiguration,
			...config,
		}
	).catch(onRequestError);
};

/*
const getVideoDetails = (id, contextConfiguration) => {axios.get(
	`https://api.vimeo.com/videos/${id}`,
	{
		headers: {
			Authorization: `Bearer 2f250f21397157e5685e82499ecc27a3`,
			Accept: "application/vnd.vimeo.user+json;version=3.0,application/vnd.vimeo.video+json;version=3.4",
			"User-Agent": "1000drills/0.0.1",
			"Content-Type": "application/offset+octet-stream", }
	}
).catch(onRequestError);}
*/

export default getVideoDetails;
