// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getSupportedLevels = contextConfiguration => axios.get(
	'/niveaus?fields=*',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getSupportedLevels;
