import TranslationKey from './enums/TranslationKey';
import { isStringNullOrEmpty } from './stringOperations';

// Returns an object to be passed down to an i18n instance as the error
// message to be shown to the user if an error is found in the provided
// HTTP request response, otherwise returns null if no error is found.
export const getErrorMessageFromResponse = (response, expectedStatusCode = 200) => {
	if (!(response)) {
		console.log('response is undefined indicating network error')
		return {
			i18nKey: TranslationKey.error_server_not_reachable,
		};
	}	
	if (response.status !== expectedStatusCode) {
		return {
			i18nKey: TranslationKey.error_request_failed_with_status_code,
			values: { status: response.status }
		};
	}
	if (response.data == null) {
		// if (response.status !== 200) {
		// 	return {
		// 		i18nKey: TranslationKey.error_request_failed_with_status_code,
		// 		values: { status: response.status }
		// 	};
		// }

		return { i18nKey: TranslationKey.error_missing_data_in_response };
	}
	if (!isStringNullOrEmpty(response.data.error)) {
		return {
			i18nKey: TranslationKey.error_request_failed_with_error_in_response,
			values: { error: response.data.error }
		};
	}




	// No error.
	return null;
};
