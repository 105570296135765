// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getDrill = (id, contextConfiguration) => axios.get(
	`/drills/${id}`,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getDrill;
