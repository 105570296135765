// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';


// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	chip: {
		
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	},
	treeBox: {
		borderWidth: '1px',
		borderRadius: '3px',
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		maxHeight: '300px',
		overflow: 'auto',
	}
});

class DropDownTreeGroupSelector extends React.Component {
	constructor(props) {
		super(props);
	}

	onCategoryNodeSelect (e, nodeIds) {
		// Check if entire group is to be selected
		// Check if group or item
		let items = this.props.items;
		// 25-10-2021 Eric: Only one item at a time can be selected so clear list first
		items.length = 0;

		nodeIds = JSON.parse(nodeIds);

		
		// See if already selected
		for (let index in items) {
			if ( (items[index].groupId == nodeIds.groupId) 
				&& (items[index].itemId == nodeIds.itemId) )
			 {
					 
					return;
			}

		}

		if (nodeIds.itemId != null) {
			this.props.onItemSelected(nodeIds);
		}

		if (nodeIds.all != null) {
			// Add group Id
			this.props.onItemSelected(nodeIds);
		}

//		for (const index in nodeIds) {
//			 
//			if (nodeIds[index].match(/_(.*)/g)!=null) {
//				let selectedItemID=this.props.supportedGroups[nodeIds[index].match(/I[0-9]*/g)[0]];
//				 
//				this.props.onItemSelected(selectedItem);
//			}
//			else {
//				if (nodeIds[index].match(/I[0-9]*/g)!=null) {
//					 
//					this.props.onItemSelected(this.props.supportedGroups[nodeIds[index]]);
//				}
//			}
//		}

	}

	onCategoryNodeToggle (e, nodeIds) {
		 
	}

	render() {
		const {
			t,
			i18n,
			items,
			classes,
			onItemRemoved,
			itemLabelText,
			itemFieldText,
			itemsEmptyText,
			supportedItems,
			onItemSelected,
			getGroupItemIds,
			supportedGroups,
			renderGroupIcon,
		} = this.props;
		 

		if (supportedItems == null)
			return null;

		// Already selected items
		let selection = null;
		if (items.length > 0) {	 
			// Show selected groups/items as chips
			selection = items.map(({ groupId, itemId }, index) => {	 
				const group = supportedGroups[groupId];
				// If main group (i.e. itemId == null & groupId != null)
				if (itemId == null) {
					return (
						<Chip
							key={groupId}
							color="primary"
							variant="outlined"
							className={classes.chip}
							onDelete={() => onItemRemoved(index)}
							label={group[i18n.language] || group.nl}
						/>
					);
				}

				// Not returned yet, so item inside group
				const item = supportedItems[itemId];

				return (
					<Chip
						key={`${groupId}-${itemId}`}
						color="primary"
						variant="outlined"
						className={classes.chip}
						onDelete={() => onItemRemoved(index)}
						label={`${group[i18n.language] || group.nl} - ${item[i18n.language] || item.nl}`}
					/>
				);
			});
		} else {
			// Nothing selected
			selection = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{itemsEmptyText}
				</Typography>
			);
		}
		 
		 
		let selectableItems = [];

		// Sort technics by label
		let sortedGroups = Object.entries(supportedGroups);
		// sortedGroups = sortedGroups.sort((a, b) => (a[1].label > b[1].label) ? 1 : -1)
		sortedGroups = sortedGroups.sort( (a, b) => (a[1].i18n_label.toLowerCase() > 
												     b[1].i18n_label.toLowerCase()) ? 1 : -1 )
		// Loop over supported techniques
		for (const index in sortedGroups) {
			let groupId = sortedGroups[index][1].id;
				{/** Loop over groups */}
				const group = supportedGroups[groupId];
				 
				let groupIcon = null;
				if (renderGroupIcon != null) {
					groupIcon = (
						<span style={{ marginRight: 8 }}>
							{renderGroupIcon(group)}
						</span>
					);
				}

				// Get items from group
				const groupItemIds = getGroupItemIds(group);
				// todo sort groupItems by name and store by id
				// create list with name/id
				let groupItems = [];
				for (let index in groupItemIds) {
					let item = {itemId: groupItemIds[index], 
								title: supportedItems[groupItemIds[index]][i18n.language]
							}
					groupItems.push(item)
				}
				// Sort items by name
				let sortedItems = groupItems.sort((a, b) => {
					//note that the sorted array contains an array per element [1] is id; [1] is the object
					if (a.title == null ) { a.title = ""}
					if (b.title == null ) { b.title = ""}
					return a.title.toLowerCase().localeCompare(b.title.toLowerCase(),
																i18n.language != null?i18n.language:'en', 
																{numeric: true});
				});
				if (sortedItems != null && sortedItems.length > 0) {
					const groupItems = sortedItems
//						.filter(itemId => !items.some(item => item.groupId === groupId && item.itemId === itemId))
						.map(({itemId, title}, index) => (
							/** Items in group */	 
							<TreeItem
								nodeId={JSON.stringify({ groupId, itemId }) }
								key={itemId}
								value={{ groupId, itemId }}
								label={ title }
								style={{  marginLeft: '16px', fontSize: 14 }}
							/>
						));



//					 
//					selectableItems = [...selectableItems, ...groupItems];

					let tmp = (
						/** Group */
							<TreeItem
							nodeId={JSON.stringify({ groupId }) }
							key={groupId}
								value={{ groupId }}
								label={group[i18n.language] || group.nl}
//								labelIcon={{groupIcon}}
								style={{  }}
							>
								{/** Show a select all option */}
								<TreeItem
								nodeId={JSON.stringify({ groupId, all: 'all' }) }
								key={groupId}
									label={t(TranslationKey.modal_filters_select_all)}
									style={{  marginLeft: '16px', fontSize: 14 }}
								/>

								{groupItems}
								</TreeItem>
							);
					selectableItems = [...selectableItems, tmp];
				}
				else {
					let tmp = (
					<TreeItem
					nodeId={JSON.stringify({ groupId }) }
					key={groupId}
						value={{ groupId }}
						label={group[i18n.language] || group.nl}
//								labelIcon={{groupIcon}}
						style={{  }}
					>
						<TreeItem
						nodeId={JSON.stringify({ groupId, all: 'all' }) }
						key={groupId}
							label={t(TranslationKey.modal_filters_select_all)}
							style={{  marginLeft: '16px', fontSize: 14 }}
						/>
					</TreeItem>
					);
					selectableItems = [...selectableItems, tmp];

				}

			}
			let selectedNodes = [];
		return (
			<Grid item>
				<Typography
					id="items-label"
					variant="subtitle1"
					style={{ paddingBottom: 8, fontWeight: 600 }}
				>
					{itemLabelText}
				</Typography>
				<div style={{ paddingBottom: 12 }}>
					{selection}
				</div>

	<Box className={classes.treeBox}>
	<TreeView
      aria-label="multi-select"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
	  selected={selectedNodes}
	  multiSelect={true}
	  onNodeSelect={(e, id) => this.onCategoryNodeSelect(e, id)}
	  onNodeToggle={this.onCategoryNodeToggle}
      sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
    >
		{selectableItems}

	  </TreeView>
	</Box>
			</Grid>
		);
	}
}

DropDownTreeGroupSelector.propTypes = {
	t: PropTypes.func.isRequired,
	renderGroupIcon: PropTypes.func,
	i18n: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	onItemRemoved: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	getGroupItemIds: PropTypes.func.isRequired,
	itemLabelText: PropTypes.string.isRequired,
	itemFieldText: PropTypes.string.isRequired,
	itemsEmptyText: PropTypes.string.isRequired,
	supportedItems: PropTypes.object.isRequired,
	supportedGroups: PropTypes.object.isRequired,
};

DropDownTreeGroupSelector.defaultProps = {
	renderGroupIcon: null,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(DropDownTreeGroupSelector);
