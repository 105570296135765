// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { ListSubheader } from '@material-ui/core';

const styles = theme => ({
	chip: {
		
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	}
});

class DropDownSelector extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			t,
			i18n,
			items,
			classes,
			onItemRemoved,
			itemLabelText,
			itemFieldText,
			itemsEmptyText,
			supportedItems,
			onItemSelected,
		} = this.props;

		if (supportedItems == null)
			return null;

		let selection = null;
		if (items.length > 0) {
			selection = items.map((id, index) => (
				<Chip
					key={id}
					color="primary"
					variant="outlined"
					className={classes.chip}
					label={supportedItems[id][i18n.language]}
					onDelete={() => onItemRemoved(index)}
				/>
			));
		} else {
			selection = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{itemsEmptyText}
				</Typography>
			);
		}

		const selectableItems = Object.keys(supportedItems)
			.filter(id => items.indexOf(id) <= -1)
			.map(id => (
				<MenuItem
					key={id}
					value={id}
					style={{  }}
				>
					{supportedItems[id][i18n.language] || supportedItems[id].nl}
				</MenuItem>
			));

		return (
			<Grid item style={{ marginBottom: '16px' }}>
				<Typography
					id="items-label"
					variant="subtitle1"
					style={{ paddingBottom: 8, fontWeight: 600 }}
				>
					{itemLabelText}
				</Typography>
				<div style={{ paddingBottom: 12 }}>
					{selection}
				</div>
				<FormControl
					fullWidth
					size="small"
					variant="outlined"
				>
					<Select
						value={''}
						displayEmpty
						id="select-filter-items"
						onChange={({ target: { value: selectedItem } }) => onItemSelected(selectedItem)}
						style={{  color: '#808080' }}
					>
						<MenuItem
							value=""
							disabled
							style={{  }}
						>
							{itemFieldText}
						</MenuItem>
						{selectableItems}
					</Select>
				</FormControl>
			</Grid>
		);
	}
}

DropDownSelector.propTypes = {
	t: PropTypes.func.isRequired,
	i18n: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	onItemRemoved: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	itemLabelText: PropTypes.string.isRequired,
	itemFieldText: PropTypes.string.isRequired,
	supportedItems: PropTypes.object.isRequired,
	itemsEmptyText: PropTypes.string.isRequired,
};

DropDownSelector.defaultProps = {};

export default compose(
	withTranslation(),
	withStyles(styles),
)(DropDownSelector);
