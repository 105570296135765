import ThemeKey from 'logic/enums/ThemeKey';
import StorageKey from './enums/StorageKey';
import { isStringNullOrEmpty } from './stringOperations';
import AuthenticationStatus from './enums/AuthenticationStatus';
import * as JsonExtension from './jsonOperations';


export function formatUserData(data) {
	const {
		url,
		taal,
		nightmode,
//		bookmarks,
		bookmark_home,
		pushmeldingen,
		emailmeldingen,
	} = data;
	// console.log('****** data ******');
	// console.log(data);
	let splitUrl = url.split('/');
	const id = splitUrl[splitUrl.length - 1];

	let language = 'en';
	if (taal == null || taal.length <= 0 || taal[0].label == null)
		console.warn('Unable to get the preferred language out of the user data.');
	else
		language = taal[0].label;

	let theme = ThemeKey.Light;
	if (nightmode == null || nightmode.length <= 0 || nightmode[0].label == null)
		console.warn('Unable to get the preferred theme out of the user data.');
	else {
		const nightmodeLabel = nightmode[0].label;

		if (nightmodeLabel === 'ja' || nightmodeLabel === 'yes')
			theme = ThemeKey.Dark;
	}

	let bookmarkhomeID = 0;
	// console.log('bookmark_home: ');
	// console.log(bookmark_home);
	const bookmarkFolderDict = bookmark_home.reduce((result, currentItem) => {
		// console.log('currentItem');
		// console.log(currentItem);
		splitUrl = currentItem.url.split('/');
		bookmarkhomeID = splitUrl[splitUrl.length - 1];
		// console.log('bookmark home ID: ', bookmarkhomeID);
		// Uses an object instead of an array to avoid duplicates.
//		result[bookmarkhomeID] = bookmarkhomeID;
		let bookmarkHomeDetails = {id: bookmarkhomeID, label: currentItem.label};
		result[bookmarkhomeID] = bookmarkHomeDetails;
		// console.log('bookmarkHomeDetails: ');
		// console.log(result);
		// console.log('bookmark label: ', currentItem.label);
		return result;
	}, {});	
	// const bookmarkIDs = bookmarks.reduce((result, currentItem) => {
	// 	splitUrl = currentItem.url.split('/');
	// 	const bookmarkID = splitUrl[splitUrl.length - 1];
	//
	// 	result.push(bookmarkID);
	// 	return result;
	// }, []);
	// console.log('bookmarkFolderDict');
	// console.log(bookmarkFolderDict);
/*
	const bookmarksDict = bookmarks.reduce((result, currentItem) => {
		splitUrl = currentItem.url.split('/');
		const bookmarkID = splitUrl[splitUrl.length - 1];
		console.log('currentItem');
		console.log(currentItem);
		// Uses an object instead of an array to avoid duplicates.
		const bookmark = {id: bookmarkID, label: currentItem.label}
		result[bookmarkID] = bookmark;
//		result[bookmarkID].label = currentItem.label;
		return result;
	}, {});

	bookmarkFolderDict[bookmarkhomeID].bookmarks = bookmarksDict;
	console.log('******** bookmarkFolderDict **********');
	console.log(bookmarkFolderDict);
*/
	let pushNotifications = null;
	if (pushmeldingen == null || pushmeldingen.length <= 0 || pushmeldingen[0].url == null)
		console.warn('Unable to get the preferred push notifications out of the user data.');
	else {
		splitUrl = pushmeldingen[0].url.split('/');
		pushNotifications = splitUrl[splitUrl.length - 1];
	}

	let emailNotifications = null;
	if (emailmeldingen == null || emailmeldingen.length <= 0 || emailmeldingen[0].url == null)
		console.warn('Unable to get the preferred email notifications out of the user data.');
	else {
		splitUrl = emailmeldingen[0].url.split('/');
		emailNotifications = splitUrl[splitUrl.length - 1];
	}

	// TODO It's a security hazard to have the password in the response of GET users request,
	//  this does not guaranty that the password won't still be around in the memory.
	delete data.password;

	return {
		...data,
		id,
		theme,
		language,
		pushNotifications,
		emailNotifications,
		bookmarkFolderDict,
	};
}

export function getStoredSessionCredentials() {
	// console.log('getStoredSessionCredentials');
	const storedCredentials = localStorage.getItem(StorageKey.UserCredentials);

	if (isStringNullOrEmpty(storedCredentials))
		return null;

	const json = JsonExtension.parseOrNull(storedCredentials);
	
	if (json == null || json.username == null || json.password == null) {
		console.warn('The stored credentials are invalid, clearing cache.');

		localStorage.removeItem(StorageKey.UserCredentials);
		return null;
	}

	return json;
}
