// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// --- API
// import getVideoDetails from 'io/API/requests/getVideoDetails'
import API from 'io/API';

// --- Logic
import Color from 'logic/enums/Color';
import ContactIcon from 'logic/enums/ContactIcon';
import TranslationKey from 'logic/enums/TranslationKey';
import { getAdvertisementIcon } from 'logic/enums/AdIcons';
// import HouseStatusColor from 'logic/enums/HouseStatusColor';
import { getIdFromApiUrl, isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ImageGallery from 'react-image-gallery';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DetailChip from 'visual/components/_/chips/DetailChip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from 'App';

// --- Components
import ImageView from 'visual/components/_/media/ImageView';
import ItemStatus from 'visual/components/_/item/ItemStatus';
import ItemHeadLine from 'visual/components/_/item/ItemHeadLine';
import RequestInformationModal from 'visual/components/modals/RequestInformationModal';
import ItemHeader from 'visual/components/_/item/ItemHeader';
import MainTechniqueIcon from 'visual/components/icons/MainTechniqueIcon';
import LearningCurveBlock from 'visual/components/_/list/LearningCurveBlock';
import LevelIcon from 'visual/components/icons/LevelIcons';

// --- Assets
import PlotIcon from 'assets/icons/plot.svg';
import BedroomIcon from 'assets/icons/bedroom.svg';
import SurfaceIcon from 'assets/icons/surface.svg';
import LocationIcon from 'assets/icons/location.svg';
import BookmarkButton from 'visual/components/icons/BookmarkButton';
import BookmarkButton2 from 'visual/components/icons/BookmarkButton2';

// --- Style
import './DrillInspectorStyle.sass';

//import Drills from '../routes/Drills';  // HL 4 2022  toegevoegd, maar is het ook nodig?

// --- Icons
import { Icon as MdiIcon } from '@mdi/react';
import {
	mdiStrategy, mdiClockOutline, mdiShieldOutline, mdiAccountOutline, mdiSteering, mdiNetworkStrength1
} from '@mdi/js';

const styles = theme => ({
	bookmarkButton: {
		padding: '6px',
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: '6px',
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	locationIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	priceIcon: {
		padding: 0,
		margin: 0,
		fontSize: 30,
		color: theme.palette.text.primary,
	},
	infoBackgroundTabTitle: {
		padding: '5px 15px',
		backgroundColor: theme.palette.background.backgroundPaper,
		borderRadius: '5px 5px 0px 0px',
		display: 'inline-block',
	},
	paper: {
		margin: '5px 0px 0px 0px',
		width: '100%',
		maxHeight: 'calc(100% - 45px)',
	},
	scrollPaper: {
		width: '100%',
		height: '100%',
		alignItems: 'flex-start',
	},
	dialogContent: {
		padding: '0px 0px 20px 0px',
	},
	zeroPaddingBottom: {
		paddingBottom: '0px !important',
	},
	zeroPaddingTop: {
		paddingTop: '0px !important',
	},
	zeroPadding: {
		padding: '0px 16px!important',
//		paddingBottom: '0px !important',
	},
});

const fallbackImage = '/images/image-placeholder.png';


class DrillInspector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			imageViewIndex: 0,
			showImageView: false,
			informationRequest: null,
			videoDetails: null,
			anchorEl: null,
		};

		this.onClose = this.onClose.bind(this);
		this.renderNoData = this.renderNoData.bind(this);
	}


	async componentDidMount() {
		const {
			props: {
				id,
				houseList,
			},
		} = this;


		const details = houseList.find(house => house.id === id);

		// const videoDetails = getVideoDetails(details.video_id);
		// fetchVideoDetails(details.video_id);
		/*
		await Promise.all([
			this.fetchVideoDetails(details.video_id),
		]);
		*/
	}

	async fetchVideoDetails(id) {
		// if (!this._ismounted)
		//	return;
		// Sets the loading video data to an empty object.
		this.setState(({ videoDetails }) => {
			videoDetails[id] = {};
			return { videoDetails };
		});

		const response = await API.getVideoDetails(id);


		if (!this._ismounted)
			return;

		this.setState(({ videoDetails }) => {
			videoDetails[id] = response.data;

			return { videoDetails };
		});
	}

	onClose() {
		const { onClose } = this.props;

		this.setState({
			imageViewIndex: 0,
			showImageView: false,
		}, onClose);
	}

	renderNoData(translationKey) {
		const { t, onClose, classes, id } = this.props;

		return (
			<Dialog
				BackdropProps={ {style: {opacity: '0.8'} }}
				fullWidth
				maxWidth='xl'
				classes={{scrollPaper: classes.scrollPaper, paper: classes.paper }}
				open={id != null}
				TransitionComponent={Transition}
				aria-labelledby="alert-dialog-slide-title-eric1"
				aria-describedby="alert-dialog-slide-description"
			>
				<div className="modal houseInspector">
					<DialogTitle disableTypography className="modalBar">
						{onClose ? (
							<Button
								onClick={onClose}
								aria-label="close"
								startIcon={<Icon>close</Icon>}
							>
								{t(TranslationKey.modal_window_close)}
							</Button>
						) : null}
						<Box flexGrow={1} component="span"/>
					</DialogTitle>
					<div className="modalBody">
						<div style={{ padding: '64px 32px', textAlign: 'center' }}>
							{t(translationKey)}
						</div>
					</div>
				</div>
			</Dialog>
		);
	}


	// Show menu when bookmark icon is clicked
	handleBookmarkClick = (event) => {
		console.log('handleBookmarkClick');
		this.setState( {anchorEl: event.currentTarget} );
	}

	handleBookmarkNew = () => {
		console.log('bookmark New clicked');
	}

	// Add bookmark to folder
	handleBookmarkAdd = (folderID, drillID) => {
		console.log(`handleBookmarkAdd: Add ${drillID} to ${folderID}`);
		this.props.addBookmarkToFolder(folderID, drillID);

		this.handleBookmarkClose();
	}

	handleBookmarkClose = () => {
		console.log('handleBookmarkClose');
		this.setState( {anchorEl: null} );
	}

	handleBookmarkRemoval = (folderID, drillID) => {
		this.props.removeBookmarkFromFolder(folderID, drillID);
		console.log(`handleBookmarkRemoval: Remove ${drillID} from ${folderID}`);
		this.handleBookmarkClose();
	}

	render() {
		const {
			props: {
				t,
				id,
				i18n,
				classes,
				bookmarkButton,
				onClose,
				houseList,
				bookmarked,
				contactData,
				// toggleBookmark,
				learningCurves,
				bookmarkDict,
				showEditBookmarkMenu = false,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				addUserBookmarkFolder,
				supportedTechnics,
				history,
				origin = '/drills',
				...other
			},
			state: {
				showImageView,
				imageViewIndex,
				informationRequest,
			}
		} = this;
		const open = Boolean(this.state.anchorEl);

		// Use other to capture only the props you're not using in List
//		const { classes, bookmarkButton, ...otherClasses } = this.props;

		if (houseList == null)
			return this.renderNoData(TranslationKey.loading);

		const details = houseList.find(house => house.id === id);

		if (details == null)
			return this.renderNoData(TranslationKey.drill_not_found);

		// Get drills from this learning curve
		let curveDrills=[];
		console.log('learningCurves');
		console.log(learningCurves);
		let currentCurve;
		if (learningCurves != null) {
			for (const [key, value] of Object.entries(learningCurves)) {
				// if (value.drills.find(drill => drill.label == details.label) != null) {
				if (value.drills.find(drill => drill.url == details.url) != null) {  // HL 4 2022  find drills based on url/id not on label 
					curveDrills = value.drills;
					currentCurve = value;
					console.log('found drill in learningcurve');
				}
			}
		}
		console.log('curveDrills:');
		console.log(curveDrills);

		// let title = '-';
		// if (!isStringNullOrEmpty(details.label))
		// 	title = details.label.charAt(0).toUpperCase() + details.label.slice(1);

		let title = '';
		if( i18n.language == 'nl'){
			title = details.label.charAt(0).toUpperCase() + details.label.slice(1);
		}
		else{
			title = details[i18n.language].charAt(0).toUpperCase() + details[i18n.language].slice(1);
		}
		if( title == '' ){
			title = '-';
		}


		console.log('details');
		console.log(details);
		// let mainTechnique = '-';
		// if (!isStringNullOrEmpty(details.mainTechnique))
		// 	mainTechnique = details.mainTechnique.charAt(0).toUpperCase() + details.mainTechnique.slice(1);

		let mainTechnique = '-';
		if (!isStringNullOrEmpty(details.mainTechniqueId) && supportedTechnics[details.mainTechniqueId] != null)
			mainTechnique = supportedTechnics[details.mainTechniqueId][i18n.language] || supportedTechnics[details.mainTechniqueId].nl;
		


		// let mainTechnique = '';
		// if( i18n.language == 'nl'){
		// 	mainTechnique = details[beschrijving].charAt(0).toUpperCase() + details[beschrijving].slice(1);
		// }
		// else{
		// 	mainTechnique = details['beschrijving_'+i18n.language].charAt(0).toUpperCase() + details['beschrijving_'+i18n.language].slice(1);
		// }
		// if( mainTechnique == '' ){
		// 	mainTechnique = '-';
		// }


		let pictures = [];

		if (details.foto != null) {
			if (details.foto instanceof Array) {
				pictures = details.foto.map(url => ({
					original: url,
					thumbnail: url,
				}));
			} else if (typeof details.foto === 'string') {
				pictures.push({
					original: details.foto,
					thumbnail: details.foto,
				});
			}
		}

		if (details.hoofdfoto != null) {
			pictures.unshift({
				original: details.hoofdfoto,
				thumbnail: details.hoofdfoto,
			});
		}

		let descriptionComponent = null;


		let Description = '';
		if( i18n.language == 'nl'){
			Description = details.beschrijving.charAt(0).toUpperCase() + details.beschrijving.slice(1);
		}
		else{
			Description = details['beschrijving_'+i18n.language].charAt(0).toUpperCase() + details['beschrijving_'+i18n.language].slice(1);
		}
		if( Description == '' ){
			Description = '-';
		}

		if (!isStringNullOrEmpty(Description)) {
			descriptionComponent = (
				<Typography
					variant="body2"
					style={{ textAlign: 'justify' }}
					dangerouslySetInnerHTML={{ __html: Description }}
				/>
			);
		} 
		else {
			descriptionComponent = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{t(TranslationKey.description_caveat_empty)}
				</Typography>
			);
		}

		// if (!isStringNullOrEmpty(details.beschrijving)) {
		// 	descriptionComponent = (
		// 		<Typography
		// 			variant="body2"
		// 			style={{ textAlign: 'justify' }}
		// 			dangerouslySetInnerHTML={{ __html: details.beschrijving }}
		// 		/>
		// 	);
		// } 
		// else {
		// 	descriptionComponent = (
		// 		<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
		// 			{t(TranslationKey.description_caveat_empty)}
		// 		</Typography>
		// 	);
		// }

		let publicationDate = '-';
		if (details.publicatiedatum != null) {
			const options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			};

			publicationDate = new Date(details.publicatiedatum).toLocaleDateString(i18n.language, options);
		}

		let plot = '-';
		if (!isStringNullOrEmpty(details.perceeloppervlakte))
			plot = <>{details.perceeloppervlakte} m<sup>2</sup></>;

		let surface = '-';
		if (!isStringNullOrEmpty(details.woonoppervlakte))
			surface = <>{details.woonoppervlakte} m<sup>2</sup></>;

		const detailChips = (
			<Grid
				item
				container
				spacing={0}
				direction="row"
				style={{ padding: 0, margin: '22px 0' }}
			>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="details">
						<SvgIcon
							key="plot-icon"
							component={PlotIcon}
							style={{ fontSize: 26, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_plot_area)}
						</Typography>
						<span
							className="value"
						>
							{plot}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<SvgIcon
							key="surface-icon"
							component={SurfaceIcon}
							style={{ fontSize: 26, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_surface_area)}
						</Typography>
						<span
							className="value"
						>
							{surface}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="type-of-property-icon"
							style={{ opacity: 0.8 }}
						>
							apartment
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_type_of_property)}
						</Typography>
						<span
							className="value"
							style={{ textTransform: 'capitalize' }}
						>
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<SvgIcon
							key="bedrooms-icon"
							component={BedroomIcon}
							style={{ fontSize: 24, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_bedrooms)}
						</Typography>
						<span
							className="value"
						>
							{details.aantal_slaapkamers || '-'}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="floors-icon"
							style={{ opacity: 0.8 }}
						>
							layers
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_floors)}
						</Typography>
						<span
							className="value"
						>
							{details.aantal_woonlagen || '-'}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="publication-date-icon"
							style={{ opacity: 0.8 }}
						>
							more_time
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.drill_publication_date)}
						</Typography>
						<span
							className="value"
						>
							{publicationDate}
						</span>
					</div>
				</Grid>
			</Grid>
		);

		let contactButtons = null;
		if (contactData != null) {
			contactButtons = (
				<Grid
					item
				>
					<Grid
						container
						spacing={1}
						direction="row"
					>
						{Object.keys(contactData).map(label => (
							<Grid
								item
								key={contactData[label].id}
							>
								<Button
									size="small"
									target="_blank"
									color="primary"
									disableElevation
									variant="contained"
									rel="noopener noreferrer"
									href={contactData[label].href}
									startIcon={ContactIcon[label]}
								>
									{t(TranslationKey[label])}
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			);
		}

		const video = (
			<div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
				<iframe
					src={`https://player.vimeo.com/video/${details.video_id}?badge=0&amp;autopause=0&amp;player_id=0`}
					style={{
						position: 'absolute',
						top: '0px',
						left: '0px',
						height: '100%',
						width: '100%'
					}}
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					title="170721_BHA_89B"
				/>
			</div>
		);
		// const video = (<div><img src="https://i.vimeocdn.com/video/577783433/pictures/1195194726" /></div>)

		const detailIcons = (
			<div className="details">
				<DetailChip
					icons={[<MdiIcon key="detail-chip-0-0" path={mdiAccountOutline} size={1} />]}
					labels={[details.minPlayers]}
				/>
				<DetailChip
				icons={[<LevelIcon levelCode={details.levelCode} classes={classes.theme} />]}
				labels={[''/*drill.aantal_slaapkamers || '-'*/]}
				/>
			</div>
		);

		let curveDrillData = [

		];

		// get details for drills
		curveDrills.forEach((curve, index) => {
			let id = getIdFromApiUrl(curve.url);
			const details = houseList.find(house => house.id === id);
			curveDrillData.push(details);
		}); 


// llllllllllllllll
// navbar_learningcurv
//							{t(TranslationKey.navbar_learningcurv)}

		// let imageList = null;

		let learningCurveBlock = null;

		let termLearningCurve = t(TranslationKey.navbar_learningcurv);
		let currentCurveLabel = "";
		if( i18n.language == 'nl'){
			currentCurveLabel = termLearningCurve + ' - ' +currentCurve.label;
		}
		else{
			currentCurveLabel = termLearningCurve + ' - ' +currentCurve[i18n.language];
		}
		if( currentCurveLabel == '' ){
			currentCurveLabel = '-';
		}




		// let title = '';
		// if( i18n.language == 'nl'){
		// 	title = drill.label.charAt(0).toUpperCase() + drill.label.slice(1);
		// }
		// else{
		// 	title = drill[i18n.language].charAt(0).toUpperCase() + drill[i18n.language].slice(1);
		// }
		// if( title == '' ){
		// 	title = '-';
		// }		



		if (currentCurve != null) {
// 			imageList = (
// 				<div>
// 					<LearningCurveBlock 
// 						title={currentCurve.label}
// 						itemData={curveDrillData} 
// 						origin={origin} 
// 						current={id}
// //						classes={other}
// 						{...other}
// 						history={history}
// 					/>
// 				</div>
// 			);
			learningCurveBlock = ( 
				<LearningCurveBlock 
				title={currentCurveLabel}
				// title={currentCurve.label}
				itemData={curveDrillData} 
				origin={origin} 
				current={id}
//						classes={other}
				{...other}
				history={history}
			/>
	);
		}

		// Bookmark menu
		let bookmarkMenu;
		let menuItems = [];
		let icon = (<Icon>favorite</Icon>);
		// Show default add bookmark to folder menu
		menuItems.push(	(
			<Box key={'bookmark_add_to_folder'}
			sx={{
				bgcolor: 'background.paper',
				paddingLeft: '16px',
				paddingRight: '16px',
				paddingTop: '6px',
				paddingBottom: '6px',
				fontSize: '1rem',
				fontWeight: '600',
			}}>

				{t(TranslationKey.bookmark_add_to_folder)}

			</Box>
		) );
		var self = this;
		for (const [key, value] of Object.entries(bookmarkDict)) {
			if (key != 'allBookmarks') {
				let uniqueKey = `${id}-${key}`;
				menuItems.push( (<MenuItem key={uniqueKey}
					onClick={() => self.handleBookmarkAdd(key, id)}
//						divider={ (Object.keys(bookmarkDict)[0] == key) ? true : false } 
					>
						{bookmarkDict[key].label}
					</MenuItem>
					)	
				);
			}
		}

		menuItems.push((<Divider key='divider'/>));
		menuItems.push( (<MenuItem key={'create_new'}
			onClick={() => this.handleBookmarkNew()}>
				Nieuw...
			</MenuItem>
			)
		);
		bookmarkMenu = (
			<Menu key={`menu_${id}`}
			id="bookmark-menu"
			anchorEl={this.state.anchorEl}
			open={open}
			onClose={this.handleBookmarkClose}
			MenuListProps={{
			'aria-labelledby': 'bookmark-button',
			}}
		>
				{menuItems}
			</Menu>
		)
		console.log("DrillInspector-bookmarkDict");
		console.log(bookmarkDict);
		return (
			<Dialog
				BackdropProps={ {style: {opacity: '0.8'} }}
				fullWidth
				maxWidth='xl'
				classes={{scrollPaper: classes.scrollPaper, paper: classes.paper }}
				open={id != null}
				TransitionComponent={Transition}
				aria-labelledby="alert-dialog-slide-title-eric2"
				aria-describedby="alert-dialog-slide-description"
			>
				<div className="modal houseInspector">
					{pictures != null && pictures.length > 0 && showImageView && (
						<ImageView
							image={pictures[imageViewIndex].original}
							onClose={() => this.setState({ showImageView: false })}
						/>
					)}
					<DialogTitle disableTypography className="modalBar">
					{onClose ? (
						<Button
							onClick={() => { onClose(); }}
							aria-label="close"
							startIcon={<Icon>close</Icon>}
						>
							{t(TranslationKey.modal_window_close)}
						</Button>
					) : null}
					<Box flexGrow={1} component="span"/>
					{/*<BookmarkButton2
						drill={details}
						bookmarkDict={bookmarkDict}
					/>*/}
					<BookmarkButton
						t={t}
						drill={details} 
	//					classes={classes} 
						bookmarked={bookmarked} 
						showEditBookmarkMenu={false} 
						bookmarkDict={bookmarkDict}
						addBookmarkToFolder={addBookmarkToFolder}
						removeBookmarkFromFolder={removeBookmarkFromFolder}
						addUserBookmarkFolder={addUserBookmarkFolder}
					/>


					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
					<Container
						disableGutters
						className="modalBody"
					>

						<div style={{ marginBottom: 0 }}>

							{/*
								pictures.length > 0 ? (

									<ImageGallery
										lazyLoad
										infinite={false}
										items={pictures}
										slideDuration={250}
										slideOnThumbnailOver
										showPlayButton={false}
										showFullscreenButton={false}
										useBrowserFullscreen={false}
										onClick={() => this.setState({ showImageView: true })}
										onBeforeSlide={nextIndex => this.setState({ imageViewIndex: nextIndex })}
									/>
								) : (
									<Box
										display="flex"
										alignItems="center"
										flexDirection="vertical"
										justifyContent="center"
										style={{ height: '40vh', background: '#00000018' }}
									>
										<Typography
											variant="body1"
											color="textSecondary"
											style={{ fontStyle: 'italic' }}
										>
											{t(TranslationKey.no_picture_available)}
										</Typography>
									</Box>
								)
								*/
							}
						</div>
						{/* statusComponent */}
						<div className="content">
							{video/* this.state.videoDetails.embed.html */}
							{/*
							<ItemHeader
								icon={<MainTechniqueIcon mainTechnique={details.mainTechnique} size={1}/> }
								leftTitle={title}
								leftSubtitle={mainTechnique}
								size={'1'}
							/>
							*/}
							<Grid
								container
								spacing={4}
								direction="column"
							>

								<Grid className={classes.zeroPaddingBottom} item>
									<ItemHeader
										icon={<MainTechniqueIcon mainTechnique={details.mainTechnique} size={1}/> }
										leftTitle={title}
										leftSubtitle={mainTechnique}
										size={'1'}
									/>
								</Grid>

								<Grid item className={classes.zeroPadding} >

									{detailIcons/* detailChips */}
								</Grid>
								<Grid item className={classes.zeroPaddingTop}>
									{descriptionComponent}
								</Grid>
								<Grid item  xs={12}>
									{learningCurveBlock}
								</Grid>


								{contactButtons}
							</Grid>
						</div>
					</Container>
					<div className="footer">
					</div>
					</DialogContent>
				</div>
			</Dialog>
		);
	}
}

DrillInspector.propTypes = {
	id: PropTypes.string,
	houseList: PropTypes.array,
	t: PropTypes.func.isRequired,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	// toggleBookmark: PropTypes.func.isRequired,
	learningCurves: PropTypes.object.isRequired,
	bookmarkDict: PropTypes.object.isRequired,
	addBookmarkToFolder: PropTypes.func.isRequired,
	showEditBookmarkMenu: PropTypes.bool,
	removeBookmarkFromFolder: PropTypes.func.isRequired,
	addUserBookmarkFolder: PropTypes.func.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
};


export default compose(
	withStyles(styles),
)(DrillInspector);
