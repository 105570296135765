// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- IO
import API from 'io/API';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

// --- Components
import { Transition } from 'App';
import { isStringNullOrEmpty } from '../../../logic/stringOperations';

// --- Style
const styles = theme => ({
	deleteAccountButton: {
		color: theme.palette.text.inDanger,
		background: theme.palette.background.danger,
		'&:hover': {
			background: theme.palette.background.danger,
		},
	},
});


const formRules = Object.freeze({
	password: [
		value => !!value || { i18nKey: TranslationKey.validation_password_empty },
	],
});

const defaultState = Object.freeze({
	password: '',
	errors: {
		password: [],
	},
	requestError: null,
	isFormDisabled: false,
});

class DeleteAccountModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...defaultState };

		this.onSubmit = this.onSubmit.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	async validateForm() {
		await this.setState({ isFormDisabled: true });

		let hasErrors = false;

		await this.setState((currentState) => {
			const errors = Object.keys(formRules).reduce((result, field) => {
				result[field] = formRules[field]
					.map(rule => rule(currentState[field]))
					.filter(ruleResult => ruleResult !== true);

				if (result[field].length > 0)
					hasErrors = true;

				return result;
			}, {});

			console.log('Form errors', errors);

			return {
				errors,
				isFormDisabled: false
			};
		});

		return hasErrors;
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		await this.setState({ requestError: null });

		const {
			props: {
				userData,
				onCompleted,
			},
			state: {
				password,
			}
		} = this;

		const hasErrors = await this.validateForm();

		// Invalid field(s).
		if (hasErrors)
			return;

		const getResponse = await API.getUserFromCredentials(userData.users, password, null);
		console.log('Get user response', getResponse);

		// No user found, wrong password.
		if (getResponse.data == null || getResponse.data.length <= 0) {
			await this.setState({ requestError: { i18nKey: TranslationKey.error_wrong_password } });
			return;
		}

		let id;
		if (!isStringNullOrEmpty(getResponse.data[0].url)) {
			const splitUrl = getResponse.data[0].url.split('/');
			id = splitUrl[splitUrl.length - 1];
		}

		// Different user found, wrong password.
		if (isStringNullOrEmpty(id) || id !== userData.id) {
			await this.setState({ requestError: { i18nKey: TranslationKey.error_wrong_password } });
			return;
		}

		const deleteResponse = await API.deleteUser(userData.id, password);
		console.log('Delete user response', deleteResponse);

		if (!isStringNullOrEmpty(deleteResponse.data.error)) {
			await this.setState({
				requestError: {
					i18nKey: TranslationKey.error_request_failed_with_error_in_response,
					values: { error: deleteResponse.data.error }
				}
			});

			return;
		}

		if (deleteResponse.status !== 200) {
			await this.setState({
				requestError: {
					i18nKey: TranslationKey.error_request_failed_with_status_code,
					values: { status: deleteResponse.status }
				}
			});

			return;
		}

		this.closeModal();

		onCompleted();
	}

	closeModal() {
		const { onClose } = this.props;

		this.setState({ ...defaultState });

		onClose();
	}

	render() {
		const {
			props: {
				t,
				isOpen,
				classes,
			},
			state: {
				errors,
				requestError,
				isFormDisabled,
			}
		} = this;

		return (
			<Dialog
				fullScreen
				open={isOpen}
				TransitionComponent={Transition}
				aria-labelledby="delete-account-title"
				aria-describedby="delete-account-body"
			>
				<div className="modal">
					<DialogTitle
						disableTypography
						className="modalBar"
						id="delete-account-title"
					>
						<Button
							onClick={this.closeModal}
							disabled={isFormDisabled}
							aria-label="close button"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
						<Typography
							variant="h6"
							className="modalTitle"
						>
							{t(TranslationKey.delete_account)}
						</Typography>
					</DialogTitle>
					<Container
						noValidate
						maxWidth="xs"
						disableGutters
						component="form"
						id="delete-account-body"
						onSubmit={this.onSubmit}
						className="modalBody gridPadding"
					>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							direction="column"
							alignItems="stretch"
							className="content"
						>
							<Grid
								item
								xs={12}
								sm="auto"
								style={{ marginTop: 'auto', marginBottom: 'auto' }}
							>
								<Alert severity="error">
									<AlertTitle>{t(TranslationKey.warning_irreversible_action)}</AlertTitle>
									<Trans i18nKey={TranslationKey.warning_delete_account}/>
								</Alert>
							</Grid>
							<Grid item>
								<TextField
									fullWidth
									type="password"
									variant="outlined"
									disabled={isFormDisabled}
									value={this.state.password}
									error={errors.password.length > 0}
									label={t(TranslationKey.password)}
									helperText={errors.password.length > 0 ? t(errors.password[0].i18nKey, errors.password[0].values) : null}
									onChange={({ target: { value } }) => this.setState((previousState) => {
										if (errors.password.length > 0)
											return { password: value, errors: { ...previousState.errors, password: [] } };
										return { password: value };
									})}
								/>
							</Grid>
							{requestError != null ? (
								<Grid item>
									<Typography variant="caption" color="error">
										{t(requestError.i18nKey, requestError.values)}
									</Typography>
								</Grid>
							) : null}
							<Grid item>
								<Button
									fullWidth
									size="large"
									type="submit"
									disableElevation
									variant="contained"
									disabled={isFormDisabled}
									className={classes.deleteAccountButton}
								>
									{t(TranslationKey.delete_account)}
								</Button>
							</Grid>
						</Grid>
					</Container>
					<div className="footer">
						<Button
							className="button"
							onClick={this.closeModal}
							disabled={isFormDisabled}
						>
							{t(TranslationKey.cancel)}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
}

DeleteAccountModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	onCompleted: PropTypes.func.isRequired,
};

DeleteAccountModal.defaultProps = {
	isOpen: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(DeleteAccountModal);
