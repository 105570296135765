// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';
/*
const headers = {
	auth: {
	  username: 'eric@vanrijswick.com',
	  password: '1234'
	}
};
*/

const getBooleanSelectors = contextConfiguration => axios.get(
	'/yes_no',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);


export default getBooleanSelectors;
