// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const patchUserPassword = (changes, contextConfiguration) => axios.patch(
	`/llinxx_admin/users/change_password`,
	changes,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default patchUserPassword;
