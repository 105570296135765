// dateOperations.js
// HL 4 2022


// Takes a LLinxx date string (yyyymmdd). Returns a Date object
export function LLinxxDate_to_Date(LLinxxDate) {
	const d = new Date(
	LLinxxDate.substring(0,4),
	LLinxxDate.substring(4,6)-1,
	LLinxxDate.substring(6,8),
	0,0,0,0);

	return d;
}


// Takes 2 Date objects, return the difference in days.
// positive if startDate is before endDate. Otherwise negative.  
export function diffDays(startDate, endDate) {
	const diffInMs   = endDate - startDate;
	const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

	return diffInDays;
}
