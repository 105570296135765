// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getSupportedBookmarkFolderTypes = contextConfiguration => axios.get(
	'/bookmark_types?fields=*',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getSupportedBookmarkFolderTypes;
