// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import SupportedSorting from 'logic/enums/SupportedSorting';
import { isStringNullOrEmpty } from 'logic/stringOperations';
import { countActiveFilters } from 'visual/components/modals/ApplyFiltersModal';

// --- External components
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// --- Components
import { Transition } from 'App';
import SortMenu from 'visual/components/Menus/SortMenu';
import DrillCard from 'visual/components/cards/DrillCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import DrillInspector from 'visual/components/modals/DrillInspector';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import LeerlijnDrills from 'visual/components/modals/LeerlijnDrills';

// --- Style
const styles = theme => ({

});


class Leerlijnen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedSorting: SupportedSorting.sort_newest.id,
			supportedSorting: SupportedSorting,
		};

		this.onSort = this.onSort.bind(this);
		this.onClearSort = this.onClearSort.bind(this);
	}

	getItemID (item) {
		let splitUrl = item.url.split('/');
		const itemID = splitUrl[splitUrl.length - 1];
	
		return itemID;
	}

	handleLearningCurveClick(curveKey) {
		console.log('learningCurveID :', curveKey );
		history.push(`/leerlijnen/${curveKey}`)
	}

	render() {
		const {
			props: {
				t,
				i18n,
				filters,
				classes,
				history,
				houseList,
				contactData,
				drillDetails,
				adCategories,
				clearFilters,
				toggleFilters,
				filtersEnabled,
				// toggleBookmark,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				openFilterModal,
				supportedTechnics,
				filteredHouseList,
				bookmarkDictionary,
				supportedSubTechnics,
				isLoadingFilteredList,
				fullBookmarkDict,
				learningCurves,
				route,
				match: { params: {leerlijnid} },
				match: { params: {drillid }},
			},
			state: {
				selectedSorting,
				supportedSorting,
			}
		} = this;

		let selectedLearningCurveDrills;
		const activeFilterCount = filters != null ? countActiveFilters(filters) : 0;

		const isFilteredList = activeFilterCount > 0 && filtersEnabled;

		let housesToDisplay = isFilteredList ? filteredHouseList : houseList;

		if (!isStringNullOrEmpty(selectedSorting) && supportedSorting[selectedSorting].sort != null) {
			housesToDisplay = housesToDisplay.slice().sort((a, b) => supportedSorting[selectedSorting].sort(a, b, drillDetails));
		}


		let self = this;
		let learningCurvesPerTechnique = {};

		// Sort technics by label
		let sortedTechnics = Object.entries(supportedTechnics);
		// sortedTechnics = sortedTechnics.sort((a, b) => (a[1].label > b[1].label) ? 1 : -1)
		sortedTechnics = sortedTechnics.sort( (a, b) => (a[1].i18n_label.toLowerCase() > 
														 b[1].i18n_label.toLowerCase()) ? 1 : -1 )
		// Loop over supported techniques
		for (const index in sortedTechnics) {
			let supportedTechnicsKey = sortedTechnics[index][1].id;

			// Define object to hold curves
			learningCurvesPerTechnique[supportedTechnicsKey] = {...supportedTechnics[supportedTechnicsKey], learningCurves: {}};

			// Find all curves for this technique by looping over curves
			for (const [key, value] of Object.entries(learningCurves)) {
				if (learningCurves[key].techniek != null) {
					// loop over technieken
					learningCurves[key].techniek.forEach(function(technique, index) {
						let techniqueID = self.getItemID(technique);
						// If the technique of the learning curve matches a supported technique, add the learning curve id
						if (techniqueID == supportedTechnics[supportedTechnicsKey].id) {
							learningCurvesPerTechnique[techniqueID].learningCurves[learningCurves[key].id] = learningCurves[key];
							let i18n_label = '';
							if( i18n.language == 'nl'){
								i18n_label = learningCurvesPerTechnique[techniqueID].label;
							}
							else{
								i18n_label = learningCurvesPerTechnique[techniqueID][i18n.language];
							}
							if( i18n_label == '' ){
								i18n_label = '-';
							}
							learningCurvesPerTechnique[techniqueID].i18n_label = i18n_label;
						}
					});
				}
			}
		}
		console.log('learningCurvesPerTechnique: ');
		console.log(learningCurvesPerTechnique);
		// Create sorted learningcurves list array
		for (const [key, value] of Object.entries(learningCurvesPerTechnique)) {
			// Get an array of curves
			let curves = Object.entries(learningCurvesPerTechnique[key].learningCurves);
			// Now sort the curves by label
			curves.sort((a, b) => {
				// if (a[1].i18n_label == null ) { a[1].i18n_label = ""}
				// if (b[1].i18n_label == null ) { b[1].i18n_label = ""}
				let a_label = a[1].i18n_label == null ? "" : a[1].i18n_label; 
				let b_label = b[1].i18n_label == null ? "" : b[1].i18n_label; 
				//note that the sorted array contains an array per element [1] is id; [1] is the object
				return a_label.toLowerCase().localeCompare(b_label.toLowerCase(),
															i18n.language != null?i18n.language:'en', 
															{numeric: true});
/*
				if ( a[1].label.toLowerCase() < b[1].label.toLowerCase() ){
					return -1;
				}
				if ( a[1].label.toLowerCase() > b[1].label.toLowerCase() ){
					return 1;
				}

				return 0;
				*/
			});
			learningCurvesPerTechnique[key].sortedCurves = curves;
		}	

		// todo: code seems to run a few times, why?

		let learningCurveItems = [];
		for (const [key, value] of Object.entries(learningCurvesPerTechnique)) {
			let curveItems = [];
			learningCurvesPerTechnique[key].sortedCurves.forEach(curve => {


				let i18n_label = '';
				if( i18n.language == 'nl'){
					i18n_label = curve[1].label;
				}
				else{
					i18n_label = curve[1][i18n.language];
				}
				if( i18n_label == '' ){
					i18n_label = '-';
				}


				curveItems.push((<TreeItem 
					key={`curve-${curve[0]}`} 
					nodeId={curve[0]} 
					// label={curve[1].label + 'hhhhhhhhh'} 
					label={i18n_label} 
					onClick={() => {
							history.push(`/leerlijnen/${curve[0]}`); 
						}
					} 
					/> 
				));
			})
			learningCurveItems.push( (<TreeItem key={`technique-${key}`} nodeId={key} 
						               label={learningCurvesPerTechnique[key].i18n_label} >{curveItems}</TreeItem>) );


		}
		/*
			for (const [curveKey, curveValue] of Object.entries(learningCurvesPerTechnique[key].learningCurves)) {
				curveItems.push((<TreeItem 
									key={`curve-${curveKey}`} 
									nodeId={curveKey} 
									label={learningCurvesPerTechnique[key].learningCurves[curveKey].label} 
									onClick={() => {
											history.push(`/leerlijnen/${curveKey}`); 
										}
									} 
									/> 
								));
			}
			learningCurveItems.push( (<TreeItem key={`technique-${key}`} nodeId={key} label={learningCurvesPerTechnique[key].label} >{curveItems}</TreeItem>) );
		}
		*/
			
		let tree = (
			<TreeView
			aria-label="file system navigator"
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
			>
			{learningCurveItems}
		</TreeView>

		);
			console.log(`id: ${leerlijnid}; drillid: ${drillid}`);
//		}

		return (
			<Container
				disableGutters
				className="page"
			>
				<LeerlijnDrills {...this.props} leerlijnid={leerlijnid} onClose={() => history.push('/leerlijnen')} />
					
				<Dialog
					fullScreen
					open={drillid != null}
					TransitionComponent={Transition}
					aria-labelledby="alert-dialog-slide-title-leerlijnen2"
					aria-describedby="alert-dialog-slide-description"
				>
					<DrillInspector
						t={t}
						id={drillid}
						i18n={i18n}
						houseList={houseList}
						contactData={contactData}
						adCategories={adCategories}
						// toggleBookmark={toggleBookmark}
						addBookmarkToFolder={addBookmarkToFolder}
						removeBookmarkFromFolder={removeBookmarkFromFolder}
						onClose={() => history.push(`/leerlijnen/${leerlijnid}`)}
						supportedSubTechnics={supportedSubTechnics}
						supportedTechnics={supportedTechnics}   // HL 4 2022  
//						bookmarked={bookmarkDictionary[drillid] != null}
						learningCurves={learningCurves}
						origin={`/leerlijnen/${leerlijnid}`}
						bookmarked={fullBookmarkDict.allBookmarks[drillid] != null}
					//	bookmarked={fullBookmarkDict.allBookmarks[housesToDisplay[index].id] != null}
						bookmarkDict={fullBookmarkDict}
					/>
				</Dialog>
				<Grid container spacing={2}>
					<Grid item>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}>
							{route.icon}								
							<Typography
									variant="h5"
									component="h1"
									style={{ textTransform: 'capitalize', paddingLeft: '10px' }}
								>
									{t(TranslationKey.page_learningcurve_title)}
							</Typography>
						</div>  
					</Grid>
					<Grid
						item
						container
						spacing={1}
						direction="row"
					>
					<TreeView
						aria-label="file system navigator"
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
						sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
						>
						{learningCurveItems}
					</TreeView>


					</Grid>
				</Grid>
			</Container>
		);
	}


	// --- Event methods
	onSort(leerlijnid) {
		this.setState({ selectedSorting: leerlijnid });
	}

	onClearSort() {
		this.setState({ selectedSorting: '' });
	}
}

Leerlijnen.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	filters: PropTypes.object,
	userData: PropTypes.object,
	clearFilters: PropTypes.func,
	toggleFilters: PropTypes.func,
	contactData: PropTypes.object,
	filtersEnabled: PropTypes.bool,
	adCategories: PropTypes.object,
	// toggleBookmark: PropTypes.func,
	addBookmarkToFolder: PropTypes.func,
	removeBookmarkFromFolder: PropTypes.func,
	openFilterModal: PropTypes.func,
	// activeFilterCount: PropTypes.number,
	bookmarkDictionary: PropTypes.object,
	fullBookmarkDict: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	isLoadingFilteredList: PropTypes.bool,
	drillDetails: PropTypes.object.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	supportedSubTechnics: PropTypes.object.isRequired,
	houseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	filteredHouseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	learningCurves: PropTypes.object.isRequired,
};

Leerlijnen.defaultProps = {
	// activeFilterCount: 0,
	filters: null,
	filtersEnabled: false,
	isLoadingFilteredList: false,
	// toggleBookmark: () => console.warn('toggleBookmark method not provided.'),
	addBookmarkToFolder: () => console.warn('addBookmarkToFolder method not provided.'),
	removeBookmarkFromFolder: () => console.warn('removeBookmarkFromFolder method not provided.'),
	clearFilters: () => console.warn('clearFilters method not provided.'),
	toggleFilters: () => console.warn('toggleFilters method not provided.'),
	openFilterModal: () => console.warn('openFilterModal method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Leerlijnen);
