// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// --- Logic
import ThemeKey from 'logic/enums/ThemeKey';

const fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

// HL 5 2022: Background is now depending on the environment. Both light and dark theme
// 	- production: original background colors
// 	- test: yellow / cornsilk
// 	- localhost: blue

// console.log('window location' + window.location.href);
let env_based_background_default;  
let env_based_background_paper;  
let env_based_background_dark_default;  
let env_based_background_dark_paper;  

if( window.location.href.includes('localhost') ){
	env_based_background_default = 'PowderBlue';
	env_based_background_paper = '#92d4dd';
	env_based_background_dark_default = '#369dab';
	env_based_background_dark_paper = '#27727c';
}
else if( window.location.href.includes('test-app') ){
	env_based_background_default = 'cornsilk';
	env_based_background_paper = '#FFFBEB';
	env_based_background_dark_default = '#c79f00';
	env_based_background_dark_paper = '#e6b800';  
}
else{
	env_based_background_default = '#EEEEEA';
	env_based_background_paper = '#FFFFFF';
	env_based_background_dark_default = '#0a0a08';
	env_based_background_dark_paper = '#161616';
}



const lightTheme = createTheme({
	darkMode: false,
	palette: {
		type: 'light',
		primary: {
			main: '#406089',
		},
		secondary: {
			main: '#6784a8',
		},
		error: {
			main: red.A700,
		},
		background: {
			default: env_based_background_default,		// default: '#EEEEEA',
			secondary: '#EEEDE7',
			paper: env_based_background_paper,			// paper: '#ffffff',
			backgroundPaper: '#e0e0e0',
			danger: '#c30000',
			aroundDanger: '#c3000024',
			bookmark: '#00000080',
			bookmarkActive: '#000000b4',
		},
		text: {
			primary: '#0f0f0f',
			secondary: '#76787A',
			danger: '#c30000',
			inDanger: '#ffffff',
			bookmark: '#ebebeb80',
			bookmarkActive: '#f2da00',
		},
	},
	typography: {
		fontFamily,
	},
	components: {
		MuiBottomNavigationActionIconOnly: {

			fontSize: '3rem',
		},

		MuiBottomNavigation: {
			styleOverrides: {
				// Name of the slot
				root: {
				// Some CSS
					fontSize: '3rem',
				},
			},
		},
	}
});

const darkTheme = createTheme({
	darkMode: true,
	palette: {
		type: 'dark',
		primary: {
			main: '#647ea1', // #4b70a0
		},
		secondary: {
			main: '#647ea1', // #6784a8
		},
		error: {
			main: red.A400,
		},
		background: {
			default: env_based_background_dark_default,		//default: '#0a0a08',
			secondary: '#00000000',
			paper: '#323232',
			paper: env_based_background_dark_paper,			// paper:  '#161616',
			danger: '#c30000',
			aroundDanger: '#c3000032',
			bookmark: '#00000080',
			bookmarkActive: '#000000b4',
		},
		text: {
			primary: '#f0f0f0',
			secondary: '#bebebe',
			danger: '#c30000',
			inDanger: '#f0f0f0',
			bookmark: '#ebebeb80',
			bookmarkActive: '#f2da00',
		},
	},
	typography: {
		fontFamily,
	},
	components: {
		MuiBottomNavigation: {
			styleOverrides: {
				// Name of the slot
				root: {
				// Some CSS
					fontSize: '3rem',
				},
			},
		},
	}
});

const AppTheme = ({ theme, children }) => (
	<ThemeProvider theme={theme === ThemeKey.Light ? lightTheme : darkTheme}>
		<CssBaseline/>
		{children}
	</ThemeProvider>
);

AppTheme.propTypes = {
	children: PropTypes.any,
	theme: PropTypes.string.isRequired,
};

// AppTheme.defaultProps = {
// 	theme: ThemeKey.Light,
// };

export default AppTheme;
