// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getVolleypediaArticles = contextConfiguration => axios.get(
	'/volleypedia?fields=*',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getVolleypediaArticles;
