// --- Logic
import API from '../index';
import { getIdFromApiUrl } from 'logic/stringOperations';
import { mdiConsoleLine } from '@mdi/js';

function formatSubfolder (subFolder) {
		let splitUrl = subFolder.url.split('/');
		const subFolderID = splitUrl[splitUrl.length - 1];
		// Uses an object instead of an array to avoid duplicates.
//		result[bookmarkhomeID] = bookmarkhomeID;
		let bookmarkSubDetails = {};
		bookmarkSubDetails.id = subFolderID;
		bookmarkSubDetails.label = subFolder.label;
		return bookmarkSubDetails;
};

function getBookmarkID (bookmark) {
    let splitUrl = bookmark.url.split('/');
    const bookmarkId = splitUrl[splitUrl.length - 1];

    return bookmarkId;
}

const API_logic = {
    getUserBookmarkDict: async function (userId, bookmarkFolderDict, supportedBookmarkFolderTypes) {
        // Get all main bookmark folders
        let allBookmarks = {};
        let tmpBookmarks = {};

        // Get data from home folder(s)
        const homeDictPromises = Object.keys(bookmarkFolderDict).map( async (homeFolder) => {
            let tmp = await API.getBookmarkHomeDetails(homeFolder);
            tmp.data.id = homeFolder;
            return tmp.data;
        });
        // Get all subfolders and its details
        const homeDicts = await Promise.all(homeDictPromises);
        console.log('*********** bookmark debugging ************');
        console.log(homeDicts);

        // Assume only one home folder. TODO: set type in database
        let homeFolderId = homeDicts[0].id;
        // For now just assume one home foler, so don't loop over home folders

        // Add bookmarks from root to allbookmarks array so they can be marked on the drills page
        for (var attr in homeDicts[0].bookmarks) { 
            let bookmarkID = getBookmarkID(homeDicts[0].bookmarks[attr]);
            allBookmarks[bookmarkID] = bookmarkID; 
        }
/*
        const subDictPromises = homeDicts[0].sub_folders.map( async (subFolder) => {
            // Get current subfolder id and label
            const subFolderDetails = formatSubfolder(subFolder);
            
            // Get subfolder details so we can find the bookmarks from subfolder
            let tmp = await API.getBookmarkHomeDetails(subFolderDetails.id);
            tmp.data.id = subFolderDetails.id;
            return tmp.data;
        });
*/        
        let tmp = await API.getBookmarkFolders();
        console.log('getBookmarkFolders');
        console.log(tmp);
        tmp.data.forEach((bookmarkFolder, index) => {
            let bookmarkID = getBookmarkID(bookmarkFolder);
            let bookmarks = {};
            bookmarkFolder.bookmarks.forEach((bookmark) => {
                let bookmarkID = getBookmarkID(bookmark);
                bookmarks[bookmarkID] = bookmarkID;
            });

            
            // Workaround TODO remove once fixed in backend: Filter userId
            if (bookmarkFolder.owner == null){ return };
            if (bookmarkFolder.owner.length == 0) {return};
            let ownerId = getIdFromApiUrl(bookmarkFolder.owner[0].url);
            if (userId == ownerId) {
                //TODO: get folder type id for this bookmarkFolder
                bookmarkFolderDict[bookmarkID] = {  id: bookmarkID, 
                                                    label: bookmarkFolder.bookmark_folders, 
                                                    sub_folders: bookmarkFolder.sub_folders, 
                                                    bookmarks: bookmarks, 
                                                    type: bookmarkFolder.bookmark_type.length > 0 
                                                        ? bookmarkFolder.bookmark_type[0].label
                                                        : 'private'
                                                    };
                for (var attr in bookmarkFolder.bookmarks) { 
                    let bookmarkID = getBookmarkID(bookmarkFolder.bookmarks[attr]);
                    allBookmarks[bookmarkID] = bookmarkID; 
    
                }
              
            }
        }); 
        bookmarkFolderDict.allBookmarks = allBookmarks;

        return bookmarkFolderDict;
    }
}

export default API_logic;