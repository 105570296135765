// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import { isStringNullOrEmpty } from 'logic/stringOperations';
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import MuiIcon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// --- Components


// --- Assets
import {
	mdiClockOutline,
	mdiAccountOutline,
	mdiNetworkStrength1
} from '@mdi/js';

// --- Style

const styles = theme => ({
	headerIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	BookmarkFolderButton: {
		padding: 6,
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	BookmarkFolderButtonActive: {
		padding: 6,
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	MenuItemTitle: {
    	fontSize: '1rem',
	},
});

class BookmarkFolderButton extends React.Component {
	constructor(props) {
		super(props);	  
		this.state = {
			anchorEl: null,
			openRenameModal: false,
			openFolderRemoveModal: false,
			folderName: null,
		};
	}

	// Show menu when folder icon is clicked
	handleIconClick = (event) => {
		console.log('handleIconClick');
		this.setState( {anchorEl: event.currentTarget} );
	}

	handleRenameFolder = () => {
		console.log('renameFolder');
		this.setState( {openRenameModal: true});
	}

	handleOpenDeleteFolderModal = (folderId) => {
		this.setState( {openFolderRemoveModal: true, folderId: folderId});
	}

	handleCloseDeleteFolderModal = () => {
		this.setState({openFolderRemoveModal: false, folderId: null});
		this.handleMenuClose();
	}

	handleMenuClose = () => {
		console.log('handleBookmarkClose');
		this.setState( {anchorEl: null} );
	}

	handleDeleteFolder = () => {
		this.props.deleteFolder(this.state.folderId);
		this.setState({openFolderRemoveModal: false, folderId: null});
		this.handleMenuClose();
	}

	render() {
		const {
			props: {
				t,
				folder,
				classes,
				...other
			},
			state: {
				openRenameModal,
				openFolderRemoveModal,
			}
		} = this;

		const open = Boolean(this.state.anchorEl);

		let bookmarkMenu;
		let menuItems = [];
		let icon = (<MuiIcon>favorite</MuiIcon>);
		// Only when we need to show copy or remove options (typically on bookmark overview page)
		// Change Icon to menu icon
		icon = (<MuiIcon>menu</MuiIcon>);

		// Show menu with copy and removing of bookmarks
		// Show remove bookmark options
		menuItems.push( 
			(<MenuItem 	
				key={'delete_folder'}
				onClick={() => this.handleOpenDeleteFolderModal(folder.id)}
			>

				<ListItemIcon>
					<DeleteOutlineIcon fontSize="small" style={{color: 'red'}}/>
				</ListItemIcon>
				<ListItemText>
					{t(TranslationKey.bookmark_folder_delete)}
				</ListItemText>
			</MenuItem>
			)
		);
/*		menuItems.push( 
			(<MenuItem 	
				key={'rename_folder'}
				onClick={() => this.handleRenameFolder()}
			>
					{t(TranslationKey.bookmark_folder_rename)}
			</MenuItem>
		));
*/
		bookmarkMenu = (
			<Menu key={folder.id}
				id="bookmark-menu"
				anchorEl={this.state.anchorEl}
				open={open}
				onClose={this.handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'bookmark-button',
				}}
			>
				{menuItems}
			</Menu>
		);
		

		return (
			<>
			<Dialog
				open={openFolderRemoveModal}
				onClose={this.handleCloseDeleteFolderModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{t(TranslationKey.bookmark_folder_delete_confirmation_title)}
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t(TranslationKey.bookmark_folder_delete_confirmation_text)}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={this.handleCloseDeleteFolderModal}>{t(TranslationKey.cancel)}</Button>
				<Button onClick={this.handleDeleteFolder} autoFocus>
					{t(TranslationKey.bookmark_folder_delete)}
				</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openRenameModal}
				aria-labelledby="alert-dialog-create-folder-title"
				aria-describedby="alert-dialog-create-folder-description"
			>
				<DialogTitle>
					{t(TranslationKey.modal_bookmark_create_folder_title)}
				</DialogTitle>
				<DialogContent>
				<DialogContentText>
					{t(TranslationKey.modal_bookmark_create_folder_text)}
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="foldername"
					label={t(TranslationKey.modal_bookmark_create_folder_input_field)}
					type="text"
					fullWidth
					variant="standard"
					onChange={(v) => {this.setState({folderName: v.target.value})}}
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={() => this.setState({openCreateModal: false})}>{t(TranslationKey.cancel)}</Button>
				<Button onClick={this.handleSaveNewFolder}>{t(TranslationKey.save)}</Button>
				</DialogActions>
			</Dialog>
			<IconButton
						aria-label="bookmark"
						className={classes.bookmarkButton}
						
						onClick={this.handleIconClick}
//							onClick={() => toggleBookmark(drill.id)}
					>
						{icon}
					</IconButton> 
					{bookmarkMenu}
			</>
		);
	}
}

BookmarkFolderButton.propTypes = {
	t: PropTypes.func.isRequired,
	folder: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	deleteFolder: PropTypes.func.isRequired,
	renameFolder: PropTypes.func.isRequired,
};

export default withStyles(styles)(BookmarkFolderButton);
