// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import SupportedSorting from 'logic/enums/SupportedSorting';
import { isStringNullOrEmpty, getIdFromApiUrl } from 'logic/stringOperations';
import { countActiveFilters } from 'visual/components/modals/ApplyFiltersModal';
// import { getIdFromApiUrl } from 'logic/stringOperations';
// import API_logic from 'io/API/API_logic/getUserBookmarkDict.js';


// --- External components
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';

// --- Components
import { Transition } from 'App';
import SortMenu from 'visual/components/Menus/SortMenu';
import DrillCard from 'visual/components/cards/DrillCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import DrillInspector from 'visual/components/modals/DrillInspector';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';
import FilterBox from 'visual/components/_/form/FilterBox'
import { templateElement } from '@babel/types';
import ApplyFiltersDrawer from 'visual/components/Menus/ApplyFiltersDrawer'
import ApplyFiltersDrawerMobile from 'visual/components/Menus/ApplyFiltersDrawerMobile'

// --- Style
const styles = theme => ({
	openFilterModalButton: {
		borderRadius: 16,
	},
	paper: {
		margin: '0px',
		width: '100%',
	},
	scrollPaper: {
		width: '100%',
		height: '100%',
//		margin: '10px',
//		alignItems: 'flex-start',
	},
	filterBox: {
		borderWidth: '0px',
		borderRadius: '0px 3px 3px 3px',
		borderStyle: 'solid',
		borderColor: theme.palette.background.backgroundPaper,
		padding: '5px 10px',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		  },
	},
	filterGroupBox: {
		borderWidth: '0px',
		borderRadius: '3px',
		borderStyle: 'solid',
		borderColor: theme.palette.background.backgroundPaper,
		padding: '0px 0px',
	},
	filterChip: {
		borderRadius: '3px', 
		 
		fontWeight: 700,
		float: 'right',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		  },
	},
	filterDrawer: {
		borderWidth: '0px',

		display: 'none',
//		backgroundColor: 'white',
		padding: '0px 10px',
//		borderRight: '1px solid rgba(0, 0, 0, 0.12);',
		[theme.breakpoints.up('md')]: {
			display: 'block',
			width: '350px',
			maxWidth: '350px',
			overflow: 'hidden',
		},
	},
	filterDrawerMobile: {
		borderWidth: '0px',
		borderRadius: '3px 3px 3px 3px',
		borderStyle: 'solid',
		borderColor: theme.palette.background.backgroundPaper,
		padding: '10px 0px 50px 0px',
		display: 'block',
		[theme.breakpoints.up('md')]: {
			display: 'none',
			width: '100%'
		},
	},
	drawerPaper: {
		position: 'relative',
		left: '0px',
		zIndex: 0,
		backgroundColor: theme.palette.background.default,
		borderWidth: '0px',
	},
	drawerPaperMobile: {
		position: 'relative',
		left: '0px',
		zIndex: 0,
		backgroundColor: theme.palette.background.default,
		borderRadius: '3px',
	},
	main: {
		borderLeft: '1px solid rgba(0, 0, 0, 0.12);',
		paddingLeft: '10px',
		paddingRight: '10px',
	},
	navContentContainer: {
		position: 'absolute', 
		left: 0, 
		display: 'flex', 
		maxWidth: '100vw', 
		width: '100vw',
//		backgroundColor: theme.palette.background.default,
	},
});


class Drills extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedSorting: SupportedSorting.sort_none.id,
			supportedSorting: SupportedSorting,
			searchText: '',
			sidebarOpen: false,
		};
	
		this.onSort = this.onSort.bind(this);
		this.onClearSort = this.onClearSort.bind(this);
		this.toggleSidebarOpen = this.toggleSidebarOpen.bind(this);
		// Show filter modal when filter is not active and when we're not showing a drill
//		if ((this.props.match.params.id==null) && (this.props.filters==null)) {
//		 	this.props.openFilterModal();
//		}
	}

	toggleSidebarOpen() {
		let isOpen = this.state.sidebarOpen;

		this.setState({sidebarOpen: !isOpen});
	}

	async updateLevelFilters(index, levels) {
		 
		const { 
			onApply,
			filters,
		} = this.props;
		if (filters != null) {
			if (filters.niveau != null) {
				if (filters.niveau.length > 0)
				{
					if (filters.niveau.length == 1) {
						filters.niveau = null;
					}
					else {
						filters.niveau.splice(index, 1);
					}
				}
			}
		}
		else {
			filters.niveau.length = 0;
		}
		 

		await onApply(filters);
	}

	render() {
		const {
			props: {
				t,
				i18n,
				filters,
				classes,
				history,
				houseList,
				contactData,
				drillDetails,
				adCategories,
				clearFilters,
				toggleFilters,
				filtersEnabled,
				// toggleBookmark,
				addBookmarkToFolder,
				removeBookmarkFromFolder,
				addUserBookmarkFolder,
				openFilterModal,
				supportedTechnics,
				filteredHouseList,
				bookmarkDictionary,
				supportedSubTechnics,
				isLoadingFilteredList,
				fullBookmarkDict,
				route,
				learningCurves,
				supportedLevels,
				filterPreference,
				onApply,
				searchText,
				clearSearchText,
				onSearchChange,
				match: { params: { id } },
			},
			state: {
				selectedSorting,
				supportedSorting,
				sidebarOpen,
			}
		} = this;

		const activeFilterCount = filters != null ? countActiveFilters(filters) : 0;

		const isFilteredList = activeFilterCount > 0 && filtersEnabled;

		let housesToDisplay = isFilteredList ? filteredHouseList : houseList;


// console.log('Drill list: supportedTechnics ///////////////////////////////////////////');
// console.log(supportedTechnics);


		if (searchText != '') {
			 
			 
			let text = searchText.toLowerCase();
			 
			let searchResults = housesToDisplay.filter( (drill) => {
				// if searchText is found, return true
				if (drill.i18n_description != null) {
					if (drill.i18n_description.toLowerCase().includes(text)) {
						return true;
					}
				}
				if (drill.i18n_label != null) {
					if (drill.i18n_label.toLowerCase().includes(text)) {
						return true;
					}	
				}

				if (drill.hoofdtechniek != null) {
				    let hoofdtechniek_id = getIdFromApiUrl(drill.hoofdtechniek[0].url)
					if( supportedTechnics[hoofdtechniek_id].i18n_label.toLowerCase().includes(text) ){
						return true;
					}					
				}

				if (drill.subtechniek != null) {
				    let subtechniek_id = getIdFromApiUrl(drill.subtechniek[0].url)
					if( supportedSubTechnics[subtechniek_id].i18n_label.toLowerCase().includes(text) ){
						return true;
					}					
				}

// supportedTechnics


	// if (drill.hoofdtechniek != null) {
	// 	if (drill.hoofdtechniek.filter( hoofdtechniek => hoofdtechniek.label.toLowerCase().includes(text)).length > 0) {
	// 		return true;
	// 	}					
	// }

	// if (drill.subtechniek != null) {  // HL 4 2022   nog weer activeren!! 
	// 	if (drill.subtechniek.filter( subtechniek => subtechniek.label.toLowerCase().includes(text)).length > 0) {
	// 		return true;
	// 	}					
	// }
/*
					if  drills, niveau[x].label, mainTechnique
					subtechniek[x].label
					hoofdtechniek[x].label

					*/

				return false;
			});
			housesToDisplay = searchResults;
		}
		// sort list
		// Create sorted article array
//		const articleArray = Object.entries(volleypediaArticles);

		housesToDisplay.sort((a, b) => {
			// if (a.i18n_label == null ) { a.i18n_label = ""}         // HL 4 2022, waarschijnlijk niet meer nodig
			// if (b.i18n_label == null ) { b.i18n_label = ""}
			//note that the sorted array contains an array per element [1] is id; [1] is the object
			return a.i18n_label.toLowerCase().localeCompare(b.i18n_label.toLowerCase(),
														i18n.language != null?i18n.language:'en', 
														{numeric: true});
			/*
			if ( a[1].label.toLowerCase() < b[1].label.toLowerCase() ){
				return -1;
			  }
			  if ( a[1].label.toLowerCase() > b[1].label.toLowerCase() ){
				return 1;
			  }

			  return 0;
			  */
		});	





		if (!isStringNullOrEmpty(selectedSorting) && supportedSorting[selectedSorting].sort != null)
			housesToDisplay = housesToDisplay.slice().sort((a, b) => supportedSorting[selectedSorting].sort(a, b, drillDetails));

		let list;
		let endOfList = null;
		let startOfList = null;
		if (isFilteredList && isLoadingFilteredList)
			list = <CircularProgress size={56} style={{ margin: '23px auto' }}/>;
		else {
			list = (
				<VirtualizedList
					preloadingOffset={9}
					itemCount={housesToDisplay.length}
					windowHeight={window.innerHeight - 72 - 56 - 48 - 8}
					computeSizes={(rowWidth) => {
						const maxItemWidth = 416;

						// maxItemWidth is multiplied by 1.1 (+10%) to prefer switching
						// item size when the available space gets 10% larger than the minimum.
						const howManyItemsCanFit = Math.max(Math.ceil(rowWidth / (maxItemWidth * 1.1)), 1);

						// Determines by how much the item(s) should be down-scaled to fit one more
						// and fill up as much horizontal space as possible. If the row width is
						// smaller than two items but still larger than one then a horizontal gap
						// can appear around the item, which is a waste of usable space.
						//
						// Note that this only applies when the row width is larger than the max item width,
						// otherwise there can only be one item per row that takes up all the width anyway,
						// and it shouldn't be applied when the ratio turns out to be larger than 1,
						// otherwise it would mean scaling each item up beyond their maximum width,
						// which would create a gap between each item horizontally and vertically.
						let freeSpaceRatio = (rowWidth / maxItemWidth) / howManyItemsCanFit;

						// Prevents each item to be considered to take up more space than they actually can,
						// otherwise a large gab can open up between items and re-introduce a waste of space.
						if (freeSpaceRatio > 1)
							freeSpaceRatio = 1;

						const itemWidth = rowWidth < maxItemWidth ? rowWidth : maxItemWidth * freeSpaceRatio;

						// (picture with 16:9 ratio) + (footer with street name and info) + (top and bottom padding).
						const rowHeight = (itemWidth * 0.5625) + 86 + 12;

						return {
							itemWidth, // 416
							rowHeight, // 317 + 16
						};
					}}
					renderItem={(key, index, style) => (
						<div
							key={key}
							style={{
								padding: '6px',
								...style,
							}}
						>
							<DrillCard
								t={t}
								i18n={i18n}
								drill={housesToDisplay[index]}
								// toggleBookmark={toggleBookmark}
								addBookmarkToFolder={addBookmarkToFolder}
								removeBookmarkFromFolder={removeBookmarkFromFolder}
								supportedTechnics={supportedTechnics}
								supportedSubTechnics={supportedSubTechnics}
								to={`/drills/${housesToDisplay[index].id}`}
								history={history}
//								bookmarked={bookmarkDictionary[housesToDisplay[index].id] != null}
								bookmarked={fullBookmarkDict.allBookmarks[housesToDisplay[index].id] != null}
								bookmarkDict={fullBookmarkDict} // Root folder
								addUserBookmarkFolder={addUserBookmarkFolder}
							/>
						</div>
					)}
				/>
			);

			endOfList = (
				<Grid
					item
					xs={12}
				>
					<EndOfListCard
						itemsCount={housesToDisplay.length}
						isFilteredList={isFilteredList}
						translationKey={TranslationKey.drills_end_of_list}
						translationKeyWhenEmpty={TranslationKey.drills_end_of_list_empty}
						translationKeyWhenFiltered={TranslationKey.drills_end_of_list_filtered}
						displayAsAlert={housesToDisplay.length <= 0 && !isFilteredList ? 'warning' : null}
					/>
				</Grid>
			);
			startOfList = (
				<Grid
					item
					xs={12}
				>
					<EndOfListCard
						itemsCount={housesToDisplay.length}
						isFilteredList={isFilteredList}
						translationKey={TranslationKey.drills_start_of_list}
						translationKeyWhenEmpty={TranslationKey.drills_start_of_list_empty}
						translationKeyWhenFiltered={TranslationKey.drills_start_of_list_filtered}
						displayAsAlert={housesToDisplay.length <= 0 && !isFilteredList ? 'warning' : null}
					/>
				</Grid>
			);
		}
		let selection = '';

		if (filters != null) {
/*
		if ( (filters.hoofdtechniek != null)
			|| (filters.subtechniek != null)
		 ) {
			 
			selection = items.map(({ groupId, itemId }, index) => {
				 
				const group = supportedGroups[groupId];
				 
				if (hoofdtechniek == null) {
					return (
						<Chip
							key={groupId}
							color="primary"
							variant="outlined"
							className={classes.chip}
							onDelete={() => onItemRemoved(index)}
							label={group[i18n.language] || group.nl}
						/>
					);
				}
				else {
					const item = supportedItems[itemId];

					return (
						<Chip
							key={`${groupId}-${itemId}`}
							color="primary"
							variant="outlined"
							className={classes.chip}
							onDelete={() => console.log('ToDo: remove item')}
							label={`${group[i18n.language] || group.nl} - ${item[i18n.language] || item.nl}`}
						/>
					);
				}
			});
		}
		*/
			if (filters.niveau != null) {
				if (filters.niveau.length > 1) {
					selection = filters.niveau.map((id, index) => (
						<Grid item key={`${id}-${index}`}>
						<Chip
							color="primary"
							variant="outlined"
							className={classes.chip}
							label={supportedLevels[id][i18n.language]}
							onDelete={() => this.updateLevelFilters(index, filters.niveau)}
						/>
						</Grid>
					));	
				}
			}
			if (searchText != '') {
				selection = (
					<Grid item key={`search-text`}>
					<Chip
						color="primary"
						variant="outlined"
						className={classes.chip}
						label={t(TranslationKey.drills_filters_search_status_label) + searchText}
						onDelete={clearSearchText}
					/>
					</Grid>
				);	
			}
		}
		const drawerWidth = 330;
		const halfDrawerWidth = drawerWidth/2;

		const container = window !== undefined ? () => window.document.body : undefined;
		const drawerSidebar = (
			<ApplyFiltersDrawer
				asSidebar={true}
				activeFilters={filters}
				searchText={searchText}
				isOpen={true}
				onApply={onApply}
				supportedLevels={supportedLevels}
				filterPreference={filterPreference}
				supportedTechnics={supportedTechnics}
				supportedSubTechnics={supportedSubTechnics}
				onClose={() => this.setState({ isFilterModalOpen: false })}
				onSearchChange={onSearchChange}
			/>
		);
		const drawerMobile = (
			<ApplyFiltersDrawerMobile
				asSidebar={false}
				activeFilters={filters}
				searchText={searchText}
				isOpen={sidebarOpen}
				onApply={onApply}
				supportedLevels={supportedLevels}
				filterPreference={filterPreference}
				supportedTechnics={supportedTechnics}
				supportedSubTechnics={supportedSubTechnics}
				onClose={this.toggleSidebarOpen}
				onSearchChange={onSearchChange}
			/>
		);
		const testdrawer = (<Box width={drawerWidth} className={classes.filterDrawer}>Test bla bla</Box>);
		return (

			<Container
			>
				<DrillInspector
					t={t}
					id={id}
					i18n={i18n}
					houseList={houseList}
					contactData={contactData}
					adCategories={adCategories}
					// toggleBookmark={toggleBookmark}
					addBookmarkToFolder={addBookmarkToFolder}
					removeBookmarkFromFolder={removeBookmarkFromFolder}
					onClose={() => history.push('/drills') }
					supportedSubTechnics={supportedSubTechnics}
					supportedTechnics={supportedTechnics}
					bookmarked={fullBookmarkDict.allBookmarks[id] != null}
				//	bookmarked={fullBookmarkDict.allBookmarks[housesToDisplay[index].id] != null}
					learningCurves={learningCurves}
					history={history}
					bookmarkDict={fullBookmarkDict}
					addUserBookmarkFolder={addUserBookmarkFolder}
				/>

				<Box className={classes.navContentContainer}>

					<Box
						component="nav"
						sx={{ position: 'relative', flexShrink: { sm: 0 }, left: '0px' }}
						aria-label="mailbox folders"
					>
						{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
						<Drawer
							className={classes.filterDrawerMobile}
							classes={{
								paper: classes.drawerPaperMobile,
							}}
							container={container}
							variant="temporary"
							open={sidebarOpen}
							onClose={this.toggleSidebarOpen}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
						>
						{drawerMobile}
						</Drawer>
						<Drawer
							className={classes.filterDrawer}
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open
						>

						{drawerSidebar}
						</Drawer>
					</Box>

					<Box
						component="main"
						sx={{ flexGrow: 1, paddingLeft: 0, alignItems: 'flex-start'	}}
						className={classes.main}
					>

					<Grid container spacing={2}>
						<Grid item xs={8}>
							<div style={{
								display: 'flex',
								alignItems: 'flex-start',
							}}>
								{route.icon}								
								<Typography
										variant="h5"
										component="h1"
										style={{  paddingLeft: '10px', paddingTop: '8px' }}
									>
										{t(TranslationKey.page_explorer_title)}
								</Typography>
							</div>  
							
						</Grid>
						<Grid item xs={4}>
							<Chip
								clickable
								color="primary"
								onClick={() => this.setState({sidebarOpen: true})}
								variant={!isFilteredList ? 'outlined' : null}
								onDelete={isFilteredList ? clearFilters : null}
								icon={!isFilteredList ? <Icon>filter_list</Icon> : null}
								// deleteIcon={filtersEnabled ? <Icon>toggle_on</Icon> : <Icon>toggle_off</Icon>}
								avatar={null /*isFilteredList ? <Avatar style={{ fontWeight: 400 }}>{activeFilterCount}</Avatar> : null*/}
								label={!isFilteredList ? t(TranslationKey.button_filter_list) : t(TranslationKey.button_filter_list_active)}
								className={classes.filterChip}
							/>
						</Grid>
						<Grid 
							item 
							xs={12} 
						>
							<Box>

								<Box className={classes.filterBox}>
								<FilterBox
									activeFilters={filters}
									onApply={onApply}
									supportedLevels={supportedLevels}
									filterPreference={filterPreference}
									supportedTechnics={supportedTechnics}
									supportedSubTechnics={supportedSubTechnics}
									onClose={() => console.log('close')}
								/>
								<Grid container spacing={1}>
								{selection}
								</Grid>
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							container
							spacing={1}
							direction="row"
						>
							<Grid item>
								{ /*<Chip
									clickable
									color="primary"
									onClick={openFilterModal}
									variant={!isFilteredList ? 'outlined' : null}
									onDelete={isFilteredList ? clearFilters : null}
									icon={!isFilteredList ? <Icon>filter_list</Icon> : null}
									style={{  fontWeight: 700 }}
									// deleteIcon={filtersEnabled ? <Icon>toggle_on</Icon> : <Icon>toggle_off</Icon>}
									avatar={isFilteredList ? <Avatar style={{ fontWeight: 400 }}>{activeFilterCount}</Avatar> : null}
									label={!isFilteredList ? t(TranslationKey.button_filter_list) : t(TranslationKey.button_filter_list_active)}
								/> */}
							</Grid>
							<Grid item>
	{/**							<SortMenu
									onSelect={this.onSort}
									onClear={this.onClearSort}
									selectedSorting={selectedSorting}
									supportedSorting={supportedSorting}
								/>

						*/}
							</Grid>
						</Grid>
						{startOfList}
						{list}
					</Grid>
				</Box>
			</Box>
			</Container>
		);
	}


	// --- Event methods
	onSort(id) {
		this.setState({ selectedSorting: id });
	}

	onClearSort() {
		this.setState({ selectedSorting: '' });
	}
}

Drills.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	filters: PropTypes.object,
	userData: PropTypes.object,
	clearFilters: PropTypes.func,
	toggleFilters: PropTypes.func,
	contactData: PropTypes.object,
	filtersEnabled: PropTypes.bool,
	adCategories: PropTypes.object,
	// toggleBookmark: PropTypes.func,
	addBookmarkToFolder: PropTypes.func,
	removeBookmarkFromFolder: PropTypes.func,
	openFilterModal: PropTypes.func,
	// activeFilterCount: PropTypes.number,
	bookmarkDictionary: PropTypes.object,
	fullBookmarkDict: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	isLoadingFilteredList: PropTypes.bool,
	drillDetails: PropTypes.object.isRequired,
	supportedTechnics: PropTypes.object.isRequired,
	supportedSubTechnics: PropTypes.object.isRequired,
	houseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	filteredHouseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	clearSearchText: PropTypes.func.isRequired,
	searchText: PropTypes.string.isRequired,
};

Drills.defaultProps = {
	// activeFilterCount: 0,
	filters: null,
	filtersEnabled: false,
	isLoadingFilteredList: false,
	// toggleBookmark: () => console.warn('toggleBookmark method not provided.'),
	addBookmarkToFolder: () => console.warn('addBookmarkToFolder method not provided.'),
	removeBookmarkFromFolder: () => console.warn('removeBookmarkFromFolder method not provided.'),
	clearFilters: () => console.warn('clearFilters method not provided.'),
	clearSearchText: () => console.warn('clearSearchText method not provided.'),
	toggleFilters: () => console.warn('toggleFilters method not provided.'),
	openFilterModal: () => console.warn('openFilterModal method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Drills);
