/* eslint-disable */

// --- Style (must be imported before anything else when using react-md component library)
import 'visual/style/index.sass';

// --- Framework
import React from 'react';
import ReactDOM from 'react-dom';

// --- External tools
/// Navigation between predefined URIs without reloading the page.
import { BrowserRouter as Router } from 'react-router-dom';

// --- Logic
import 'logic/translation/i18n';

// --- Components
import App from 'App';

// --- Progressive Web App
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


// Hides console logs in production environment, warnings and errors will still appear.
if (process.env.NODE_ENV === 'production')
	console.log = () => {};


ReactDOM.render(
	<Router>
		<App/>
	</Router>,
	document.getElementById('root')
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
