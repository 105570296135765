/* Sources
 * https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript
 */

// Returns a copy of an array without any elements corresponding to ["", 0, NaN, null, undefined, false,,].
export function RemoveFalsyElements(array) {
	return array.filter(Boolean);
}

// Returns a copy of an array without any elements corresponding to [null, undefined,,].
export function RemoveNullElements(array) {
	return array.filter(element => element != null);
}

// Returns a copy of an array so that [1,,2,,,,3] would become [1,2,3].
export function RemoveHoles(array) {
	return array.filter(() => true);
}
