// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

// --- Logic
import i18n from 'logic/translation/i18n';
import StorageKey from 'logic/enums/StorageKey';
import TranslationKey from 'logic/enums/TranslationKey';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';

// --- External components
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';

// --- Components
import EditAccountModal from 'visual/components/modals/EditAccountModal';
import ApplyFiltersModal from 'visual/components/modals/ApplyFiltersModal';
import ConfirmationModal from 'visual/components/modals/ConfirmationModal';
import DeleteAccountModal from 'visual/components/modals/DeleteAccountModal';
import ChangePasswordModal from 'visual/components/modals/ChangePasswordModal';
import { authenticatedRoutes } from 'visual/components/routes';

// --- Assets
import logo from 'assets/images/1000drills_logo_512x512.png';
import WikiIcon from 'assets/icons/v-logo2.svg';
import macbook from 'assets/images/1000drills-macbook.png';
import iphone from 'assets/images/1000drills-iphone.png';
import macphone from 'assets/images/1000drills-mac-phone.png';

// For card
import { styled } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
  })(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
	  duration: theme.transitions.duration.shortest,
	}),
  }));
  

// --- Style
const styles = theme => ({
	deleteAccountButton: {
		color: theme.palette.text.inDanger,
		background: theme.palette.background.danger,
		'&:hover': {
			background: theme.palette.background.danger,
		},
	},
	contentContainer: {
        backgroundImage: `url(${"/images/1000drills_logo_512x512.png"})`
	},
});

class Homepage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditAccountModalOpen: false,
			isDeleteAccountModalOpen: false,
			isChangePasswordModalOpen: false,
			isFilterPreferenceModalOpen: false,
			isSignOutConfirmationModalOpen: false,
			preferencesExpanded: false,
		};

		this.signOut = this.signOut.bind(this);
		this.onThemeSelected = this.onThemeSelected.bind(this);
		this.onLanguageSelected = this.onLanguageSelected.bind(this);
		this.onPushNotificationSelected = this.onPushNotificationSelected.bind(this);
		this.onEmailNotificationSelected = this.onEmailNotificationSelected.bind(this);
	}


	handleExpandClick = () => {
		let expanded = this.state.preferencesExpanded;
		expanded = !expanded;
		this.setState({preferencesExpanded: expanded});
	//	setExpanded(!expanded);
	};
		// --- Framework methods
	render() {
		const {
			props: {
				t,
				theme,
				classes,
				userData,
				onSignedOut,
				updateFilters,
				supportedLevels,
				onAccountEdited,
				supportedThemes,
				booleanSelectors,
				filterPreference,
				supportedTechnics,
				supportedLanguages,
				authenticationStatus,
				onCredentialsChanged,
				route,
				informationTexts,
			},
		} = this;

		const isAuthenticated = authenticationStatus === AuthenticationStatus.Authenticated;

		let notSignedIn = null;
		let signedInElements = null;
		let accountInfo = null;
		let signOutButton = null;
		let editAccountButton = null;
		let changePasswordButton = null;
		let deleteAccountButton = null;
		if (isAuthenticated) {
			if (userData != null) {
				accountInfo = (
					<Grid item xs={12}>
						<Typography variant="subtitle2">
							<Trans
								values={{ username: userData.voor_en_achternaam.split(' ')[0] }}
								i18nKey={TranslationKey.connected_as_username}
								default="Connected as <strong>{{username}}</strong>."
							/>
						</Typography>
					</Grid>
				);
			}

			signOutButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isSignOutConfirmationModalOpen: true })}
					>
						{t(TranslationKey.sign_out)}
					</Button>
					<ConfirmationModal
						onCompleted={this.signOut}
						cancelText={t(TranslationKey.cancel)}
						confirmText={t(TranslationKey.sign_out)}
						contentText={t(TranslationKey.modal_confirm_sign_out)}
						isOpen={this.state.isSignOutConfirmationModalOpen}
						onClose={() => this.setState({ isSignOutConfirmationModalOpen: false })}
					/>
				</Grid>
			);

			editAccountButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isEditAccountModalOpen: true })}
					>
						{t(TranslationKey.update_profile)}
					</Button>
					<EditAccountModal
						userData={userData}
						onCompleted={onAccountEdited}
						onFailedToComplete={onSignedOut}
						isOpen={this.state.isEditAccountModalOpen}
						onClose={() => this.setState({ isEditAccountModalOpen: false })}
					/>
				</Grid>
			);

			changePasswordButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isChangePasswordModalOpen: true })}
					>
						{t(TranslationKey.change_password)}
					</Button>
					<ChangePasswordModal
						userData={userData}
						isOpen={this.state.isChangePasswordModalOpen}
						onClose={() => this.setState({ isChangePasswordModalOpen: false })}
						onCompleted={newPassword => onCredentialsChanged(userData.users, newPassword)}
					/>
				</Grid>
			);

			deleteAccountButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						disableElevation
						variant="contained"
						className={classes.deleteAccountButton}
						onClick={() => this.setState({ isDeleteAccountModalOpen: true })}
					>
						{t(TranslationKey.delete_account)}
					</Button>
					<DeleteAccountModal
						userData={userData}
						onCompleted={this.signOut}
						isOpen={this.state.isDeleteAccountModalOpen}
						onClose={() => this.setState({ isDeleteAccountModalOpen: false })}
					/>
				</Grid>
			);

			signedInElements = (
				<Grid
					container
					spacing={1}
					wrap="wrap"
					style={{ marginBottom: 32 }}
				>
					{signOutButton}
					{editAccountButton}
					{changePasswordButton}
					{deleteAccountButton}
				</Grid>
			);
		} else {
			notSignedIn = (
				<Grid
					item
					xs={12}
					sm="auto"
					style={{ margin: 'auto' }}
				>
					<Alert severity="warning">
						<Trans i18nKey={TranslationKey.page_preferences_caveat_not_signed_in}/>
					</Alert>
				</Grid>
			);
		}

		let languageSelector = null;
		if (supportedLanguages != null) {
			languageSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-language-label">
							{t(TranslationKey.select_language)}
						</InputLabel>
						<Select
							value={i18n.language}
							id="select-preferred-language"
							onChange={this.onLanguageSelected}
							label={t(TranslationKey.select_language)}
							labelId="select-preferred-language-label"
						>
							{supportedLanguages.map(({ id, label }) => (
								<MenuItem
									key={id}
									value={label}
								>
									{t(label)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		let themeSelector = null;
		if (supportedThemes != null) {
			themeSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-theme-label">
							{t(TranslationKey.select_theme)}
						</InputLabel>
						<Select
							value={theme}
							id="select-preferred-language"
							onChange={this.onThemeSelected}
							label={t(TranslationKey.select_theme)}
							style={{  }}
							labelId="select-preferred-theme-label"
						>
							{supportedThemes.map(({ id, label }) => (
								<MenuItem
									key={id}
									value={label}
									style={{  }}
								>
									{t(TranslationKey[label])}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		let booleanSelectorMenuItems = null;
		if (booleanSelectors != null) {
			booleanSelectorMenuItems = Object.keys(booleanSelectors).map(key => (
				<MenuItem
					key={booleanSelectors[key].id}
					value={booleanSelectors[key].id}
					style={{  }}
				>
					{booleanSelectors[key][i18n.language]}
				</MenuItem>
			));
		}

		let emailNotificationsSelector = null;
		// let pushNotificationsSelector = null;
		if (booleanSelectorMenuItems != null) {
			emailNotificationsSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-email-notifications-label">
							{t(TranslationKey.select_email_notifications)}
						</InputLabel>
						<Select
							disabled={!isAuthenticated}
							id="select-preferred-language"
							style={{  }}
							onChange={this.onEmailNotificationSelected}
							labelId="select-preferred-email-notifications-label"
							label={t(TranslationKey.select_email_notifications)}
							value={userData != null ? userData.emailNotifications : booleanSelectors.nee.id}
						>
							{booleanSelectorMenuItems}
						</Select>
					</FormControl>
				</Grid>
			);

			// pushNotificationsSelector = (
			// 	<Grid item>
			// 		<FormControl
			// 			fullWidth
			// 			size="small"
			// 			variant="outlined"
			// 		>
			// 			<InputLabel id="select-preferred-push-notifications-label">
			// 				{t(TranslationKey.select_push_notifications)}
			// 			</InputLabel>
			// 			<Select
			// 				disabled={!isAuthenticated}
			// 				id="select-preferred-language"
			// 				style={{  }}
			// 				onChange={this.onPushNotificationSelected}
			// 				label={t(TranslationKey.select_push_notifications)}
			// 				labelId="select-preferred-push-notifications-label"
			// 				value={userData != null ? userData.pushNotifications : booleanSelectors.nee.id}
			// 			>
			// 				{booleanSelectorMenuItems}
			// 			</Select>
			// 		</FormControl>
			// 	</Grid>
			// );
		}





		let menuExplanation = 
		(
		<Grid container   
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start">


			<Grid container
			justifyContent="center"
			alignItems="center">

			</Grid>


			<Grid item xs={4}>
			<List >
				<Link to="/drills" className={'linkInvisible'}>
					<ListItem>

					<ListItemAvatar>
						{authenticatedRoutes[1].icon}
					</ListItemAvatar>
					<ListItemText primary={t(TranslationKey.navbar_explorer)} />
				</ListItem>
			  </Link>

			  <Link to="/leerlijnen" className={'linkInvisible'}>
				<ListItem>
					<ListItemAvatar>
						{authenticatedRoutes[2].icon}
					</ListItemAvatar>
					<ListItemText primary={t(TranslationKey.navbar_learningcurves)} />
				</ListItem>
			</Link>
			<Link to="/bookmarks" className={'linkInvisible'}>
			  <ListItem>
				<ListItemAvatar>
					{authenticatedRoutes[3].icon}
				</ListItemAvatar>
				<ListItemText primary={t(TranslationKey.navbar_favorites)} />
			  </ListItem>
			</Link>
			<Link to="/volleypedia" className={'linkInvisible'}>
			  <ListItem>
				<ListItemAvatar>
					{authenticatedRoutes[4].icon}
				</ListItemAvatar>
				<ListItemText primary={t(TranslationKey.navbar_information)} />
			  </ListItem>
			</Link>
			</List>
			</Grid>
			<Grid item xs={8}></Grid>

			</Grid>
		  );

		let filterPreferenceEditor = null;
		if (supportedLevels != null && supportedTechnics != null) {
			filterPreferenceEditor = (
				<Grid item>
					<Button
						fullWidth
						disableElevation
						variant="outlined"
						disabled={!isAuthenticated}
						onClick={() => this.setState({ isFilterPreferenceModalOpen: true })}
					>
						{t(TranslationKey.edit_filter_preference_button)}
					</Button>
					<ApplyFiltersModal
						applyOnClear={false}
						onApply={updateFilters}
						alwaysUpdateFilterPreference
						activeFilters={filterPreference}
						supportedLevels={supportedLevels}
						isAuthenticated={isAuthenticated}
						filterPreference={filterPreference}
						supportedTechnics={supportedTechnics}
						isOpen={this.state.isFilterPreferenceModalOpen}
						onClose={() => this.setState({ isFilterPreferenceModalOpen: false })}
					/>
				</Grid>
			);
		}
		let preferencesCard = (
		<Card sx={{ display: 'flex' }}>
			<CardHeader
				avatar={
					<Avatar aria-label="personal">
					<Icon style={{fontSize: '3rem'}}  className="material-icons-outlined">person</Icon>
					</Avatar>
				}
				title={ 
					<Trans
						values={{ username: userData.voor_en_achternaam.split(' ')[0] }}
						i18nKey={TranslationKey.connected_as_username}
						default="Connected as <strong>{{username}}</strong>."
					/>
				}

				subheader={
					<Typography>
						{t(TranslationKey.home_user_set_preferences)}
						<ExpandMore
							expand={this.state.preferencesExpanded}
							onClick={this.handleExpandClick}
							aria-expanded={this.state.preferencesExpanded}
							aria-label="show more"
						>
					
							<ExpandMoreIcon />
						</ExpandMore>
					</Typography>
				}
			/>
			
			<Collapse in={this.state.preferencesExpanded} timeout="auto" unmountOnExit>
				<CardContent>
					{signedInElements}
					<Grid
						container
						spacing={2}
						direction="column"
					>
						{languageSelector} 
						{/* languageSelector */}
						{themeSelector}
						{/* emailNotificationsSelector */}
						{/* {pushNotificationsSelector} */}
						{/* filterPreferenceEditor */}
						{notSignedIn}
					</Grid>

				</CardContent>
			</Collapse>
		</Card>
		);

		let homepageText = ''
		if (informationTexts!=null) {
			homepageText = informationTexts.homepage;
		}		

		return (<div >
			<Container
				disableGutters
				className="page"
				
				sx={{backgroundImage: `url(${"/images/1000drills_logo_512x512.png"})`}}
			>
				<Grid container spacing={2}
							direction="row"
							justifyContent="center"
							alignItems="flex-start">
					<Grid item>
					<List >
						<ListItem>
							<ListItemAvatar>
							<img
								src={logo}
								alt="1000Drills"
								title="1000Drills"
								id="splashScreenImage"
								width="50px"
							/>				  
							</ListItemAvatar>
							<ListItemText disableTypography style={{fontSize: '1.5rem'}} primary="1000Drills"/>
						</ListItem>
						</List>

					</Grid>
					<Grid item style={{textAlign: "center"}} xs={12}>
						<Typography>
							{homepageText[i18n.language]}
						</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						{menuExplanation}
					</Grid>
					<Grid item md={9}>
						<Hidden mdDown>
							<img 
								src={macphone}
								alt="1000Drills on PC"
								title="1000Drills on PC"
								id="macbook"
								width="600px"
						 	/>
						</Hidden>
					</Grid>
			</Grid>
				<Container
					maxWidth="xs"
					disableGutters
					className="pageContainer"
				>
					{preferencesCard}
				</Container>
				<div style={{color: 'grey'}}>Version {process.env.REACT_APP_VERSION}</div>
			</Container>
			</div>
		);
	}
// width img macphone was 950 px

	// --- Working methods
	signOut() {
		localStorage.removeItem(StorageKey.UserCredentials);
		this.props.onSignedOut();
	}


	// --- Event methods
	onLanguageSelected(event) {
		const { target: { value: selectedLanguage } } = event;

		const {
			switchLanguage,
			supportedLanguages,
		} = this.props;

		const languageToApply = supportedLanguages.find(({ label }) => label === selectedLanguage);

		if (languageToApply == null) {
			console.error(`The selected language ${selectedLanguage} is out of range, aborting.`);
			return;
		}

		switchLanguage(languageToApply);
	}

	onThemeSelected(event) {
		const { target: { value: selectedTheme } } = event;

		const {
			switchTheme,
			supportedThemes,
		} = this.props;

		const themeToApply = supportedThemes.find(({ label }) => label === selectedTheme);

		if (themeToApply == null) {
			console.error(`The selected theme ${selectedTheme} is out of range, aborting.`);
			return;
		}

		switchTheme(themeToApply);
	}

	onEmailNotificationSelected(event) {
		const { target: { value: booleanID } } = event;

		const { switchEmailNotifications } = this.props;

		switchEmailNotifications(booleanID);
	}

	onPushNotificationSelected(event) {
		const { target: { value: booleanID } } = event;

		const { switchPushNotifications } = this.props;

		switchPushNotifications(booleanID);
	}
}

Homepage.propTypes = {
	userData: PropTypes.object,
	onSignedOut: PropTypes.func,
	switchTheme: PropTypes.func,
	t: PropTypes.func.isRequired,
	updateFilters: PropTypes.func,
	switchLanguage: PropTypes.func,
	onAccountEdited: PropTypes.func,
	supportedLevels: PropTypes.object,
	filterPreference: PropTypes.object,
	theme: PropTypes.string.isRequired,
	booleanSelectors: PropTypes.object,
	supportedTechnics: PropTypes.object,
	onCredentialsChanged: PropTypes.func,
	switchPushNotifications: PropTypes.func,
	switchEmailNotifications: PropTypes.func,
	route: PropTypes.object.isRequired,
	supportedThemes: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})),
	supportedLanguages: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})),
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	authenticationStatus: PropTypes.oneOf(Object.values(AuthenticationStatus)).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	informationTexts: PropTypes.object,

};

Homepage.defaultProps = {
	filterPreference: null,
	switchTheme: () => console.warn('switchTheme method not provided.'),
	onSignedOut: () => console.warn('onSignedOut event method not provided.'),
	switchLanguage: () => console.warn('switchLanguage method not provided.'),
	onAccountEdited: rawUserData => console.warn('onAccountEdited method not provided.'),
	updateFilters: () => console.warn('updateFilters method not provided.'),
	switchPushNotifications: () => console.warn('switchPushNotifications method not provided.'),
	switchEmailNotifications: () => console.warn('switchEmailNotifications method not provided.'),
	onCredentialsChanged: (username, password) => console.warn('onCredentialsChanged method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Homepage);
